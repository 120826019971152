<template>
  <HeaderSapporo>
    <div id="not-found"></div>
  </HeaderSapporo>
</template>

<script>
import HeaderSapporo from '../components/HeaderSapporo';
export default {
  components: {
    HeaderSapporo
  }
};
</script>

'use strict';
const sendToGa4 = function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});
  var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;
  f.parentNode.insertBefore(j,f);
};

//退会完了画面にてGa4に送信する値をdatalayerに格納する
const unRegistGa4 = function(suid) {
  var nowDate = getNowDate();
  window.dataLayer.push({
    "event": "unregist",
    "suid": suid,
    "profile_update_date": nowDate,
    "unregist_flag": 1
  });
};

//現在の時間取得
function getNowDate() {
  var data = new Date();
  var year = data.getFullYear();
  var month = ('0' + (data.getMonth()+1)).slice(-2);
  var day = ('0' + data.getDate()).slice(-2);
  var hours = ('0' + data.getHours()).slice(-2);
  var minutes = ('0' + data.getMinutes()).slice(-2);
  var seconds = ('0' + data.getSeconds()).slice(-2);
  var nowDate = year + '-' + month + '-' + day + ' '+ hours + ':' + minutes + ':' + seconds;
  return nowDate
}
export default{ sendToGa4,unRegistGa4};
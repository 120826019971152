<!-- 退会画面 -->
<template>
  <!-- リンクのない共通ヘッダー -->
  <HeaderOld>
    <div id="page-container">
      <div id="structure">
        <h1 class="hd" ref="spTitle">{{ spTitle }}</h1>
        <div class="panel-group">
          <div class="panelFull-group">
            <div class="panelFull first-child">
              <div class="panelFull-content panelFull-content01">
                <div class="headingA01">
                  <h1>
                    <img
                      src="/common/img/heading/ttl_h1_01.gif"
                      width="28"
                      height="15"
                      alt="退会"
                    />
                  </h1>
                  <!-- /headingA01 -->
                </div>
                <template v-if="step === '1'">
                  <h2 class="step">
                    <img
                      src="/common/img/navi/step_remove_01.gif"
                      width="670"
                      height="30"
                      alt="登録内容の確認"
                    />
                  </h2>

                  <!--errorMSGstart-->
                  <div
                    class="important-informationB01"
                    v-if="unRegistError != ''"
                  >
                    <div class="important-informationB01-content">
                      <div class="important-informationB01-content-body">
                        <div class="textB01">
                          <p class="flat">
                            <span class="marker">
                              <img
                                src="/common/img/shared/ico/ico_error_01.gif"
                                width="14"
                                height="14"
                                alt="【!】"
                                class="icoB03"
                              />
                            </span>
                            <em>{{ unRegistError }}</em>
                          </p>
                          <!-- /textB01 -->
                        </div>
                        <div class="textA01">
                          <ul class="note-listB01 error">
                            <li></li>
                          </ul>
                          <!-- /textA01 -->
                        </div>
                        <!-- /important-informationB01-content-body -->
                      </div>
                      <!-- /important-informationB01-content -->
                    </div>
                    <!-- /important-informationB01 -->
                  </div>
                  <!--errorMSGend-->

                  <div class="textA01">
                    <p>
                      退会手続きを行います。
                      <br />現在、ご登録されている会員情報をご確認ください。
                    </p>
                    <!-- /textA01 -->
                  </div>

                  <ul class="note-listA01">
                    <li>
                      <span class="marker">※</span
                      >手続きには約1日ほどかかります。その為、手続きの翌日に配信するメールマガジンに関しては、退会前のメールアドレスに届いてしまう事がございますので、あらかじめご了承ください。
                    </li>
                  </ul>

                  <div class="form-inputA01" v-if="unRegistError === ''">
                    <form name="form1" id="form1" class="validation">
                      <h3 class="headingA03">メールアドレス</h3>

                      <div class="form-tableA01">
                        <table>
                          <tbody>
                            <tr>
                              <th scope="row">メールアドレス</th>
                              <td>
                                {{ this.memberinfo.mailaddress }}
                                <input
                                  type="hidden"
                                  name="ml1"
                                  id="ml1"
                                  :value="this.memberinfo.mailaddress"
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <!-- /form-tableA01 -->
                      </div>

                      <h3 class="headingA03">会員情報</h3>

                      <div class="form-tableA01">
                        <table>
                          <tbody>
                            <tr>
                              <th scope="row">お名前</th>
                              <td>
                                {{ this.memberinfo.namesei }}
                                {{ this.memberinfo.namemei }}
                              </td>
                            </tr>

                            <tr>
                              <th scope="row">フリガナ</th>
                              <td>
                                {{ this.memberinfo.nameseikana }}
                                {{ this.memberinfo.namemeikana }}
                              </td>
                            </tr>

                            <tr>
                              <th scope="row">性別</th>
                              <td>{{ checkSex(this.memberinfo.sex) }}</td>
                            </tr>

                            <tr>
                              <th scope="row">生年月日</th>
                              <td>
                                {{ formatBirthday(this.memberinfo.birthday) }}
                              </td>
                            </tr>

                            <tr>
                              <th scope="row">電話番号</th>
                              <td>{{ this.memberinfo.phonenumber }}</td>
                            </tr>

                            <tr>
                              <th scope="row">郵便番号</th>
                              <td>{{ this.memberinfo.zipcode }}</td>
                            </tr>

                            <tr>
                              <th scope="row">都道府県</th>
                              <td>
                                {{
                                  checkPrefectures(this.memberinfo.prefecture)
                                }}
                              </td>
                            </tr>

                            <tr>
                              <th scope="row">市区郡</th>
                              <td>{{ this.memberinfo.addr1 }}</td>
                            </tr>

                            <tr>
                              <th scope="row">町村・番地・建物名</th>
                              <td>{{ this.memberinfo.addr2 }}</td>
                            </tr>
                          </tbody>
                        </table>
                        <!-- /form-tableA01 -->
                      </div>

                      <h3 class="headingA03">メールマガジンの登録</h3>

                      <div class="form-tableA01">
                        <table>
                          <tbody>
                            <tr>
                              <th scope="row">メールマガジン</th>
                              <td>
                                <ul>
                                  <li v-if="this.memberinfo.mailmaga1 === 1">
                                    SAPPORO NET NEWS
                                  </li>
                                  <li v-if="this.memberinfo.mailmaga2 === 1">
                                    YEBISUプレミアム通信
                                  </li>
                                  <li v-if="this.memberinfo.mailmaga3 === 1">
                                    焼酎＆梅酒だより
                                  </li>
                                  <li v-if="this.memberinfo.mailmaga4 === 1">
                                    GRANDE POLAIRE Precious Salon
                                  </li>
                                </ul>
                              </td>
                            </tr>

                            <tr>
                              <th scope="row">メールマガジンの形式</th>
                              <td>
                                <label for="hm-1">{{
                                  checkMagaType(this.memberinfo.mailmagatype)
                                }}</label>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <!-- /form-tableA01 -->
                      </div>

                      <div class="button-listB01 action">
                        <ul>
                          <li class="back">
                            <input
                              type="image"
                              name="rt"
                              id="rt"
                              src="/common/img/shared/btn/function/btn_back_01.gif"
                              class="swap-img"
                              alt="戻る"
                              @click.prevent="goback"
                            />
                          </li>
                          <li class="confirm-unregist">
                            <input
                              type="image"
                              name="submit"
                              src="/common/img/shared/btn/function/btn_dropout_02.gif"
                              class="swap-img"
                              alt="退会の確認へ進む"
                              @click.prevent="step = '2'"
                            />
                          </li>
                        </ul>
                        <!-- /button-listB01 -->
                      </div>
                    </form>
                    <!-- /form-inputA01 -->
                  </div>
                </template>
                <template v-else-if="step === '2'">
                  <h2 class="step">
                    <img
                      src="/common/img/navi/step_remove_02.gif"
                      width="670"
                      height="30"
                      alt="退会の確認"
                    />
                  </h2>

                  <div class="textA01">
                    <p>
                      退会手続きを完了させます。
                      <br />「退会する」を押すと登録内容が全て消去されますが、同意の上、退会しますか？
                    </p>
                    <!-- /textA01 -->
                  </div>

                  <ul class="note-listA01">
                    <li>
                      <span class="marker">※</span
                      >手続きには約1日ほどかかります。その為、手続きの翌日に配信するメールマガジンに関しては、退会前のメールアドレスに届いてしまう事がございますので、あらかじめご了承ください。
                    </li>
                  </ul>

                  <div class="form-inputA01">
                    <form name="form1" id="form1">
                      <div class="button-listB01 action">
                        <ul>
                          <li class="cancel-unregist">
                            <input
                              type="image"
                              name="rt"
                              src="/common/img/shared/btn/function/btn_not-dropout_01.gif"
                              class="swap-img"
                              alt="退会をやめる"
                              @click.prevent="goback"
                            />
                          </li>
                          <li class="unregist">
                            <input
                              type="image"
                              name="submit"
                              id="submit"
                              src="/common/img/shared/btn/function/btn_dropout_01.gif"
                              class="swap-img"
                              alt="退会する"
                              @click.prevent="unRegist"
                            />
                          </li>
                        </ul>

                        <!-- /button-listB01 -->
                      </div>
                    </form>
                    <!-- /form-inputA01 -->
                  </div>
                </template>

                <template v-else-if="step === '3'">
                  {{ doGa4() }}
                  <h2 class="step">
                    <img
                      src="/common/img/navi/step_remove_03.gif"
                      width="670"
                      height="30"
                      alt="退会の完了"
                    />
                  </h2>

                  <div class="important-informationB02">
                    <div class="important-informationB02-content">
                      <div class="important-informationB02-content-body">
                        <div class="textB01">
                          <p class="ct flat">
                            <em>退会手続きが完了しました。</em>
                          </p>
                          <!-- /textB01 -->
                        </div>

                        <ul class="note-listA01">
                          <li>
                            <span class="marker">※</span
                            >手続きには約1日ほどかかります。その為、手続きの翌日に配信するメールマガジンに関しては、退会前のメールアドレスに届いてしまう事がございますので、あらかじめご了承ください。
                          </li>
                        </ul>

                        <div class="textA01">
                          <p class="ct">
                            ご利用ありがとうございました。
                            <br />今後ともサッポロビール株式会社をよろしくお願いいたします。
                          </p>
                          <!-- /textA01 -->
                        </div>

                        <div class="button-listB01 action">
                          <ul>
                            <li class="gohome-unregist">
                              <input
                                type="image"
                                name="submit"
                                id="submit"
                                src="/common/img/shared/btn/function/btn_go-home_02.gif"
                                class="swap-img"
                                alt="サッポロビールホームへ"
                                @click.prevent="goHome"
                              />
                            </li>
                          </ul>
                          <!-- /button-listB01 -->
                        </div>
                        <!-- /important-informationB02-content-body -->
                      </div>
                      <!-- /important-informationB02-content -->
                    </div>
                    <!-- /important-informationB02 -->
                  </div>
                </template>
                <ul class="link-listA01" v-if="step === '1' || step === '2'">
                  <li>
                    <a :href="PUBLICHTML.url + PUBLICHTML.updateMail"
                      >メールアドレス変更</a
                    >
                  </li>
                  <li>
                    <a :href="PUBLICHTML.url + PUBLICHTML.updateMaga"
                      >メールマガジン登録変更</a
                    >
                  </li>
                  <li>
                    <a href="/">マイアカウント</a>
                  </li>
                </ul>
                <!-- /panelFull-content -->
              </div>
              <!-- /panelFull -->
            </div>
            <!-- /panelFull-group -->
          </div>
          <!-- /panel-group -->
        </div>

        <!-- /structure -->
      </div>
    </div>
  </HeaderOld>
</template>

<script>
import {
  UNREGISTERROR,
  PREFECTURES,
  SEXTYPE,
  MAGATYPE,
  PUBLICHTML,
  MAINTAINURL,
  OVERLOADURL
} from '../common/const';
import HeaderOld from '../components/HeaderOld';
import axiosAuth from '../services/axiosAuth';
import ga4 from '../common/ga4';
export default {
  components: {
    HeaderOld
  },
  data() {
    return {
      unRegistError: '',
      // 退会のステップ、"1"は登録内容の確認、"2"は退会の確認、"3"は退会完了
      step: '1',
      PUBLICHTML: PUBLICHTML,
      suidGa4:''
    };
  },
  computed: {
    accesstoken() {
      return this.$store.getters.accesstoken;
    },
    memberinfo() {
      return this.$store.getters.memberinfo;
    },
    prefectures() {
      return PREFECTURES;
    },
    spTitle() {
      let title = '';
      switch (this.step) {
        case '1':
          title = '登録内容の確認';
          break;
        case '2':
          title = '退会の確認';
          break;
        case '3':
          title = '退会の完了';
          break;
      }
      return title;
    }
  },
  methods: {
    /* eslint-disable no-unused-vars */
    //生年月日を整形する
    formatBirthday(birthday) {
      let texts = birthday.split('-');
      return texts[0] + '年' + texts[1] + '月' + texts[2] + '日';
    },
    //都道府県を確認する
    checkPrefectures(pre) {
      return this.prefectures[Number(pre)];
    },
    //性別を確認する
    checkSex(sex) {
      if (sex === 1) {
        return SEXTYPE[0];
      } else if (sex === 2) {
        return SEXTYPE[1];
      } else {
        return SEXTYPE[2];
      }
    },
    //メルマガタイプを確認する
    checkMagaType(type) {
      if (type === 1) {
        return MAGATYPE[0];
      } else if (type === 2) {
        return MAGATYPE[1];
      }
    },
    //メルマガ登録のcss class
    checkSubscribeClass(sub) {
      if (sub === 1) {
        return 'is-registered';
      } else if (sub === 2) {
        return 'is-not-registered';
      }
    },
    //マイアカウントへ遷移
    goback() {
      window.location.assign('/');
    },
    goHome() {
      window.location.assign('https://www.sapporobeer.jp/');
    },
    //退会処理
    unRegist() {
      //退会処理の前にGA4で送信するSUIDを取得する
      this.suidGa4 = this.memberinfo.suid;
      axiosAuth
        .post('/social/unRegist', {
          accesstoken: this.accesstoken,
          mail: this.memberinfo.mailaddress
        })
        .then((response) => {
          this.$store.dispatch('logout');
          this.step = '3';
        })
        .catch((error) => {
          this.step = '1';
          this.unRegistError = UNREGISTERROR;
          if (!error.response) {
            this.$store.dispatch('logout');
          } else if (error.response.status === 404) {
            this.$store.dispatch('logout');
          } else {
            if (error.response.status === 503) {
              window.location.assign(MAINTAINURL);
              return;
            } else if (error.response.status === 429) {
              window.location.assign(OVERLOADURL);
              return;
            } else {
              this.$store.dispatch('logout');
            }
          }
        });
    },
    //完了画面にて必要な値を取得し、GA4へデータを送信
    doGa4() {
      ga4.unRegistGa4(this.suidGa4);
      ga4.sendToGa4(window,document,'script','dataLayer','GTM-MCT8RPC');
    }
  },
  beforeMount() {
    //ページタイトルを設定
    document.title = this.$route.meta.title;
  }
};
</script>

<style scoped>
th {
  width: 25%;
}
td > ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
@media only screen and (max-width: 769px) {
  th {
    width: 94.5%;
  }
  div.function .button-listB01.action li {
    margin-bottom: 1em;
  }
  td > ul {
    margin: 0;
    padding: 0.7em 10px;
    list-style: disc;
  }
}
</style>

<!-- ログアウト画面 -->
<template>
  <!-- リンクのない共通ヘッダー -->
  <HeaderOld>
    <div id="page-container">
      <div id="structure">
        <div class="panel-group">
          <div class="panelFull-group">
            <div class="panelFull first-child">
              <div class="panelFull-content panelFull-content01">
                <div class="headingA01">
                  <h1>
                    <img
                      src="/common/img/reg/heading/ttl_h1_03.gif"
                      width="135"
                      height="15"
                      alt="アカウント登録の解除"
                    />
                  </h1>
                  <!-- /headingA01 -->
                </div>

                <div class="textA01">
                  <p>
                    以下のサービスのアカウント登録を解除してもよろしいでしょうか？
                    <br />登録を解除すると、こちらのアカウント使ってログインすることができなくなります。
                  </p>
                  <!-- /textA01 -->
                </div>

                <ul class="note-listA01">
                  <li>
                    <span class="marker">※</span
                    >各種サービスのアカウント登録を解除しても、メールアドレス／パスワードを使ってマイアカウントにログインすることはできます。
                  </li>
                </ul>

                <!--errorMSGstart-->
                <!--errorMSGend-->

                <div class="form-inputA01">
                  <form name="form1" id="form1" method="post">
                    <div class="form-tableA01">
                      <table>
                        <tbody>
                          <tr>
                            <th scope="row" class="social">ログイン登録</th>
                            <td>
                              <div class="social-acount-listA01">
                                <dl>
                                  <dt>
                                    <img
                                      :src="providerImg"
                                      width="19"
                                      height="17"
                                      alt="Facebookを解除する"
                                    />
                                  </dt>
                                  <dd>
                                    {{ providerTitle }}
                                    <!--で登録済み-->
                                  </dd>
                                </dl>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <!-- /form-tableA01 -->
                    </div>

                    <div class="button-listB01 action">
                      <ul>
                        <li class="back">
                          <input
                            type="image"
                            name="rt"
                            id="rt"
                            src="/common/img/shared/btn/function/btn_back_01.gif"
                            class="swap-img"
                            alt="戻る"
                            @click.prevent="goback"
                          />
                        </li>
                        <li class="unlink">
                          <input
                            type="image"
                            name="et"
                            id="et"
                            src="/common/img/shared/btn/function/btn_unresister_01.gif"
                            class="swap-img avoid-double-click"
                            alt="登録を解除する"
                            @click.prevent="unLink"
                          />
                        </li>
                      </ul>
                      <input type="hidden" name="provider" value="facebook" />
                      <input type="hidden" name="action" value="unassociate" />
                      <input
                        type="hidden"
                        name="se"
                        id="se"
                        value="$1$2EdBOHhj$AFmmtIJBW1/LHqu8MlMcT/"
                      />
                      <input
                        type="hidden"
                        name="ti"
                        id="ti"
                        value="1596006363"
                      />
                      <!-- /button-listB01 -->
                    </div>
                  </form>
                  <!-- /form-inputA01 -->
                </div>

                <!-- /panelFull-content -->
              </div>
              <!-- /panelFull -->
            </div>
            <!-- /panelFull-group -->
          </div>
          <!-- /panel-group -->
        </div>
      </div>
    </div>
  </HeaderOld>
</template>

<script>
import HeaderOld from '../components/HeaderOld';
import axiosAuth from '../services/axiosAuth';
import { MAINTAINURL } from '../common/const';
/* eslint-disable no-unused-vars */
export default {
  components: {
    HeaderOld
  },

  computed: {
    //認証のJWTを取得
    accesstoken() {
      return this.$store.getters.accesstoken;
    },
    provider() {
      return this.$route.params.provider;
    },
    providerTitle() {
      let title = '';
      switch (this.provider) {
        case 'facebook':
          title = 'Facebook';
          break;
        case 'google':
          title = 'Google';
          break;
        case 'twitter':
          title = 'X';
          break;
        case 'yahoo':
          title = 'Yahoo! JAPAN ID';
          break;
        case 'line':
          title = 'LINE';
          break;
      }
      return title;
    },
    providerImg() {
      return '/common/img/shared/btn/ico_social_s_' + this.provider + '_01.gif';
    }
  },
  methods: {
    goback() {
      window.location.assign('/');
    },

    //ソーシャルプラス連携解除処理
    unLink() {
      let provider = this.provider;
      if (this.provider === 'google') {
        provider = 'google';
      }
      axiosAuth
        .post('/social/dissociate', {
          accesstoken: this.accesstoken,
          provider: provider
        })
        .then((response) => {
          window.location.assign('/');
        })
        .catch((error) => {
          if (!error.response) {
            this.$store.dispatch('setSocialMessage', 'sever');
            this.$router.push('/');
          } else if (error.response.status === 503) {
            window.location.assign(MAINTAINURL);
          } else if (error.response.status === 404) {
            this.$store.dispatch('setSocialMessage', 'nolink');
            this.$router.push('/');
          } else {
            this.$store.dispatch('setSocialMessage', 'sever');
            this.$router.push('/');
          }
        });
    }
  },
  created() {
    if (
      this.provider != 'facebook' &&
      this.provider != 'line' &&
      this.provider != 'google' &&
      this.provider != 'yahoo' &&
      this.provider != 'twitter'
    ) {
      window.location.assign('/');
    }
  },
  beforeMount() {
    //ページタイトルを設定
    document.title = this.$route.meta.title;
  }
};
</script>

<style scoped>
.form-tableA01 table th {
  width: 25%;
}
</style>

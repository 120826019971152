<!-- ログアウト画面 -->
<template>
  <!-- リンクのない共通ヘッダー -->
  <HeaderOld>
    <div id="page-container">
      <div id="structure">
        <h1 class="hd">{{ spTitle }}</h1>
        <div class="panel-group">
          <div class="panelFull-group">
            <div class="panelFull first-child">
              <div class="panelFull-content panelFull-content01">
                <template v-if="!loggedOut">
                  <div class="headingA01">
                    <h1>
                      <img
                        src="/common/img/logout/heading/ttl_h1_01.gif"
                        width="110"
                        height="15"
                        alt="ログアウトの確認"
                      />
                    </h1>
                    <!-- /headingA01 -->
                  </div>
                  <div class="textA01">
                    <p>本当にログアウトしてもよろしいでしょうか？</p>
                    <!-- /textA01 -->
                  </div>

                  <div class="form-inputA01">
                    <div class="button-listB01 action">
                      <ul>
                        <li class="back">
                          <input
                            type="image"
                            name="rt"
                            id="rt"
                            src="/common/img/shared/btn/function/btn_back_01.gif"
                            class="swap-img"
                            alt="戻る"
                            @click.prevent="goback"
                          />
                        </li>
                        <li class="logout">
                          <input
                            type="image"
                            src="/common/img/shared/btn/function/btn_logout_01.gif"
                            class="swap-img"
                            alt="ログアウトする"
                            @click.prevent="logout"
                          />
                        </li>
                      </ul>
                      <!-- /button-listB01 -->
                    </div>

                    <!-- /form-inputA01 -->
                  </div>
                </template>
                <template v-else>
                  <div class="headingA01">
                    <h1>
                      <img
                        src="/common/img/logout/heading/ttl_h1_02.gif"
                        width="66"
                        height="15"
                        alt="ログアウト"
                      />
                    </h1>
                    <!-- /headingA01 -->
                  </div>

                  <div class="important-informationB02">
                    <div class="important-informationB02-content">
                      <div class="important-informationB02-content-body">
                        <div class="textB01">
                          <p class="ct flat">
                            <em>ログアウトが完了しました。</em>
                          </p>
                          <!-- /textB01 -->
                        </div>

                        <div class="button-listB01 action">
                          <ul>
                            <li class="gohome-logout">
                              <input
                                type="image"
                                name="submit"
                                src="/common/img/shared/btn/function/btn_go-home_04.gif"
                                class="swap-img"
                                alt="ホームへ"
                                @click.prevent="goHome"
                              />
                            </li>
                          </ul>
                          <!-- /button-listB01 -->
                        </div>
                        <!-- /important-informationB02-content-body -->
                      </div>
                      <!-- /important-informationB02-content -->
                    </div>
                    <!-- /important-informationB02 -->
                  </div>
                </template>
                <!-- /panelFull-content -->
              </div>
              <!-- /panelFull -->
            </div>
            <!-- /panelFull-group -->
          </div>
          <!-- /panel-group -->
        </div>
      </div>
    </div>
  </HeaderOld>
</template>

<script>
import HeaderOld from '../components/HeaderOld';

export default {
  data() {
    return {
      loggedOut: false
    };
  },
  computed: {
    spTitle() {
      let title = '';
      if (this.loggedOut === false) {
        title = 'ログアウトの確認';
      } else {
        title = 'ログアウト';
      }
      return title;
    }
  },
  components: {
    HeaderOld
  },
  methods: {
    goback() {
      window.location.assign('/');
    },
    logout() {
      this.$store.dispatch('logout');
      this.loggedOut = true;
    },
    goHome() {
      window.location.assign('https://www.sapporobeer.jp/');
    }
  },
  beforeMount() {
    //ページタイトルを設定
    document.title = this.$route.meta.title;
  }
};
</script>

<style scoped>
@media only screen and (max-width: 769px) {
  div.textA01 > p {
    font-weight: bold;
  }
}
</style>

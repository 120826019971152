<!-- ロゴだけの共通ヘッダー、退会、ログアウトなどの画面に適用 -->
<template>
  <div class="function">
    <!-- ヘッダー -->
    <div class="layout-ie7" id="simple-header"></div>

    <slot />
    <!-- フッター -->
    <div class="layout-ie7" id="simple-footer"></div>
  </div>
</template>

<script>
export default {
  props: [],
  methods: {}
};
</script>

<style scoped>
ul.note-listA01 li,
ul.note-listB01 li {
  font-size: 83% !important;
}
h1.hd {
  display: none;
  width: 100%;
  padding: 5px 5px 0 5px;
  text-align: center;
  font-size: 1.1em !important ;
}
@media only screen and (max-width: 1000px) {
  div.function {
    width: 1000px !important;
  }
}
@media only screen and (max-width: 769px) {
  div.function {
    width: 400px !important;
  }
  div#page-container {
    min-height: 400px !important;
  }
  div#structure,
  div.panelFull {
    width: 375px !important;
    margin: 10px auto 3px auto !important;
  }
  .panelFull .panelFull-content.panelFull-content01 {
    padding: 10px !important;
    min-height: 80px !important;
  }
  .panel-group {
    margin: 0 auto !important;
  }
  table {
    width: 100%;
  }
  th,
  td {
    display: block;
    text-align: left;
    padding: 8px 9px !important;
  }

  div.headingA01,
  h2.step {
    display: none;
  }
  h1.hd {
    display: block;
  }
  .layout-footer .layout-footer_copyright > img {
    margin: 0 auto 20px auto !important;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  dt,
  dd,
  li,
  caption,
  col,
  th,
  td,
  p,
  address,
  figcaption {
    font-size: 1.1em !important;
  }
  ul.note-listA01 li,
  ul.note-listB01 li {
    font-size: 0.9em !important;
  }
}
div.function div.form-tableA01 table th,
div.function div.form-tableA01 table td {
  word-break: break-all;
}
#page-container {
  margin: 10px auto 10;
}
div.function {
  margin: 0 auto;
  padding: 0;
  width: auto;
  font-family: 'ヒラギノ角ゴ ProN W3', 'Hiragino Kaku Gothic ProN',
    'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'Meiryo UI',
    'MS UI Gothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif !important;
  font-size: 12px !important;
  /* font-size: 75%; */
  line-height: 1.5 !important;
  color: #4d3b17;
  background-color: #f0ebdf;
}
/* div.function input.swap-img */
.action ul li {
  letter-spacing: normal;
  display: inline-block;
  *display: inline;
  *zoom: 1;
  margin: 0 10px 0 10px;
}
.action ul li a {
  display: block;
  height: 45px;
  overflow: hidden;
}
div.function .button-listB01.action li {
  display: inline-table !important;
}
.action ul li.confirm-unregist {
  background: transparent
    url('/common/img/shared/btn/function/btn_dropout_02_o.gif') no-repeat left
    top;
}
.action ul li.back {
  background: transparent
    url('/common/img/shared/btn/function/btn_back_01_o.gif') no-repeat left top;
}
.action ul li.cancel-unregist {
  background: transparent
    url('/common/img/shared/btn/function/btn_not-dropout_01_o.gif') no-repeat
    left top;
}
.action ul li.unregist {
  background: transparent
    url('/common/img/shared/btn/function/btn_dropout_01_o.gif') no-repeat left
    top;
}
.action ul li.gohome-unregist {
  background: transparent
    url('/common/img/shared/btn/function/btn_go-home_02_o.gif') no-repeat left
    top;
}
.action ul li.gohome-logout {
  background: transparent
    url('/common/img/shared/btn/function/btn_go-home_04_o.gif') no-repeat left
    top;
}
.action ul li.logout {
  background: transparent
    url('/common/img/shared/btn/function/btn_logout_01_o.gif') no-repeat left
    top;
}
.action ul li.unlink {
  background: transparent
    url('/common/img/shared/btn/function/btn_unresister_01_o.gif') no-repeat
    left top;
}
.swap-img:hover {
  opacity: 0;
  /* IE */
  filter: alpha(opacity=0);
  zoom: 1;
}
.action li:active {
  border: black 1px solid;
}
h2 {
  display: block;
  font-size: 1.5em !important;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}
p {
  margin: 0;
  padding: 0;
}
img {
  border: 0 none;
  vertical-align: bottom;
}
em {
  font-style: normal;
  font-weight: bold;
}
strong {
  color: #cc3300;
}
sup {
  vertical-align: 0.333em;
  font-size: 75%;
  *font-size: 100%;
}
sub {
  vertical-align: baseline;
  font-size: 75%;
  *font-size: 100%;
}
hr {
  width: 100%;
  height: 1px;
  margin: 1.95em 0 0;
  *margin-top: 1.4em;
  padding: 0;
  border: 0 none;
  color: #e2dbbf;
  background-color: #e2dbbf;
}
a:link {
  color: #806a00;
  text-decoration: underline;
}
a:visited {
  color: #80774c;
  text-decoration: underline;
}
a:focus {
  color: #e6bf00;
  text-decoration: none;
}
a:link:hover,
a:visited:hover,
a:link:active,
a:visited:active {
  color: #e6bf00;
  text-decoration: none;
}
a.url:link,
a.url:visited {
  text-decoration: none;
}
a.url:focus {
  text-decoration: none;
}
a.url:link:hover,
a.url:visited:hover,
a.url:link:active,
a.url:visited:active {
  text-decoration: none;
}
.center {
  text-align: center;
  zoom: 1;
}
.right {
  text-align: right;
  zoom: 1;
}
.none {
  display: none;
}
span.red {
  color: #cc3300;
}
span.blue {
  color: #0065cc;
}
h1,
h2,
h3,
h4,
h5,
h6,
dt,
dd,
li,
caption,
col,
th,
td,
p,
address,
figcaption {
  font-size: 1em !important;
}

/* ============================== mod_structure.css ============================== */
#structure {
  width: 950px;
  margin: 0 auto;
  padding: 0 5px;
  position: relative;
  *overflow: hidden;
  *zoom: 1;
}
#structure:after {
  clear: both;
  display: block;
  content: '';
}
#structure a:hover img,
#structure a:focus img,
#structure a.rollover:hover img.norollover,
#structure a.rollover:focus img.norollover {
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(opacity=70)';
  filter: Alpha(opacity=70);
  opacity: 0.7;
  zoom: 1;
}
#structure a.rollover:hover img,
#structure a.rollover:focus img,
#structure a.rollover-button:hover img,
#structure a.rollover-button:focus img,
#structure a:hover span.attention img,
#structure a:focus span.attention img,
#structure a.with-icon:hover img,
#structure a.with-icon:focus img,
#structure a.rollover:hover span img,
#structure a.rollover:focus span img,
#structure li.extra a:hover img,
#structure li.extra a:focus img,
#structure a.swap-img:hover img,
#structure a.swap-img:focus img,
#structure .tabView-tabs a:hover img,
#structure .tabView-tabs a:focus img {
  -ms-filter: none;
  filter: none;
  opacity: 1;
  zoom: default;
}
#structure a[href^='http']:after {
  content: url('/common/img/shared/ico/ico_window_02.gif');
  margin: 0 0 0 0.3em;
  background: transparent none;
  vertical-align: middle;
}
#structure a[href*='sapporobeer.jp']:after {
  content: '' !important;
  margin: 0;
}
#structure .product-blockC01 .product-name a:after,
#structure .saparate2column .figureA04 a:after,
#structure .saparate2column .product-detailA01 .figureA03 a:after,
#structure .movie-select a:after,
#structure dl.float-area dt a:after,
#structure a.rollover-button:after {
  content: '' !important;
  margin: 0;
}
#structure .definition-listC03 dt a:after {
  vertical-align: 1px;
}
#structure a[href$='.pdf']:before {
  content: url('/common/img/shared/ico/ico_pdf_01.gif');
  margin: 0 0.3em 0 0;
  background: transparent none;
  vertical-align: middle;
}
#structure div.topicpath {
  width: 950px;
  margin: 0 auto;
  padding: 0;
}
#structure div.topicpath p {
  padding: 10px 0 0;
  _padding-top: 11px;
  font-size: 0.92em;
  line-height: 1;
}
#structure div.topicpath p a:link,
#structure div.topicpath p a:visited {
  color: #806a00;
  text-decoration: none;
}
#structure div.topicpath p a:focus {
  color: #e6bf00;
  text-decoration: none;
}
#structure div.topicpath p a:link:hover,
#structure div.topicpath p a:visited:hover,
#structure div.topicpath p a:link:active,
#structure div.topicpath p a:visited:active {
  color: #e6bf00;
  text-decoration: none;
}
#structure div.topicpath p span {
  color: #4d3b17;
}

/* ============================== mod_headings.css ============================== */

div.headingA01 {
  margin: 0;
  padding: 1.1em 0 0;
  padding-top: 1em;
  font-size: 150%;
}
div.headingA01 h1 {
  margin: 0;
  padding: 0;
  font-size: 81%;
}
div.headingA01.article-title {
  clear: both;
  padding-top: 0.9em;
}
div.headingA01 .supertitle,
div.headingA01 .subtitle {
  display: block;
  font-size: 80%;
  font-weight: normal;
}
div.headingA01 .supertitle {
  margin-bottom: 0.3em;
}
div.headingA01-cm .supertitle {
  margin-bottom: 1.2em;
  *margin-bottom: 1em;
}
div.headingA01.article-title .subtitle {
  position: relative;
  top: -3px;
  _position: static;
}
h2.headingA01 {
  margin: 0;
  padding: 1.9em 0 0;
  font-size: 100%;
}
h2.headingA01.first-child {
  padding-top: 1.1em;
}
h2.headingA02 {
  margin: 0;
  padding: 2.5em 0 0;
  *padding-top: 2.4em;
  font-size: 100%;
}
h2.headingA02.first-child {
  padding-top: 1.7em;
}
h2.headingB01 {
  margin: 0;
  padding: 1.9em 10px 0;
  font-size: 100%;
}
h2.headingB01.first-child {
  padding-top: 1.1em;
}
body.product div.panelFull-content.article-page h2.headingB01 {
  padding-right: 0;
  padding-left: 0;
}
h2.headingB02 {
  margin: 0;
  padding: 2.3em 10px 0;
  *padding-top: 2.25em;
  font-size: 100%;
}
h2.headingB02.first-child {
  padding-top: 1.5em;
  *padding-top: 1.4em;
}
div.headingA02 {
  margin: 0;
  padding: 0;
  font-size: 100%;
}
div.headingA02 h2 {
  margin: 0;
  padding: 0;
}
div.headingA02 h2.heading-lv2A01 {
  margin: -8px 0 -14px 7px;
  _position: relative;
}
h2.headingA02 img {
  vertical-align: middle;
  position: relative;
  *position: static;
  top: -1px;
  *top: 0;
}
h2.headingA02 span {
  font-weight: normal;
}
h2.headingA02.line-up {
  padding-top: 0;
}
h2.headingA02.not-found {
  font-weight: normal;
}
h2.headingA02.not-found span {
  font-weight: bold;
}
h2.panelL-headingA01 {
  margin: 0;
  padding: 0;
  position: absolute;
  left: -1px;
  top: -26px;
}
h2.panelL-headingA01 img {
  _behavior: url(/css/shared/alphaImageLoader.htc);
}
div.headingA03 {
  margin: 0;
  padding: 0;
  font-size: 100%;
  height: 25px;
}
div.headingA03 h2 {
  margin: 0;
  padding: 0;
}
h2.panelL-headingA03 {
  margin: 0;
  padding: 0;
  position: absolute;
  left: 5px;
  top: -10px;
}
h2.panelL-headingA03 img {
  _behavior: url(/css/shared/alphaImageLoader.htc);
}
h3.headingA01 {
  margin: 0;
  padding: 1.1em 0 0 0;
  color: #8c6e00;
  font-size: 100%;
}
h3.headingA02 {
  margin: 0;
  padding: 1.45em 0 0 0;
  color: #8c6e00;
  font-size: 100%;
}
h3 a:link,
h3 a:visited {
  text-decoration: none;
}
h3 a:focus {
  text-decoration: none;
}
h3 a:link:hover,
h3 a:visited:hover,
h3 a:link:active,
h3 a:visited:active {
  text-decoration: none;
}
div.feature-headingA01 {
  min-height: 130px;
  *min-height: 133px;
  _height: 130px;
  margin-top: 3.3em;
  *margin-top: 3.1em;
  border-bottom: 1px solid #e2dbbf;
}
div.feature-headingA01.first-child {
  margin-top: 0.8em;
  *margin-top: 0.6em;
}
div.feature-headingA01 h2.headingA02 {
  padding-top: 0;
  padding-left: 461px;
  font-size: 100%;
  position: relative;
  zoom: 1;
}
div.feature-headingA01 h2.headingA02 img {
  position: absolute;
  left: 0;
  top: 0;
}
div.feature-headingA01 div.textA01 p {
  padding-top: 0;
  padding-bottom: 0.8em;
  padding-left: 461px;
}

/* ============================== mod_panel.css ============================== */

.panel-group {
  margin: 0 -10px 0 0;
  *margin-right: -30px;
  padding: 0;
  _position: relative;
  zoom: 1;
}
.panel-group:before,
.panel-group:after {
  content: '.';
  clear: both;
  display: block;
  height: 0;
  visibility: hidden;
}
.panelFull {
  width: 950px;
  margin: 10px 0 0;
  padding: 0;
  background: #fff url(/common/img/shared/bg/bg_main_02.gif) 0 0 no-repeat !important;
}
.panelFull.withTab {
  margin: 0;
  background-image: none !important;
}
.panelFull .panelFull-content {
  margin: 0;
  padding: 0 10px 10px;
  background: url(/common/img/shared/bg/bg_main-inner_02.gif) 0 100% no-repeat;
  zoom: 1;
}
.panelFull .panelFull-content:before,
.panelFull .panelFull-content:after {
  content: '.';
  clear: both;
  display: block;
  height: 0;
  visibility: hidden;
}
.panelFull .panelFull-content.article-page {
  padding: 0 20px 56px;
}
.panelFull .panelFull-content.panelFull-content01 {
  padding-left: 140px;
  padding-right: 140px;
}
.panelFull .panelFull-content.panelFull-content02 {
  padding: 0 20px 20px;
}
div.mobile .panelFull .panelFull-content.panelFull-content02 {
  padding-bottom: 15px;
  *padding-bottom: 7px;
}
.panelL-group {
  float: left;
  width: 710px;
  margin: 0;
  padding: 0;
}
div.recipe .panelL-group {
  _width: 710px;
}
.panelL {
  margin: 10px 0 0;
  padding: 0;
  background: #fff url(/common/img/shared/bg/bg_main_01.gif) 0 0 no-repeat;
  position: relative;
  zoom: 1;
}
.panelL:before,
.panelL:after {
  content: '.';
  clear: both;
  display: block;
  height: 0;
  visibility: hidden;
}
.panelL .panelL-content {
  margin: 0;
  padding: 20px 20px 10px;
  background: url(/common/img/shared/bg/bg_main-inner_01.gif) 0 100% no-repeat;
  zoom: 1;
}
.panelL .panelL-content:before,
.panelL .panelL-content:after {
  content: '.';
  clear: both;
  display: block;
  height: 0;
  visibility: hidden;
}
div.life .panelL .panelL-content {
  padding: 20px 20px 0;
}
.panelL .panelL-content.index-page {
  _width: 670px;
  padding: 0 20px 10px;
}
.panelL .panelL-content.panelL-content-inner {
  _width: 690px;
  padding: 0 10px 10px;
}
.panelL .panelL-content.panelL-content-inner.inner {
  _width: 690px;
  padding: 0 10px;
}
.panelL .article {
  margin: 0;
  padding: 0 0 4.15em 85px;
  width: 585px;
  zoom: 1;
}
.panelL .article:before,
.panelL .article:after {
  content: '.';
  clear: both;
  display: block;
  height: 0;
  visibility: hidden;
}
.panelR {
  float: left;
  width: 230px;
  margin: 10px 0 0 10px;
  padding: 0;
}
div.gourmet .panelR {
  _margin-top: 0;
  _padding-top: 10px;
}
.panel2column {
  float: left;
  width: 470px;
  margin: 10px 10px 0 0;
  padding: 0;
  background: #fff url(/common/img/shared/bg/bg_column02_01.gif) 0 0 no-repeat;
}
.panel2column .panel2column-content {
  margin: 0;
  padding: 0 10px 10px;
  background: url(/common/img/shared/bg/bg_column02_02.gif) 0 100% no-repeat;
  position: relative;
  zoom: 1;
}
div#sapporo-related-area div.withTab {
  background: #fff url(/common/img/shared/bg/bg_column02_03.gif) 0 0 no-repeat !important;
}
div#sapporo-related-area div.withTab h2.headingA02 img {
  position: relative;
  top: 2px;
}
div#sapporo-related-area div.withTabB01 {
  background: #fff url(/common/img/shared/bg/bg_column02_12.gif) 0 0 no-repeat !important;
}
div#sapporo-related-area div.withTabB01 h2.headingA02 img {
  top: 3px;
}
.panel2columnB01-group {
  float: left;
  width: 470px;
  margin: 10px 10px 0 0;
  padding: 0;
  background: #fff url(/common/img/shared/bg/bg_column02_02.gif) 0 100%
    no-repeat;
}
.panel2columnB01 {
  margin: 0;
  padding: 0;
  background: url(/common/img/shared/bg/bg_column02_01.gif) 0 0 no-repeat;
}
.panel2columnB01.divide {
  padding-top: 13px;
  background: url(/common/img/shared/bg/bg_column02_09.gif) 0 0 no-repeat;
  zoom: 1;
}
.panel2columnB01 .panel2columnB01-content {
  margin: 0;
  padding: 0 20px 1.7em;
  position: relative;
}
.panel2columnB01 .panel2columnB01-head {
  min-height: 154px;
  _height: 154px;
  margin: 0 -10px 0 0;
  _margin: 0 -10px 0 -20px;
  padding: 0 0 0 220px;
  _padding: 0 0 0 240px;
}
.panel2columnB01 .panel2columnB01-head h2.headingA02 {
  padding-top: 1.7em;
}
.panel2columnB01 .panel2columnB01-head h2.headingA02 a:link,
.panel2columnB01 .panel2columnB01-head h2.headingA02 a:visited {
  text-decoration: none;
}
.panel2columnB01 .panel2columnB01-head h2.headingA02 a:focus {
  text-decoration: none;
}
.panel2columnB01 .panel2columnB01-head h2.headingA02 a:link:hover,
.panel2columnB01 .panel2columnB01-head h2.headingA02 a:visited:hover,
.panel2columnB01 .panel2columnB01-head h2.headingA02 a:link:active,
.panel2columnB01 .panel2columnB01-head h2.headingA02 a:visited:active {
  text-decoration: none;
}
#structure div.topicpath p span {
  color: #4d3b17;
}

/* ============================== mod_mp.css ============================== */

.lt {
  text-align: left;
}
.rt {
  text-align: right;
}
.ct {
  text-align: center;
}
.multi-column2 .column {
  float: left;
  padding-left: 20px;
}
.multi-column2 .column .column {
  padding-left: 15px;
}
.multi-column2 .column.first {
  margin-left: 0 !important;
  padding-left: 0;
}
.multi-column2 .column {
  width: 325px;
}
.multi-column2 .column .multi-column2 .column {
  width: 155px;
}
.multi-column2 .column.first-child,
.multi-column3 .column.first-child {
  padding-left: 0 !important;
  _margin-top: 0 !important;
}
.panelFull-content02 .multi-column2 .column {
  width: 670px;
}
.panelFull-content02 .multi-column2 .column.first {
  width: 220px;
}
.panelFull-content02 .multi-column2 .multi-column3 .column {
  width: 210px;
  padding-left: 20px;
}
.multi-column3 {
  *zoom: 1;
}
.multi-column3:after {
  clear: both;
  content: '';
  display: block;
}
div.bajl-enabled #section1 .multi-column3.frame .column {
  height: auto;
}
p code {
  background: #eeeeee;
  border: #999999 solid 1px;
  display: block;
  padding: 0.8em;
}
ol ol {
  margin: 0 0 0 3em;
  padding: 0.8em 0 0;
  list-style-type: none;
}
ol ol li {
  margin: 0.25em 0 0;
  padding: 0;
  position: relative;
  zoom: 1;
}
ol ol li:first-child {
  margin-top: 0;
}
ol ol li span.marker {
  width: 1.5em;
  padding-right: 5px;
  text-align: right;
  position: absolute;
  top: 0;
  right: 100%;
}
.flat {
  margin-top: 0 !important;
  padding-top: 0 !important;
}
.lt {
  text-align: left;
}
.rt,
table.rt td {
  text-align: right;
}
.ct,
table.ct td {
  text-align: center;
}
img.lt,
img.rt {
  vertical-align: middle;
}
img.lt {
  float: left;
  margin-right: 0.8em;
}
img.rt {
  float: right;
  margin-left: 0.8em;
}
.listA01,
.listA02 {
  margin: 0;
  list-style: none;
}
.listA01 li,
.listA02 li {
  padding-left: 15px;
  text-indent: -15px;
}
.listA01 li .marker,
.listA02 li .marker {
  margin-right: 9px;
}
.listA01 {
  padding: 0.2em 0 0;
  *padding-top: 0.25em;
}
.listA02 {
  padding: 0.6em 0 0;
}

div.button-listC01 ul {
  list-style-type: none;
  margin-bottom: 0;
  margin-left: 0;
  padding-left: 0;
}
div.button-listC01 ul li {
  display: inline;
}
.float-area {
  *zoom: 1;
}
.float-area:after {
  clear: both;
  content: '';
  display: block;
}
dl.float-area {
  margin: 0;
  padding: 0.8em 0 0;
}
dl.float-area dt {
  margin: 0;
  padding: 0;
}
dl.float-area dd {
  margin: 0;
  padding: 0;
}
div.float-area p.ajustA01 img {
  padding-top: 1.5em;
  *padding-top: 1.55em;
}
div.figure-blockB01 {
  width: 170px;
}
div.figure-blockB01.rt {
  float: right;
  margin-left: 20px;
  text-align: left;
}
div.figure-blockB01.lt {
  float: left;
  margin-right: 20px;
}
div.figureB01 {
  font-size: 83.33%;
  margin-bottom: 10px;
}
div.figureB01 .figure-object img {
  margin-top: 0.3em;
}
div.figureB01 .figure-caption {
  color: #a69d8b;
  margin-top: 0.5em;
}

/* ============================== mod_list.css ============================== */

ul.unordered-listA01,
ol.ordered-listA01 {
  margin: 0;
  padding: 0.8em 0 0;
}
ul.unordered-listA01 li,
ol.ordered-listA01 li {
  margin: 0;
  padding: 0.25em 0 0;
}
ul.unordered-listA01 li:first-child,
ol.ordered-listA01 li:first-child {
  padding-top: 0;
}
div.textA01 ul.unordered-listA01,
div.textA01 ol.ordered-listA01 {
  padding-left: 45px;
}
ul.note-listA01,
ul.note-listB01 {
  margin: 0;
  padding: 0.3em 0 0 1.25em;
  *padding-top: 0.15em;
  list-style-type: none;
}
ul.note-listA01 li,
ul.note-listB01 li {
  margin: 0.25em 0 0;
  padding: 0;
  /* font-size: 83% !important; */
  text-indent: -1.5em;
}
ul.note-listA01 li:first-child,
ul.note-listB01 li:first-child {
  margin-top: 0;
}
ul.note-listA01 li span.marker,
ul.note-listB01 li span.marker {
  margin-right: 0.5em;
}
ul.note-listB01 {
  color: #c30;
}
dl.definition-listA01 {
  margin: 0;
  padding: 0.8em 0 0;
}
dl.definition-listA01 dt {
  margin: 0;
  padding: 0.8em 0 0;
  font-weight: bold;
}
dl.definition-listA01 dd {
  margin: 0;
  padding: 0.25em 0 0;
}
dl.definition-listA02 {
  margin: 0;
  padding: 0;
}
dl.definition-listA02 dt {
  margin: 0;
  padding: 0.8em 0 0;
  font-weight: bold;
}
dl.definition-listA02 dd {
  margin: 0;
  padding: 0.25em 0 0;
}
dl.definition-listA02 dt a:link,
dl.definition-listA02 dt a:visited {
  text-decoration: none;
}
dl.definition-listA02 dt a:focus {
  text-decoration: none;
}
dl.definition-listA02 dt a:link:hover,
dl.definition-listA02 dt a:visited:hover,
dl.definition-listA02 dt a:link:active,
dl.definition-listA02 dt a:visited:active {
  text-decoration: none;
}
dl.definition-listB01 {
  margin: 0;
  padding: 0.8em 0 0 0;
  zoom: 1;
}
dl.definition-listB01:before,
dl.definition-listB01:after {
  content: '.';
  clear: both;
  display: block;
  height: 0;
  visibility: hidden;
}
dl.definition-listB01 dt {
  clear: left;
  float: left;
  margin: 0;
  padding: 0 20px 0 0;
  font-weight: bold;
}
dl.definition-listB01 dd {
  float: left;
  margin: 0;
  padding: 0;
}
dl.definition-listB01 dd ul.link-listB01 {
  padding-top: 0;
}
dl.definition-listC01 {
  width: 210px;
  margin: 0;
  padding: 1.3em 0 0;
}
dl.definition-listC01.first-child {
  padding-top: 0;
}
.panelL
  .column-group-wide
  .saparate3column
  .panel-asideA01
  dl.definition-listC01.first-child,
.panelR dl.definition-listC01.first-child {
  padding-top: 0.8em;
}
dl.definition-listC01 dt {
  margin: 0;
  padding: 0;
  font-weight: bold;
}
dl.definition-listC01 dt a:link,
dl.definition-listC01 dt a:visited,
dl.definition-listC01 dt a:hover,
dl.definition-listC01 dt a:active {
  text-decoration: none !important;
}
dl.definition-listC01 dt a img {
  display: block;
  padding-bottom: 0.6em;
}
dl.definition-listC01 dd {
  margin: 0;
  padding: 0.25em 0 0;
}
.saparate3columnB01 dl.definition-listC01 {
  margin-top: 2.15em;
  padding-top: 0;
}
.saparate3columnB01 dl.definition-listC01.first-child {
  margin-top: 0;
}
dl.definition-listC01 dd.icon {
  zoom: 1;
  margin: 0.3em 0 0;
}
dl.definition-listC01 dd.icon:after {
  content: '.';
  clear: both;
  display: block;
  height: 0;
  visibility: hidden;
}
dl.definition-listC01 dd.icon span.point-info-iconB01,
dl.definition-listC01 dd.icon span.image-icon {
  float: left;
  margin-right: 1px;
}
dl.definition-listC01 dd.icon span img {
  display: inline;
}
dl.definition-listC01 dd.icon span.point-info-iconB01 {
  font-size: 10px;
  font-weight: bold;
  text-align: center;
}
dl.definition-listC01 dd.icon span.point-info-iconB01.oubo {
  background: url(/mypage/img/point/point_info_icon_b01-oubo.gif) 0 0 no-repeat;
  width: 78px;
  height: 14px;
  padding-top: 2px;
  position: relative;
}
dl.definition-listC01 dd.icon span.point-info-iconB01.plus {
  background: url(/mypage/img/point/point_info_icon_b01-plus.gif) 0 0 no-repeat;
  width: 80px;
  height: 13px;
  padding-top: 3px;
  position: relative;
}
dl.definition-listC01 dd.icon span.point-info-iconB01.plus img {
  _behavior: url(/css/shared/alphaImageLoader.htc);
}
dl.definition-listC01 dd.icon span.point-info-iconB01.plus .plusB01-icon {
  position: absolute;
  top: -7px;
  left: 0;
}
dl.definition-listC01 dd.icon span.point-info-iconB01.plus span.device-starB01 {
  color: #4d3b17;
  position: absolute;
  top: 0;
  left: 38px;
}
dl.definition-listC01
  dd.icon
  span.point-info-iconB01.plus
  span.device-pointB01 {
  color: #4d3b17;
  position: absolute;
  top: 0;
  left: 49px;
  background: url('/mypage/img/point/point_info_icon_c01-plus_r.png') no-repeat
    scroll 100% 100% transparent;
  height: 16px;
  padding-right: 5px;
}
dl.definition-listC02 {
  float: left;
  width: 340px;
  margin: 0;
  padding: 0 0 0 10px;
}
dl.definition-listC02.first-child {
  padding-left: 0;
}
dl.definition-listC02 dt {
  margin: 0;
  padding: 0;
  font-weight: bold;
}
dl.definition-listC02 dt a:link,
dl.definition-listC02 dt a:visited,
dl.definition-listC02 dt a:hover,
dl.definition-listC02 dt a:active {
  text-decoration: none !important;
}
dl.definition-listC02 dt span {
  display: inline-block;
  padding-top: 0.6em;
  cursor: pointer;
}
dl.definition-listC02 dd {
  margin: 0.1em 0 0;
  padding: 0;
}
dl.definition-listC03 {
  width: 210px;
  margin: 1.3em 0 0;
  padding: 0;
  position: relative;
}
dl.definition-listC03.first-child {
  margin-top: 0;
}
.panel4column dl.definition-listC03.first-child {
  margin-top: 0;
  padding-top: 1.7em;
}
dl.definition-listC03 dt {
  margin: 0;
  padding: 0;
  font-weight: bold;
}
dl.definition-listC03 dt a:link,
dl.definition-listC03 dt a:visited,
dl.definition-listC03 dt a:hover,
dl.definition-listC03 dt a:active {
  text-decoration: none !important;
}
dl.definition-listC03 dt span {
  display: inline-block;
  padding-top: 10px;
}
dl.definition-listC03 dt a span {
  cursor: pointer;
}
dl.definition-listC03 dd {
  margin: 0;
  padding: 0;
  *padding-top: 0.5em;
}
div.definition-listD01-group {
  margin-top: 1.4em;
  zoom: 1;
}
div.definition-listD01-group:before,
div.definition-listD01-group:after {
  content: '.';
  clear: both;
  display: block;
  height: 0;
  visibility: hidden;
}
div.definition-listD01-group.first-child {
  margin-top: 0.8em;
}
div.definition-listD01-group dl.definition-listD01 {
  margin-top: 0;
}
dl.definition-listD01 {
  float: left;
  width: 210px;
  margin: 0.8em 0 0 20px;
  padding: 0;
  position: relative;
}
dl.definition-listD01.first-child {
  margin-left: 0;
}
dl.definition-listD01 dt {
  margin: 0;
  padding: 0;
  font-weight: bold;
}
dl.definition-listD01 dt a:link,
dl.definition-listD01 dt a:visited,
dl.definition-listD01 dt a:hover,
dl.definition-listD01 dt a:active {
  text-decoration: none !important;
}
dl.definition-listD01 dt span.attention {
  position: absolute;
  left: -9px;
  top: -5px;
}
dl.definition-listD01 dt span.attention img {
  _behavior: url(/css/shared/alphaImageLoader.htc);
}
dl.definition-listD01 dt span.shop-name {
  display: inline-block;
  padding-top: 0.6em;
  cursor: pointer;
}
dl.definition-listD01 dd {
  margin: 0;
  padding: 0;
}
dl.definition-listD01 dd.note {
  font-size: 83%;
}
dl.definition-listE01 {
  margin: 0;
  padding: 0.8em 0 0;
  border-bottom: 1px solid #e2dbbf;
}
dl.definition-listE01 dt {
  margin: 0;
  padding-top: 0.4em;
  border-top: 1px solid #e2dbbf;
}
dl.definition-listE01 dd {
  margin: 0;
  padding: 0 0 0.2em 0;
}
dl.definition-listE01 dd a:link,
dl.definition-listE01 dd a:visited,
dl.definition-listE01 dd a:hover,
dl.definition-listE01 dd a:active {
  text-decoration: none !important;
}
div.selected-listA01 {
  display: inline;
}
div.selected-listA01 ul {
  display: inline;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
div.selected-listA01 ul li {
  display: inline;
  margin: 0 6px 0 0;
  *margin-right: 10px;
  padding: 0 9px 0 0;
  *padding-right: 5px;
  border-right: 1px solid #e2dbbf;
}
dl.element-table {
  margin: 0;
  padding: 0.8em 0 0;
  zoom: 1;
}
dl.element-table:before,
dl.element-table:after {
  content: '.';
  clear: both;
  display: block;
  height: 0;
  visibility: hidden;
}
dl.element-table dt,
dl.element-table dd {
  margin: 0;
  padding: 0.33em 0 0;
}
dl.element-table dt {
  clear: both;
  float: left;
  width: 150px;
  padding-left: 9px;
  background: url(/common/img/shared/bg/bg-th01.gif) 1px 0.9em no-repeat;
}
dl.element-table dd {
  margin-left: 150px;
  padding-left: 9px;
  background: url(/common/img/shared/bg/bg-td01.gif) 1px 0.7em no-repeat;
}
dl.element-table dd.table-data {
  clear: both;
  margin-left: 0;
  padding-left: 0;
  background: 0;
}
ul.movie-select {
  margin: 4px 0 0;
  padding: 0;
  list-style: none;
}
ul.movie-select:after {
  content: '.';
  clear: both;
  display: block;
  height: 0;
  visibility: hidden;
}
ul.movie-select li {
  float: left;
  margin: 0 0 0 4px;
}
ul.movie-select li.first-child {
  margin: 0;
}
ul.icon-listA01 {
  margin: -3px 0 0;
  padding: 0;
  list-style-type: none;
  zoom: 1;
}
ul.icon-listA01:before,
ul.icon-listA01:after {
  content: '.';
  clear: both;
  display: block;
  height: 0;
  visibility: hidden;
}
ul.icon-listA01 li {
  float: left;
  margin: 0;
  padding: 3px 3px 0 0;
}
ul.icon-listA01 li a,
ul.icon-listA01 li img {
  display: block;
}
div.function div.option-listB01 ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
ul.figure-block-listA01 {
  margin: 0.8em 0 0;
  padding: 0;
  list-style-type: none;
  border-top: 1px solid #e2dbbf;
  zoom: 1;
}
ul.figure-block-listA01.first-child {
  border-top: 0;
}
ul.figure-block-listA01:before,
ul.figure-block-listA01:after {
  content: '.';
  clear: both;
  display: block;
  height: 0;
  visibility: hidden;
}
ul.figure-block-listA01 li {
  float: left;
  width: 210px;
  margin: 0;
  padding: 0.7em 0 0;
  *padding-top: 0.6em;
}
ul.figure-block-listA01 li.odd {
  padding-right: 10px;
}
ul.figure-block-listA01 li dl,
ul.figure-block-listA01 li dt,
ul.figure-block-listA01 li dd {
  margin: 0;
  padding: 0;
}
ul.figure-block-listA01 li dt a:link,
ul.figure-block-listA01 li dt a:visited {
  font-weight: bold;
  text-decoration: none;
}
ul.figure-block-listA01 li dt a:focus {
  text-decoration: none;
}
ul.figure-block-listA01 li dt a:link:hover,
ul.figure-block-listA01 li dt a:visited:hover,
ul.figure-block-listA01 li dt a:link:active,
ul.figure-block-listA01 li dt a:visited:active {
  text-decoration: none;
}
ul.figure-block-listA01 li dt img {
  float: left;
  padding-top: 2px;
  *padding-top: 3px;
  padding-right: 10px;
}
ul.figure-block-listA01 li dt span {
  font-weight: normal;
}
.panelFull ul.figure-block-listA01 {
  margin: 0 -30px 0 0;
  padding: 0.9em 0 0.5em;
  *padding-top: 0.8em;
  border-top: 0;
}
.panelFull ul.figure-block-listA01 li {
  padding-right: 30px;
}
.saparate3columnB01 ul.figure-block-listA01 {
  margin-top: 0;
  border-top: 0;
}
.saparate3columnB01 ul.figure-block-listA01 li {
  padding-top: 1.1em;
  padding-right: 0;
}
.panel4column ul.figure-block-listA01 {
  margin-top: 0;
  padding-top: 0.85em;
  border-top: 0;
}
.panel4column ul.figure-block-listA01 li {
  padding-right: 0;
}
ul.figure-block-listA02 {
  margin: 1.3em 0 0;
  padding: 0;
  list-style-type: none;
  border-top: 1px solid #e2dbbf;
  zoom: 1;
}
ul.figure-block-listA02.first-child {
  margin-top: 0;
  border-top: 0;
}
ul.figure-block-listA02:before,
ul.figure-block-listA02:after {
  content: '.';
  clear: both;
  display: block;
  height: 0;
  visibility: hidden;
}
ul.figure-block-listA02 li {
  float: left;
  width: 210px;
  margin: 0;
  padding: 1.5em 0 0;
  *padding-top: 1.4em;
}
ul.figure-block-listA02 li.odd {
  padding-right: 10px;
}
ul.figure-block-listA02 li dl,
ul.figure-block-listA02 li dt,
ul.figure-block-listA02 li dd {
  margin: 0;
  padding: 0;
}
ul.figure-block-listA02 li dt a:link,
ul.figure-block-listA02 li dt a:visited {
  font-weight: bold;
  text-decoration: none;
}
ul.figure-block-listA02 li dt a:focus {
  text-decoration: none;
}
ul.figure-block-listA02 li dt a:link:hover,
ul.figure-block-listA02 li dt a:visited:hover,
ul.figure-block-listA02 li dt a:link:active,
ul.figure-block-listA02 li dt a:visited:active {
  text-decoration: none;
}
ul.figure-block-listA02 li dt img {
  float: left;
  padding-top: 2px;
  *padding-top: 3px;
  padding-right: 10px;
}
ul.figure-block-listA02 li dt span {
  font-weight: normal;
}

/* ============================== mod_link-list.css ============================== */

ul.link-listA01 {
  margin: 0;
  padding: 0.5em 0 0;
  *padding-top: 0.55em;
  list-style-type: none;
}
ul.link-listA01 li {
  margin: 0;
  padding: 0.25em 0 0;
}
ul.link-listA01 li:first-child {
  padding-top: 0;
}
ul.link-listA01 li a {
  padding-left: 10px;
  zoom: 1;
}
ul.link-listA01 li a:link,
ul.link-listA01 li a:visited {
  text-decoration: none;
  background: url(/common/img/shared/ico/ico_arrow_01.gif) 0 0.2em no-repeat;
  *background-position: 0 0.5em;
}
ul.link-listA01 li a:focus {
  text-decoration: none;
  background-image: url(/common/img/shared/ico/ico_arrow_01_o.gif);
}
ul.link-listA01 li a:link:hover,
ul.link-listA01 li a:visited:hover,
ul.link-listA01 li a:link:active,
ul.link-listA01 li a:visited:active {
  text-decoration: none;
  background-image: url(/common/img/shared/ico/ico_arrow_01_o.gif);
}
ul.link-listA01.detail {
  zoom: 1;
}
ul.link-listA01.detail:before,
ul.link-listA01.detail:after {
  content: '.';
  clear: both;
  display: block;
  height: 0;
  visibility: hidden;
}
ul.link-listA01.detail li {
  padding-top: 0;
}
ul.link-listA01 li.readmore {
  float: left;
  margin-top: -2px;
  *margin-top: 3px;
}
ul.link-listA01 li.readmore a:link,
ul.link-listA01 li.readmore a:visited {
  padding-left: 0;
  background: none;
}
ul.link-listA01 li.readmore a:focus {
  padding-left: 0;
  background: none;
}
ul.link-listA01 li.readmore a:link:hover,
ul.link-listA01 li.readmore a:visited:hover,
ul.link-listA01 li.readmore a:link:active,
ul.link-listA01 li.readmore a:visited:active {
  padding-left: 0;
  background: none;
}
ul.link-listA01 li.readmore a img {
  margin-right: 5px;
  *position: relative;
  *top: -4px;
}
ul.link-listA01 li.info {
  float: right;
  *position: relative;
  *top: 0px;
}
ul.utility-listA01 {
  float: right;
  margin: 0;
  padding: 0.45em 0 0;
  list-style-type: none;
}
ul.utility-listA01 li {
  float: left;
  margin: 0;
  padding: 0 0 0 20px;
}
ul.utility-listA01 li.fist-child {
  padding-left: 0;
}
ul.utility-listA01 li.extra {
  margin-top: -5px;
}
ul.utility-listA01 li a:link,
ul.utility-listA01 li a:visited {
  text-decoration: none;
  background: none;
}
ul.utility-listA01 li a:focus {
  text-decoration: none;
  background: none;
}
ul.utility-listA01 li a:link:hover,
ul.utility-listA01 li a:visited:hover,
ul.utility-listA01 li a:link:active,
ul.utility-listA01 li a:visited:active {
  text-decoration: none;
  background: none;
}
ul.link-listB01 {
  clear: both;
  margin: 0;
  padding: 0.8em 0 0;
  list-style-type: none;
  zoom: 1;
}
ul.link-listB01:before,
ul.link-listB01:after {
  content: '.';
  clear: both;
  display: block;
  height: 0;
  visibility: hidden;
}
ul.link-listB01 li {
  float: left;
  margin: 0;
  padding: 0 20px 0 0;
  white-space: nowrap;
  white-space: pre;
  zoom: 1;
}
ul.link-listB01 li a {
  padding-left: 10px;
}
ul.link-listB01 li a:link,
ul.link-listB01 li a:visited {
  text-decoration: none;
  background: url(/common/img/shared/ico/ico_arrow_01.gif) 0 0.2em no-repeat;
}
ul.link-listB01 li a:focus {
  text-decoration: none;
  background-image: url(/common/img/shared/ico/ico_arrow_01_o.gif);
}
ul.link-listB01 li a:link:hover,
ul.link-listB01 li a:visited:hover,
ul.link-listB01 li a:link:active,
ul.link-listB01 li a:visited:active {
  text-decoration: none;
  background-image: url(/common/img/shared/ico/ico_arrow_01_o.gif);
}
ul.button-listA01 {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
ul.button-listA01 li {
  margin: 10px 0 0;
  padding: 0;
}
ul.button-listA01 li a,
ul.button-listA01 li img {
  vertical-align: top;
}
ul.button-listB01 {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
ul.button-listB01 li {
  margin: 0.8em 0 0;
  padding: 0;
  text-align: right;
}
ul.button-listB01 li a {
  padding-left: 0;
  background: none;
}
.panel2columnB01 .panel2columnB01-content ul.button-listB01 li {
  margin-top: 0;
  position: relative;
  bottom: -10px;
}
ul.anchor-link-listA01,
ul.anchor-link-listB01 {
  margin: 0;
  padding: 0.8em 0 0;
  list-style-type: none;
}
ul.anchor-link-listA01 li,
ul.anchor-link-listB01 li {
  margin: 0;
  padding: 0.25em 0 0;
}
ul.anchor-link-listA01 li:first-child,
ul.anchor-link-listB01 li:first-child {
  padding-top: 0;
}
ul.anchor-link-listA01 li a,
ul.anchor-link-listB01 li a {
  padding-left: 10px;
}
ul.anchor-link-listA01 li a:link,
ul.anchor-link-listA01 li a:visited,
ul.anchor-link-listB01 li a:link,
ul.anchor-link-listB01 li a:visited {
  text-decoration: none;
  background: url(/common/img/shared/ico/ico_arrow_02.gif) 0 0.35em no-repeat;
  *background-position: 0 0.3em;
}
ul.anchor-link-listA01 a:focus,
ul.anchor-link-listB01 a:focus {
  text-decoration: none;
  background-image: url(/common/img/shared/ico/ico_arrow_02_o.gif);
}
ul.anchor-link-listA01 a:link:hover,
ul.anchor-link-listA01 a:visited:hover,
ul.anchor-link-listA01 a:link:active,
ul.anchor-link-listA01 a:visited:active,
ul.anchor-link-listB01 a:link:hover,
ul.anchor-link-listB01 a:visited:hover,
ul.anchor-link-listB01 a:link:active,
ul.anchor-link-listB01 a:visited:active {
  text-decoration: none;
  background-image: url(/common/img/shared/ico/ico_arrow_02_o.gif);
}
ul.anchor-link-listB01 li {
  display: inline;
  padding-top: 0;
  padding-right: 10px;
  white-space: nowrap;
  zoom: 1;
}
div.product div.panelFull-content.article-page ul.anchor-link-listA01 li {
  display: inline;
  padding-top: 0;
  padding-right: 10px;
  white-space: nowrap;
  zoom: 1;
}
div.bookmark ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
div.product div.bookmark ul {
  padding-top: 1.3em;
  *padding-top: 1.4em;
  text-align: right;
}
div.wine div.bookmark ul {
  padding-top: 1.7em;
  *padding-top: 1.65em;
  text-align: right;
}
div.bookmark ul li {
  margin: 0;
  padding: 0;
}
ul.link-listA01 li.plain {
  margin-left: 9px;
  padding-left: 0;
  text-indent: 0;
  background: none;
  list-style-type: disc;
}
ul.link-listA01 li.plain a:link,
ul.link-listA01 li.plain a:visited {
  padding-left: 0;
  text-decoration: underline;
}
ul.link-listA01 li.plain a:focus {
  text-decoration: none;
}
ul.link-listA01 li.plain a:link:hover,
ul.link-listA01 li.plain a:visited:hover,
ul.link-listA01 li.plain a:link:active,
ul.link-listA01 li.plain a:visited:active {
  text-decoration: none;
}
/* ============================== mod_form.css ============================== */

form {
  position: relative;
  margin: 0;
  padding: 0;
}
fieldset {
  margin: 0;
  padding: 0;
  border: 0 none;
}
legend {
  display: none;
}
input,
select {
  font-size: 100%;
}
input.text,
input.password {
  display: block;
  width: 40%;
  padding: 3px 5px 2px;
  *padding: 4px 5px 0;
  background-color: #fafafa;
  border: 1px solid #e2dbbf;
}
input.prompt {
  background-color: #fafafa;
  border: 1px solid #e2dbbf;
}
input.text.bajl-input-prompt {
  padding: 3px 5px 2px;
  *padding: 4px 5px 0;
  background-color: #fafafa;
  border: 1px solid #e2dbbf;
}
input.text.bajl-input-prompt-focus,
input.text.bajl-input-prompt-changed {
  background-color: #fff;
  color: #000;
}
label.prompt-label {
  margin: 1px 2px;
  position: absolute;
  color: #ccc;
  cursor: text;
}
input.radio {
  margin: 0 0.25em 0 0;
  *margin-right: 0;
  padding: 0;
  vertical-align: -0.167em;
  _vertical-align: 0;
}
input.checkbox {
  margin: 0 6px 0 0;
  *margin-right: 3px;
  padding: 0;
  vertical-align: -0.167em;
  _vertical-align: 0;
}
form table td.note * {
  display: inline;
  margin: 0;
  padding: 0;
}
form table td.note ul {
  list-style-type: none;
  margin-left: 9px;
}
form table td label.condition01 {
  padding-left: 16px;
  *padding-left: 12px;
  *position: relative;
  _position: static;
  *top: -0.2em;
}
#page-container.wine form div.data-tableA01 #taste-red,
#page-container.wine form div.data-tableA01 #taste-other {
  display: none;
}
div.option-listA01 {
  margin: 0;
  padding: 0;
}
div.option-listA01 ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
div.option-listA01 ul li {
  float: left;
  *margin-left: -4px;
  _margin-left: -2px;
  padding: 0 20px 0 0;
  white-space: nowrap;
}
div.option-listA01 ul li.pseudo-disabled {
  color: #ccc;
}
div.button-listA01,
div.button-listB01 {
  margin: 0;
  padding: 0.8em 0 0;
}
div.button-listA01 ul,
div.button-listB01 ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
div.button-listA01 ul li,
div.button-listB01 ul li {
  height: 25px !important;
  margin: 0;
  padding: 0;
}
div.button-listB01 {
  text-align: center;
}
div.form-blockA01 {
  zoom: 1;
}
div.form-blockA01:before,
div.form-blockA01:after {
  content: '.';
  clear: both;
  display: block;
  height: 0;
  visibility: hidden;
}
div.form-blockA01 form div.data-tableB01 {
  padding-top: 0;
}
div.form-blockA01 form ul.unordered-listA01 {
  float: left;
  padding-top: 0;
  list-style-type: none;
}
div.form-blockA01 form ul.unordered-listA01 li {
  float: left;
  padding-top: 0;
  padding-right: 20px;
}
div.form-blockA01 form ul.unordered-listA01 label {
  font-weight: bold;
  *position: relative;
  *top: -2px;
}
div.form-blockA01 form ul.unordered-listA01 select {
  width: 141px;
}
div.form-blockA01 form div.button-listA01 {
  float: left;
  padding-top: 0;
  padding-left: 0;
  position: relative;
  top: -0.28em;
  *top: -0.15em;
}
div.form-blockA01 form ul.link-listA01 {
  float: left;
  padding-top: 0.05em;
  *padding-top: 0.1em;
}
div.form-blockA01 form ul.link-listA01 li {
  padding-top: 0;
}
div.form-blockA02 {
  margin: 0;
  padding: 0.8em 0 0.3em;
  zoom: 1;
}
div.form-blockA02:before,
div.form-blockA02:after {
  content: '.';
  clear: both;
  display: block;
  height: 0;
  visibility: hidden;
}
div.form-blockA02 input.text {
  float: left;
  margin: 0 6px 0 0;
  _margin-right: 3px;
}
div.form-blockA02 input.buttonA01 {
  margin-top: -2px;
}
#structure form.validation input.bajl-input-prompt {
  color: #ccc;
}
#structure form.validation input.bajl-input-prompt-focus,
#structure form.validation input.bajl-input-prompt-changed {
  color: black;
}
body #structure form.validation input.pseudo-error,
body #structure form.validation textarea.pseudo-error {
  background-color: #fff2f2;
  outline: 1px dotted #c30;
}
#structure form.validation ins.validation-field {
  margin: 0;
  padding: 0;
  display: inline;
  position: relative;
  text-decoration: none;
  zoom: 1;
}
#structure form.validation ins.error-message {
  position: absolute;
  left: 65%;
  bottom: 100%;
  background: url(/common/img/shared/bg/bg_error_01.png) 0 0 no-repeat;
  _background-image: url(/common/img/shared/bg/bg_error_01.gif);
  text-decoration: none;
  white-space: nowrap;
  font-size: 10px;
  cursor: default;
}
#structure .panel-asideA01 form.validation ins.error-message {
  left: 0;
}
#structure form.validation td.textarea ins.validation-field,
#structure form.validation td.textarea textarea {
  display: inline-block;
}
#structure form.validation td.textarea ins.error-message {
  top: -31px;
  *top: -37px;
}
#structure form.validation ins.error-message span {
  display: block;
  zoom: 1;
  background: url(/common/img/shared/bg/bg_error_02.png) 100% 0 no-repeat;
  _background-image: url(/common/img/shared/bg/bg_error_02.gif);
}
#structure form.validation ins.error-message span span {
  background: url(/common/img/shared/bg/bg_error_03.png) 0 100% no-repeat;
  _background-image: url(/common/img/shared/bg/bg_error_03.gif);
}
#structure form.validation ins.error-message span span span {
  padding: 0 4px 10px;
  background: url(/common/img/shared/bg/bg_error_04.png) 100% 100% no-repeat;
  _background-image: url(/common/img/shared/bg/bg_error_04.gif);
}
#structure form.validation ins.error-message span span span span {
  padding: 4px 0;
  background: #c00;
}
#structure form.validation ins.error-message span span span span span {
  margin: 0 -4px;
  padding: 2px 10px 2px 6px;
  *padding-top: 1px;
  *padding-bottom: 3px;
  background: #c00;
  color: #fff;
  position: relative;
}
#structure form.validation dl.form-tableA01 ins.error-message {
  left: 0;
}
.sp-all form.validation ins.validation-field {
  margin: 0;
  padding: 0;
  display: block;
  position: static;
  text-decoration: none;
  zoom: 1;
  opacity: 1 !important;
}
.sp-all form.validation ins.error-message {
  opacity: 1 !important;
  display: block;
  text-decoration: none;
  color: #c30;
  font-size: 10px;
}
.sp-all form.validation td.textarea ins.validation-field,
#structure form.validation td.textarea textarea {
  display: inline-block;
}
.sp-all form.validation ins.error-message span {
  display: block;
  zoom: 1;
}
.sp-all form.validation ins.error-message span span span {
  padding: 0;
}
.sp-all form.validation ins.error-message span span span span span {
  position: static;
}

/* ============================== mod_marketing_function.css ============================== */

div.function input.text,
div.function input.password,
div.function select,
div.function textarea {
  background: #fafafa;
  border: #e2dbbf solid 1px;
}
div.function div.form-tableA01 {
  border: #e2dbbf solid 1px;
  border-top: none;
  margin: 0.5em 0 0;
  padding: 0;
}
div.function div.form-tableA01 table {
  width: 100%;
  border-collapse: collapse;
}
div.function div.form-tableA01 table th,
div.function div.form-tableA01 table td {
  text-align: left;
  color: #4d3b17;
  border-top: #e2dbbf solid 1px;
}
div.function div.form-tableA01 table th {
  background: #fcf9f2;
  vertical-align: top;
  padding: 6px 9px;
}
div.function div.form-tableA01 table td.rowspan {
  border-right: #e2dbbf solid 1px;
  vertical-align: top;
}
div.function div.form-tableA01 table td {
  vertical-align: middle;
  padding: 3px 10px;
}
div.function dl.form-tableA01 {
  border: #e2dbbf solid 1px;
  margin: 0.5em 0 0;
  padding: 0;
}
div.function dl.form-tableA01:first-child {
  margin-top: 1.7em;
}
div.function dl.form-tableA01 dt.heading {
  color: #4d3b17;
  background: #fcf9f2;
  font-weight: bold;
  margin: 0;
  padding: 8px 9px;
}
div.function dl.form-tableA01 dd {
  color: #4d3b17;
  margin: 0;
  padding: 0.7em 9px;
}
div.function dl.form-tableA01 dd div.validate-required {
  position: relative;
  left: -4px;
}
div.function div.form-tableB01 table th,
div.function div.form-tableB01 table td {
  border: none;
  text-align: left;
  vertical-align: top;
  padding: 0;
}
div.function div.form-tableC01 th {
  display: none;
}
div.function div.form-tableC01 table th,
div.function div.form-tableC01 table td {
  border: none;
  text-align: left;
  padding: 0;
}
div.function span.requiredA01 {
  padding-right: 4px;
  font-weight: bold;
  color: #cc3300;
}
div.function th span.requiredA01 {
  padding-right: 0;
  padding-left: 5px;
}
div.function #bty {
  display: inline;
}
div.function input#bty,
div.function input#tel1,
div.function input#tel2,
div.function input#tel3,
div.function input#zip1,
div.function input#zip2 {
  width: 63px;
  padding: 2px 5px;
}
div.function input#sn,
div.function input#nm,
div.function input#ksn,
div.function input#knm {
  width: 162px;
}
div.function input#ml,
div.function input#oldPw,
div.function input#pw1,
div.function input#pw2,
div.function input#adr1,
div.function input#adr2,
div.function textarea#ban8 {
  width: 346px;
}
div.function .panel-asideA01 form input#ml,
div.function .panel-asideA01 form input#pw1 {
  width: 94.5%;
}
div.function textarea#view {
  width: 354px;
}
div.function input#pw1,
div.function input#pw2 {
  padding: 1px 5px 4px;
}
div.function .textA01 p .buttonB01,
div.function input#zipMatching,
div.function input#zipret {
  margin-left: 6px;
}
div.function .textA01 p textarea {
  margin-bottom: 5px;
}
div.function .form-itemA01 {
  margin-top: 10px;
  background: #fcf9f2 url(/common/img/shared/bg/bg_function-block03_01.gif) left
    top no-repeat;
}
div.function .form-itemA01-content {
  background: url(/common/img/shared/bg/bg_function-block03_02.gif) left bottom
    no-repeat;
  padding: 10px;
}
div.function .form-itemB01 {
  border-color: #e2dbbf;
  border-style: solid;
  border-width: 1px 0;
  margin: 10px 0 20px;
  padding: 10px;
}
div.function div.form-inputA01 ul.note-listA01 {
  margin-bottom: -1px;
  padding-top: 0.2em;
}
div.function
  div.form-inputA01
  form.validation
  dl.form-tableA01
  ins
  + ul.note-listA01 {
  padding-left: 18px;
}
div.function ul.note-listC01 {
  list-style-type: none;
  margin: 0.8em 0 0 20px;
  padding: 0;
}
div.function ul.note-listD01 {
  font-size: 83%;
  list-style: none;
  margin: 0.3em 0;
  padding: 0;
}
div.function ul.note-listE01 {
  list-style-type: none;
  margin: 0;
  padding: 0.3em 0 1em 1.25em;
}
div.function ul.note-listE01 li {
  font-size: 83%;
  text-indent: -1.5em;
  margin: 0.25em 0 0;
  padding: 0;
}
div.function div.button-listB01 {
  padding-top: 1.3em;
}
div.function .button-listB01.action li {
  display: inline;
  padding: 0 1px;
}
div.function .note-textA01 {
  margin-left: 20px;
  color: #8c742a;
  font-size: 83%;
}
div.function .plain,
div.function ul.plain li,
div.function ol.plain li,
div.function dl.plain dt,
div.function dl.plain dd {
  background-color: transparent;
  background-image: none;
  border: none;
  color: inherit;
  font-weight: normal;
  list-style: none;
  margin: 0;
  padding: 0;
}
div.function div.textA01 ul.plain {
  padding-top: 0.6em;
}
div.function dl.compactA01 dt,
div.function dl.compactA01 dd {
  float: left;
  margin: 0;
  padding: 0;
}
div.function dl.compactA01 dt {
  width: 2em;
}
div.function dl.compactA01 dd input.text {
  width: 100%;
}
div.function .panelFull .panelFull-content01 {
  min-height: 518px;
  _height: 518px;
  padding-bottom: 20px;
}
div.function div.headingA01 {
  padding: 0.9em 0 0;
}
div.function h2 {
  padding-top: 0.37em;
  margin: 0;
}
div.function h2.step {
  margin-bottom: 0.85em;
  padding-top: 0.85em;
}
div.function h2.headingA02,
div.function h3.headingA02 {
  font-size: 125%;
  color: #4d3b17;
  margin: 1.6em 0 0;
  padding: 0;
}
div.function h3.headingA03 {
  color: #4d3b17;
  font-size: 100%;
  margin: 0;
  padding: 1.45em 0 0;
}
div.function div.textA01 p {
  margin-top: 0.8em;
  padding-top: 0;
}
div.function div.textA01 p.flat {
  margin-top: 0.6em;
  word-break: break-all;
  word-wrap: break-word;
}
div.function div.important-informationB02 div.textA01 p,
div.function div.important-informationB02 div.textA01 p.flat {
  margin-top: 0.6em !important;
}
div.function h3.headingA03 + div.textA01 p {
  margin-top: 0.25em;
}
div.function img.icoB02 {
  margin-right: 3px;
  vertical-align: -0.2em;
}
div.function img.icoB03 {
  margin-right: 0;
}
div.function td div.option-listA01 ul,
div.function td div.option-listB01 ul,
div.function div.option-listA01 td ul,
div.function div.option-listB01 td ul {
  text-indent: 0;
}
div.function td div.option-listA01 ul:after {
  content: '.';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
div.function div.form-tableA01 div.option-listA01 ul li {
  padding-right: 9px;
}
div.function div.option-listC01 dd ul {
  position: relative;
  _position: static;
  left: -4px;
}
div.function div.option-listC01 dt em {
  padding-right: 10px;
}
div.function div.option-listC01 dd li {
  float: left;
  padding-right: 5px;
  list-style-type: none;
}
div.function .figureA02 {
  margin-top: 0.8em;
}
div.function div.headingA01 + .figureA02 {
  margin-top: 1.65em;
}
div.function .important-informationA01 {
  background: #fcf9f2 url(/common/img/shared/bg/bg_function-block03_01.gif) left
    top no-repeat;
  margin: 20px 0 10px;
}
div.function .important-informationA01-content {
  background: url(/common/img/shared/bg/bg_function-block03_02.gif) left bottom
    no-repeat;
  padding: 10px 10px 6px;
}
div.function .important-informationA01 div.button-listB01 {
  padding-top: 0;
}
div.function .important-informationB01 {
  color: #cc3300;
  background: #fff2f2 url(/common/img/shared/bg/bg_function-block02_01.gif) left
    top repeat-y;
  margin: 1.7em 0;
  padding: 0;
}
div.function .important-informationB01-content {
  background: url(/common/img/shared/bg/bg_function-block02_02.gif) left top
    no-repeat;
}
div.function .important-informationB01-content-body {
  background: url(/common/img/shared/bg/bg_function-block02_03.gif) left bottom
    no-repeat;
  padding: 1.3em 20px;
}
div.function .important-informationB02 {
  background: #fcf9f2 url(/common/img/shared/bg/bg_function-block01_01.gif) left
    top repeat-y;
  margin: 1.7em 0 0;
  padding: 0;
}
div.function .important-informationB02-content {
  background: url(/common/img/shared/bg/bg_function-block01_02.gif) left top
    no-repeat;
}
div.function .important-informationB02-content-body {
  background: url(/common/img/shared/bg/bg_function-block01_03.gif) left bottom
    no-repeat;
  padding: 1.3em 20px 1.5em;
}
div.function .important-informationC01 {
  padding-top: 0.8em;
  padding-bottom: 0;
}
div.function .important-informationC01 div.figureA01 {
  padding-left: 29px;
}
div.function .important-informationC01 ul.note-listA01 {
  padding-top: 1.85em;
}
div.function .important-informationC01 ul.note-listA01 li {
  text-align: center;
}
div.function .point-informationA01 {
  position: relative;
  z-index: 1;
  background: #fcf9f2
    url(/common/img/shared/bg/bg_point-information-block01_01.gif) left top
    repeat-y;
  width: 329px;
  margin: 1.7em auto 0;
  padding: 0;
}
div.function .point-informationA01-content {
  background: url(/common/img/shared/bg/bg_point-information-block01_02.gif)
    left top no-repeat;
}
div.function .point-informationA01-content-body {
  background: url(/common/img/shared/bg/bg_point-information-block01_03.gif)
    left bottom no-repeat;
  padding: 1.3em 20px 1.5em;
}
div.function .point-informationA01-content-div.with-star {
  background: url(/common/img/shared/bg/bg_point-information-block01_with-star.gif)
    left bottom no-repeat;
}
div.function .point-informationA01 .textA01 .ct {
  margin-top: 0.1em;
}
div.function .point-informationB01 {
  z-index: 2;
  background: #fcf9f2
    url(/common/img/shared/bg/bg_point-information-block01_04.gif) left top
    repeat-y;
  width: 329px;
  position: relative;
  margin: -1.6em auto 0;
  padding: 0;
}
div.function .point-informationB01-content {
  background: url(/common/img/shared/bg/bg_point-information_b01.jpg) no-repeat;
  background-position: 90% 80%;
  position: relative;
}
div.function .point-informationB01-content-body {
  background: url(/common/img/shared/bg/bg_point-information-block01_03.gif)
    left bottom no-repeat;
  position: relative;
  padding: 1.3em 20px 1.5em;
}
div.function .point-informationB01 .guidance-info-block {
  text-align: left;
  padding-top: 1.3em;
  border-top: solid 1px #e7e3d6;
}
div.function .point-informationB01 .guidance-info-block .link-listA01 li a {
  background-position: 0 0.4em;
}
div.function div.function .ct span.get-point-textA01 {
  padding-right: 0.3em;
}
div.function .point-informationA01 .textA01 .ct .get-point-textA01 {
  color: #4d3b17;
}
div.function .point-informationA01 .textB01 span.get-point-textA01 {
  color: #cc3300;
}
div.function .form-tableA01 span.get-point-textA01 {
  font-weight: bold;
  color: #cc3300;
}
div.function em .device-starD01,
div.function td .device-starD01 {
  color: #bfbc65;
}
div.function .scroll-areaA01 {
  height: 241px;
  overflow: auto;
  overflow-x: hidden;
  overflow-y: scroll;
  margin-top: 1.3em;
  border: #e2dbbf solid 1px;
  padding: 0 7px 7px;
}
div.function ul.note-listB01.error,
div.function div.textB01 p.flat,
div.function div.textB01 .note-listA01,
div.function div.important-informationB02 .note-listA01 {
  font-size: 120% !important;
}
div.function input.swap-img {
  vertical-align: bottom;
}
div.function dl.compactA01,
div.function div.option-listC01 dd ul,
.multi-column2 {
  height: 1%;
}
div.function dl.compactA01:after,
div.function div.option-listC01 dd ul:after,
.multi-column2:after {
  clear: both;
  content: '';
  display: block;
  height: 0;
  visibility: hidden;
}
div.function td .textA01.first-child ul.plain {
  margin-top: 0.75em;
}
div.function input.checkbox,
div.function input.radio {
  _vertical-align: middle;
}
div.function
  div.panel-asideA01
  div.panel-asideA01-content
  div.social-button-listA01 {
  margin-top: 0;
  margin-bottom: 10px;
  overflow: hidden;
}
div.function
  div.panel-asideA01
  div.panel-asideA01-content
  div.social-button-listA01
  ul.link-listA01 {
  margin-bottom: 10px;
}
div.function
  div.panel-asideA01
  div.panel-asideA01-content
  div.social-button-listA01
  ul.note-listB01.error {
  padding-top: 0 !important;
  margin-bottom: 10px;
}
div.function
  div.panel-asideA01
  div.panel-asideA01-content
  div.social-button-listA01
  ul.button-listA01
  li {
  float: left;
  list-style-type: none;
  padding-right: 8px;
  margin: 0;
}
div.function div.form-inputA01 div.social-button-listA01 {
  width: 420px;
  margin: 5px 0 0;
}
div.function div.form-inputA01 div.social-button-listA01 ul.button-listA01 li {
  float: left;
  list-style-type: none;
  margin: 0 10px 0 0;
  padding: 0 0 5px;
}
div.function div.form-inputA01 div.social-button-listA01 .note-listA01 {
  clear: both;
}
.social-button-listA01 .sp-login-embed ul li {
  padding-right: 8px;
}
.social-button-listA01 .sp-login-embed ul li a {
  width: 30px;
  height: 30px;
  background-position: left top;
  background-repeat: no-repeat;
}
.social-button-listA01 .sp-login-embed ul li a.facebook {
  background: url(/img/shared/btn/ico_social_facebook_01.gif) left top no-repeat;
}
.social-button-listA01 .sp-login-embed ul li a.twitter {
  background: url(/img/shared/btn/ico_social_twitter_01.gif) left top no-repeat;
}
.social-button-listA01 .sp-login-embed ul li a.google {
  background: url(/img/shared/btn/ico_social_google_01.gif) left top no-repeat;
}
.social-button-listA01 .sp-login-embed ul li a.yahoo {
  background: url(/img/shared/btn/ico_social_yahoo_01.gif) left top no-repeat;
}
.social-button-listA01 .sp-login-embed ul li a.mixi {
  background: url(/img/shared/btn/ico_social_mixi_01.gif) left top no-repeat;
}
div.function div.form-tableA01 table td .social-acount-listA01,
div.function dl.form-tableA01 dd.social-acount-listA01 {
  margin: 0 !important;
  padding: 0 !important;
}
div.function div.form-tableA01 table td .social-acount-listA01 dl,
div.function dl.form-tableA01 dd.social-acount-listA01 dl {
  margin: 0;
}
div.function div.form-tableA01 table td .social-acount-listA01 dl dt {
  clear: left;
  float: left;
  width: 19px;
  height: 17px;
  margin: 0;
  padding: 0.7em 6px 0.7em 0;
}
div.function dl.form-tableA01 dd.social-acount-listA01 dl dt {
  clear: left;
  float: left;
  width: 19px;
  height: 17px;
  margin: 0;
  padding: 0.7em 6px 0.7em 9px;
}
div.function div.form-tableA01 table td .social-acount-listA01 dl dd {
  font-weight: bold;
  margin: 0;
  padding: 9px 9px 0 0;
}
div.function dl.form-tableA01 dd.social-acount-listA01 dl dd {
  font-weight: bold;
  margin: 0;
  padding: 10px 9px 0 0;
}
div.function div.form-tableA01 table td div.textA01 p,
div.function div.form-tableB01,
div.function div.form-tableC01,
div.function .flat {
  margin-top: 0;
}
div.function dl.form-tableA01 dd div.option-listB01,
div.function ul.note-listB01.error {
  padding-top: 0.5em;
}
div.function div.form-tableB01 table,
div.function div.form-tableC01 table {
  width: 100%;
  border-collapse: collapse;
  border: none;
}
ul.note-listD01 li span.marker,
ul.note-listE01 li span.marker {
  margin-right: 0.5em;
}
div.function dl.compactA01,
div.function div.option-listC01,
div.function div.option-listC01 dl,
div.function div.option-listC01 dt,
div.function div.option-listC01 dd,
div.function div.option-listC01 dd ul,
div.function div.option-listC01 dd li {
  margin: 0;
  padding: 0;
}
div.function dl.compactA01 dd,
div.function img.icoB01 {
  margin-right: 10px;
}
div.function strong,
div.function .important-informationB02 div.textB01 em {
  color: #4d3b17;
}
div.function td div.option-listA01 ul,
div.function .form-itemA01-content,
div.function .important-informationA01-content,
div.function .important-informationB01-content-body,
div.function .important-informationB02-content-body {
  zoom: 1;
}
div.function textarea,
div.function div.important-informationB02 ul.note-listA01 {
  font-size: 100%;
}
div.function img.icoB01,
div.function img.icoB03,
div.function div.form-tableA01 table th.social {
  vertical-align: middle;
}
div.function
  div.panel-asideA01
  div.panel-asideA01-content
  div.social-button-listA01
  ul.button-listA01,
div.function div.form-inputA01 div.social-button-listA01 ul.button-listA01 {
  margin-bottom: 0;
}

/* ==========================================================================
   Reset
   ========================================================================== */
.layout-header div,
.breadcrumbs div,
.layout-footer div,
.layout-header span,
.breadcrumbs span,
.layout-footer span,
.layout-header h1,
.breadcrumbs h1,
.layout-footer h1,
.layout-header h2,
.breadcrumbs h2,
.layout-footer h2,
.layout-header h3,
.breadcrumbs h3,
.layout-footer h3,
.layout-header h4,
.breadcrumbs h4,
.layout-footer h4,
.layout-header h5,
.breadcrumbs h5,
.layout-footer h5,
.layout-header h6,
.breadcrumbs h6,
.layout-footer h6,
.layout-header p,
.breadcrumbs p,
.layout-footer p,
.layout-header blockquote,
.breadcrumbs blockquote,
.layout-footer blockquote,
.layout-header pre,
.breadcrumbs pre,
.layout-footer pre,
.layout-header a,
.breadcrumbs a,
.layout-footer a,
.layout-header abbr,
.breadcrumbs abbr,
.layout-footer abbr,
.layout-header address,
.breadcrumbs address,
.layout-footer address,
.layout-header cite,
.breadcrumbs cite,
.layout-footer cite,
.layout-header code,
.breadcrumbs code,
.layout-footer code,
.layout-header del,
.breadcrumbs del,
.layout-footer del,
.layout-header dfn,
.breadcrumbs dfn,
.layout-footer dfn,
.layout-header em,
.breadcrumbs em,
.layout-footer em,
.layout-header img,
.breadcrumbs img,
.layout-footer img,
.layout-header ins,
.breadcrumbs ins,
.layout-footer ins,
.layout-header kbd,
.breadcrumbs kbd,
.layout-footer kbd,
.layout-header q,
.breadcrumbs q,
.layout-footer q,
.layout-header s,
.breadcrumbs s,
.layout-footer s,
.layout-header samp,
.breadcrumbs samp,
.layout-footer samp,
.layout-header small,
.breadcrumbs small,
.layout-footer small,
.layout-header strong,
.breadcrumbs strong,
.layout-footer strong,
.layout-header sub,
.breadcrumbs sub,
.layout-footer sub,
.layout-header sup,
.breadcrumbs sup,
.layout-footer sup,
.layout-header var,
.breadcrumbs var,
.layout-footer var,
.layout-header b,
.breadcrumbs b,
.layout-footer b,
.layout-header u,
.breadcrumbs u,
.layout-footer u,
.layout-header i,
.breadcrumbs i,
.layout-footer i,
.layout-header dl,
.breadcrumbs dl,
.layout-footer dl,
.layout-header dt,
.breadcrumbs dt,
.layout-footer dt,
.layout-header dd,
.breadcrumbs dd,
.layout-footer dd,
.layout-header ol,
.breadcrumbs ol,
.layout-footer ol,
.layout-header ul,
.breadcrumbs ul,
.layout-footer ul,
.layout-header li,
.breadcrumbs li,
.layout-footer li,
.layout-header fieldset,
.breadcrumbs fieldset,
.layout-footer fieldset,
.layout-header form,
.breadcrumbs form,
.layout-footer form,
.layout-header label,
.breadcrumbs label,
.layout-footer label,
.layout-header legend,
.breadcrumbs legend,
.layout-footer legend,
.layout-header table,
.breadcrumbs table,
.layout-footer table,
.layout-header caption,
.breadcrumbs caption,
.layout-footer caption,
.layout-header tbody,
.breadcrumbs tbody,
.layout-footer tbody,
.layout-header tfoot,
.breadcrumbs tfoot,
.layout-footer tfoot,
.layout-header thead,
.breadcrumbs thead,
.layout-footer thead,
.layout-header tr,
.breadcrumbs tr,
.layout-footer tr,
.layout-header th,
.breadcrumbs th,
.layout-footer th,
.layout-header td,
.breadcrumbs td,
.layout-footer td,
.layout-header article,
.breadcrumbs article,
.layout-footer article,
.layout-header aside,
.breadcrumbs aside,
.layout-footer aside,
.layout-header canvas,
.breadcrumbs canvas,
.layout-footer canvas,
.layout-header details,
.breadcrumbs details,
.layout-footer details,
.layout-header embed,
.breadcrumbs embed,
.layout-footer embed,
.layout-header figure,
.breadcrumbs figure,
.layout-footer figure,
.layout-header figcaption,
.breadcrumbs figcaption,
.layout-footer figcaption,
.layout-header footer,
.breadcrumbs footer,
.layout-footer footer,
.layout-header header,
.breadcrumbs header,
.layout-footer header,
.layout-header main,
.breadcrumbs main,
.layout-footer main,
.layout-header menu,
.breadcrumbs menu,
.layout-footer menu,
.layout-header nav,
.breadcrumbs nav,
.layout-footer nav,
.layout-header output,
.breadcrumbs output,
.layout-footer output,
.layout-header ruby,
.breadcrumbs ruby,
.layout-footer ruby,
.layout-header section,
.breadcrumbs section,
.layout-footer section,
.layout-header summary,
.breadcrumbs summary,
.layout-footer summary,
.layout-header time,
.breadcrumbs time,
.layout-footer time,
.layout-header mark,
.breadcrumbs mark,
.layout-footer mark,
.layout-header audio,
.breadcrumbs audio,
.layout-footer audio,
.layout-header video,
.breadcrumbs video,
.layout-footer video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
.layout-header h1,
.breadcrumbs h1,
.layout-footer h1,
.layout-header h2,
.breadcrumbs h2,
.layout-footer h2,
.layout-header h3,
.breadcrumbs h3,
.layout-footer h3,
.layout-header h4,
.breadcrumbs h4,
.layout-footer h4,
.layout-header h5,
.breadcrumbs h5,
.layout-footer h5,
.layout-header h6,
.breadcrumbs h6,
.layout-footer h6 {
  font-weight: bold;
}
.layout-header table,
.breadcrumbs table,
.layout-footer table {
  border-collapse: collapse;
  border-spacing: 0;
}
.layout-header caption,
.breadcrumbs caption,
.layout-footer caption {
  text-align: left;
}
.layout-header th,
.breadcrumbs th,
.layout-footer th {
  font-weight: bold;
}
.layout-header th,
.breadcrumbs th,
.layout-footer th,
.layout-header td,
.breadcrumbs td,
.layout-footer td {
  vertical-align: top;
}
.layout-header ol,
.breadcrumbs ol,
.layout-footer ol,
.layout-header ul,
.breadcrumbs ul,
.layout-footer ul {
  list-style: none;
}
.layout-header input,
.breadcrumbs input,
.layout-footer input,
.layout-header textarea,
.breadcrumbs textarea,
.layout-footer textarea,
.layout-header select,
.breadcrumbs select,
.layout-footer select,
.layout-header button,
.breadcrumbs button,
.layout-footer button {
  margin: 0;
  padding: 0;
  font-size: 15;
  color: #333333;
  line-height: 1.9;
  outline: none;
  vertical-align: middle;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}
@media (max-width: 768px) {
  .layout-header input,
  .breadcrumbs input,
  .layout-footer input,
  .layout-header textarea,
  .breadcrumbs textarea,
  .layout-footer textarea,
  .layout-header select,
  .breadcrumbs select,
  .layout-footer select,
  .layout-header button,
  .breadcrumbs button,
  .layout-footer button {
    font-size: 14;
  }
}
/* ==========================================================================
   Layout - General
   ========================================================================== */
/* Clearfix
   ========================================================================== */
header,
footer,
main,
section,
article,
aside,
nav,
.layout-container,
.layout-header,
.page-footer,
.content,
.main-content,
.sub-content,
.article,
.section,
.row {
  zoom: 1;
}
header:before,
footer:before,
main:before,
section:before,
article:before,
aside:before,
nav:before,
.layout-container:before,
.layout-header:before,
.page-footer:before,
.content:before,
.main-content:before,
.sub-content:before,
.article:before,
.section:before,
.row:before,
header:after,
footer:after,
main:after,
section:after,
article:after,
aside:after,
nav:after,
.layout-container:after,
.layout-header:after,
.page-footer:after,
.content:after,
.main-content:after,
.sub-content:after,
.article:after,
.section:after,
.row:after {
  content: '';
  display: table;
}
header:after,
footer:after,
main:after,
section:after,
article:after,
aside:after,
nav:after,
.layout-container:after,
.layout-header:after,
.page-footer:after,
.content:after,
.main-content:after,
.sub-content:after,
.article:after,
.section:after,
.row:after {
  clear: both;
}
/* Root
   ========================================================================== */
body {
  margin: 0;
  min-width: 100%;
  font-feature-settings: 'palt';
  -webkit-font-feature-settings: 'palt';
  -moz-font-feature-settings: 'palt';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media (max-width: 768px) {
  body {
    min-width: 320px;
  }
}
/* Font face
   ========================================================================== */
@font-face {
  font-family: 'YakuHanJP';
  font-style: normal;
  font-weight: normal;
  src: url('/common/fonts/YakuHanJP-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('/common/fonts/YakuHanJP-Regular.woff2') format('woff2'),
    url('/common/fonts/YakuHanJP-Regular.woff') format('woff'),
    url('/common/fonts/YakuHanJP-Regular.otf') format('opentype');
}
@font-face {
  font-family: 'YakuHanJP';
  font-style: normal;
  font-weight: bold;
  src: url('/common/fonts/YakuHanJP-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('/common/fonts/YakuHanJP-Bold.woff2') format('woff2'),
    url('/common/fonts/YakuHanJP-Bold.woff') format('woff'),
    url('/common/fonts/YakuHanJP-Bold.otf') format('opentype');
}
/* @font-face {
  font-family: "Barlow Condensed";
  font-style: normal;
  font-weight: normal;
  font-weight: 500;
} */

/* Container
  ========================================================================== */
.layout-container {
  overflow-x: hidden;
  width: 100%;
}
@media (min-width: 769px) {
  .layout-container {
    min-width: 1080px;
  }
}
@media (max-width: 768px) {
  .layout-container.is-reduced {
    min-width: 960px;
  }
}
.is-fixed {
  position: fixed;
  top: 52px;
  width: 100% !important;
}
@media (max-width: 768px) {
  .is-fixed .layout-container.is-reduced {
    min-width: 100% !important;
  }
}
/* Section
   ========================================================================== */
.section {
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  margin-top: 90px;
}
.section:first-child {
  margin-top: 0;
}
.section:first-child {
  margin-top: 0;
}
@media (max-width: 768px) {
  .section {
    margin-top: 50px;
  }
}
.section .section {
  margin-top: 70px;
}
.section .section:first-child {
  margin-top: 0;
}
@media (max-width: 768px) {
  .section .section {
    margin-top: 40px;
  }
}
.section .section .section {
  margin-top: 60px;
}
/* Paragraph
   ========================================================================== */
.layout-header p,
.layout-footer p,
.breadcrumbs p {
  margin-top: 30px;
}
.layout-header p:first-child,
.layout-footer p:first-child,
.breadcrumbs p:first-child {
  margin-top: 0;
}
@media (max-width: 768px) {
  .layout-header p,
  .layout-footer p,
  .breadcrumbs p {
    margin-top: 20px;
  }
}
/* Image
   ========================================================================== */
.layout-header img,
.layout-footer img,
.breadcrumbs img {
  max-width: 100%;
  height: auto;
  max-height: 100%;
}
/* Anchor
   ========================================================================== */
.layout-header a,
.layout-footer a,
.breadcrumbs a {
  text-decoration: none;
}
@media (min-width: 769px) {
  .layout-header a:hover,
  .layout-footer a:hover,
  .breadcrumbs a:hover {
    text-decoration: underline;
  }
}
/* ==========================================================================
   Layout - Header
   ========================================================================== */
/* Page header
   ========================================================================== */
.layout-header {
  position: relative;
  z-index: 1000;
  padding: 0;
  margin: 0;
  background: #fff;
  font-size: 10px;
  line-height: 1.3;
}
@media (min-width: 769px) {
  .layout-header a:hover,
  .layout-header a:link:hover,
  .layout-header a:active:hover,
  .layout-header a:visited:hover {
    color: #222;
  }
}
.layout-header .layout-header_inner {
  position: relative;
  z-index: 2;
  width: 100%;
  margin: 0 auto;
}
.layout-header.is-fixed {
  position: fixed;
  top: 52px;
  width: 100% !important;
}
.layout-header.theme-new-window {
  height: 90px;
}
.layout-header.theme-new-window .layout-header_logo {
  top: 12px;
  right: inherit;
  left: 50px;
  width: inherit;
}
.layout-header.theme-new-window .layout-header_window-close {
  float: right;
  height: 90px;
  margin: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-left: 1px solid #d9d9d9;
  font-size: 1.5em;
  line-height: 90px;
}
.layout-header.theme-new-window .layout-header_window-close > a {
  display: block;
  padding: 0 45px;
  color: #222;
}
.layout-header.theme-new-window .layout-header_window-close > a > span {
  position: relative;
  padding-left: 25px;
}
.layout-header.theme-new-window .layout-header_window-close > a > span:before {
  position: absolute;
  top: 50%;
  left: 0;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  background: url(/sb/common/img/layout/header-new-window_bg_close.svg)
    no-repeat 0 0;
  background-size: 12px auto;
  content: '';
}
.layout-header.theme-new-window .layout-header_window-close > a:hover {
  opacity: 0.7;
  cursor: pointer;
  text-decoration: none;
}
.layout-header.theme-print .layout-header_logo {
  left: 40px;
  top: 35px;
}
.layout-header.theme-print .m-btn-list {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-top: 0;
  padding: 35px 40px 80px;
}
.layout-header.theme-print .m-btn-list li {
  max-width: 280px;
}
.layout-header.theme-simple,
.layout-header.theme-form {
  height: 90px;
}
@media (max-width: 768px) {
  .layout-header.theme-simple,
  .layout-header.theme-form {
    height: 55px;
  }
}
.layout-header.theme-simple .layout-header_logo,
.layout-header.theme-form .layout-header_logo {
  top: 12px;
  right: inherit;
  left: 50px;
  width: inherit;
}
@media (max-width: 768px) {
  .layout-header.theme-simple .layout-header_logo,
  .layout-header.theme-form .layout-header_logo {
    top: 6px;
    right: 0;
    left: 0;
    width: 33px;
    margin: 0 auto;
    padding: 0;
  }
}
/* Header navigation (PC)
   ========================================================================== */
@media (min-width: 769px) {
  .layout-header {
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
  }
  .layout-header .layout-header_logo {
    position: absolute;
    top: 50px;
    right: inherit;
    left: 50px;
    width: 40px;
  }
  .layout-header .layout-header_nav-pc {
    clear: both;
  }
  .layout-header .layout-header_nav-pc .primary-nav {
    zoom: 1;
  }
  .layout-header .layout-header_nav-pc .primary-nav:before,
  .layout-header .layout-header_nav-pc .primary-nav:after {
    content: '';
    display: table;
  }
  .layout-header .layout-header_nav-pc .primary-nav:after {
    clear: both;
  }
  .layout-header .layout-header_nav-pc .primary-nav > ul {
    float: right;
    display: table;
    margin: 0;
    padding: 0;
  }
  .layout-header .layout-header_nav-pc .primary-nav > ul > li {
    display: table-cell;
    max-height: 90px;
    padding: 0 40px 0 0;
    font-size: 1.5em;
    font-weight: bold;
    vertical-align: middle;
    white-space: nowrap;
  }
  .layout-header .layout-header_nav-pc .primary-nav > ul > li > a {
    display: inline-block;
    position: relative;
    max-height: 74px;
    padding: 28px 0 27px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-align: center;
    text-decoration: none;
  }
  .layout-header .layout-header_nav-pc .primary-nav > ul > li > a:after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background: #f3be00;
    -webkit-transition: -webkit-transform 0.4s 0.15s
      cubic-bezier(0.19, 1, 0.22, 1);
    transition: -webkit-transform 0.4s 0.15s cubic-bezier(0.19, 1, 0.22, 1);
    transition: transform 0.4s 0.15s cubic-bezier(0.19, 1, 0.22, 1);
    transition: transform 0.4s 0.15s cubic-bezier(0.19, 1, 0.22, 1),
      -webkit-transform 0.4s 0.15s cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: right;
    -ms-transform-origin: right;
    transform-origin: right;
    content: '';
  }
  .layout-header .layout-header_nav-pc .primary-nav > ul > li > a:hover:after {
    -webkit-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: left;
    -ms-transform-origin: left;
    transform-origin: left;
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
  }
  .layout-header
    .layout-header_nav-pc
    .primary-nav
    > ul
    .primary-nav_dropdown
    > a {
    padding-right: 18px;
  }
  .layout-header
    .layout-header_nav-pc
    .primary-nav
    > ul
    .primary-nav_dropdown
    > a:after {
    content: none;
  }
  .layout-header
    .layout-header_nav-pc
    .primary-nav
    > ul
    .primary-nav_dropdown
    > a:before {
    background: url(/sb/common/img/parts/icon-down-arrow_bg.svg) no-repeat 0 0;
    background-size: 10px 6px;
    content: '';
    height: 6px;
    margin-top: -3px;
    width: 10px;
    position: absolute;
    right: 0;
    top: 50%;
  }
  .layout-header .layout-header_nav-pc .primary-nav > ul .primary-nav_company,
  .layout-header
    .layout-header_nav-pc
    .primary-nav
    > ul
    .primary-nav_employment {
    position: relative;
    padding: 0;
    font-size: 1.2em;
  }
  .layout-header
    .layout-header_nav-pc
    .primary-nav
    > ul
    .primary-nav_company:before,
  .layout-header
    .layout-header_nav-pc
    .primary-nav
    > ul
    .primary-nav_employment:before {
    display: block;
    position: absolute;
    top: 15px;
    width: 1px;
    height: -webkit-calc(100% - 30px);
    height: calc(100% - 30px);
    margin-top: 0;
    background: #d9d9d9;
    content: '';
  }
  .layout-header .layout-header_nav-pc .primary-nav > ul .primary-nav_company a,
  .layout-header
    .layout-header_nav-pc
    .primary-nav
    > ul
    .primary-nav_employment
    a {
    padding: 42px 20px 18px;
  }
  .layout-header
    .layout-header_nav-pc
    .primary-nav
    > ul
    .primary-nav_company
    a:hover,
  .layout-header
    .layout-header_nav-pc
    .primary-nav
    > ul
    .primary-nav_employment
    a:hover {
    opacity: 0.7;
  }
  .layout-header
    .layout-header_nav-pc
    .primary-nav
    > ul
    .primary-nav_company
    a:hover:after,
  .layout-header
    .layout-header_nav-pc
    .primary-nav
    > ul
    .primary-nav_employment
    a:hover:after {
    content: none;
  }
  .layout-header .layout-header_nav-pc .primary-nav a {
    color: #222;
  }
  .layout-header .layout-header_nav-pc .mega-dropdown {
    display: none;
    position: absolute;
    left: 0;
    width: 100%;
    background: #f1f2f6;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .layout-header .layout-header_nav-pc .mega-dropdown a {
    color: #222;
  }
  .layout-header .layout-header_nav-pc .js-dropdown-menu_content {
    opacity: 0;
    margin-top: -30px;
    padding-bottom: 30px;
  }
  .layout-header .layout-header_nav-pc .mega-dropdown_inner {
    display: table;
    max-width: 1200px;
    margin: 0 auto;
    padding: 50px 0;
  }
  .layout-header .layout-header_nav-pc .is-active:hover .mega-dropdown_inner {
    -webkit-animation: fadeIn 1s ease 0s 1 normal;
    animation: fadeIn 1s ease 0s 1 normal;
  }
  .layout-header .layout-header_nav-pc .mega-dropdown_title {
    display: table-cell;
    width: 275px;
    font-size: 1.66em;
    vertical-align: top;
  }
  .layout-header .layout-header_nav-pc .mega-dropdown_title > a {
    display: inline-block;
    position: relative;
    padding: 30px 40px 30px 0;
    text-decoration: none;
    background: url(/sb/common/img/layout/mega-dropdown_bg_arrow.svg) no-repeat
      right center;
    background-size: 15px auto;
  }
  .layout-header .layout-header_nav-pc .mega-dropdown_title > a:before {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 30px;
    height: 1px;
    background: #222;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
    content: '';
  }
  .layout-header .layout-header_nav-pc .mega-dropdown_title > a:hover:before {
    opacity: 1;
    width: 80px;
  }
  .layout-header .layout-header_nav-pc .mega-dropdown_content {
    display: table-cell;
  }
  .layout-header .layout-header_nav-pc .mega-dropdown_products-list {
    overflow: hidden;
    margin-left: -20px;
  }
  .layout-header .layout-header_nav-pc .mega-dropdown_products-list > li {
    float: left;
    width: 25%;
    padding: 40px 0 0 20px;
    font-size: 0.933em;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .layout-header
    .layout-header_nav-pc
    .mega-dropdown_products-list
    > li:nth-child(-n + 4) {
    padding-top: 0;
  }
  .layout-header .layout-header_nav-pc .mega-dropdown_products-list > li > a {
    display: block;
    overflow: hidden;
    margin-bottom: 10px;
  }
  .layout-header
    .layout-header_nav-pc
    .mega-dropdown_products-list
    > li
    > a:hover
    img {
    -webkit-transform: scale(1.04);
    -ms-transform: scale(1.04);
    transform: scale(1.04);
  }
  .layout-header
    .layout-header_nav-pc
    .mega-dropdown_products-list
    > li
    > a
    img {
    display: block;
    -webkit-transition: -webkit-transform 0.8s cubic-bezier(0.23, 1, 0.32, 1);
    transition: -webkit-transform 0.8s cubic-bezier(0.23, 1, 0.32, 1);
    transition: transform 0.8s cubic-bezier(0.23, 1, 0.32, 1);
    transition: transform 0.8s cubic-bezier(0.23, 1, 0.32, 1),
      -webkit-transform 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  }
  .layout-header .layout-header_nav-pc .mega-dropdown_other-products {
    overflow: hidden;
    margin-top: 60px;
    border-left: 1px solid #cfd0d7;
  }
  .layout-header .layout-header_nav-pc .mega-dropdown_other-products > li {
    float: left;
    font-size: 0.933em;
    border-right: 1px solid #cfd0d7;
  }
  .layout-header .layout-header_nav-pc .mega-dropdown_other-products > li > a {
    display: block;
    padding: 9px 33px 10px;
    text-decoration: none;
  }
  .layout-header
    .layout-header_nav-pc
    .mega-dropdown_other-products
    > li
    > a:hover {
    opacity: 0.7;
  }
  .layout-header .layout-header_nav-pc .mega-dropdown_link {
    overflow: hidden;
    margin-left: -20px;
  }
  .layout-header .layout-header_nav-pc .mega-dropdown_link > li {
    float: left;
    width: 25%;
    padding-left: 20px;
    font-size: 0.933em;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .layout-header .layout-header_nav-pc .mega-dropdown_link > li > a {
    position: relative;
    display: block;
    padding: 30px 20px 30px 0;
    border-bottom: 1px solid #cfd0d7;
    text-decoration: none;
  }
  .layout-header .layout-header_nav-pc .mega-dropdown_link > li > a:before {
    opacity: 0;
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 1px;
    height: 1px;
    background: #222;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
    content: '';
  }
  .layout-header
    .layout-header_nav-pc
    .mega-dropdown_link
    > li
    > a:hover:before {
    opacity: 1;
    width: 100%;
  }
  .layout-header .layout-header_nav-pc .primary-nav_company {
    margin-left: 20px;
  }
  .layout-header .layout-header_nav-pc .primary-nav_company > a {
    background: url(/sb/common/img/layout/primary-nav_bg_company.svg) no-repeat
      center 15px;
    background-size: 18px auto;
  }
  .layout-header .layout-header_nav-pc .primary-nav_employment > a {
    background: url(/sb/common/img/layout/primary-nav_bg_employment.svg)
      no-repeat center 16px;
    background-size: 19px auto;
  }
  .layout-header .layout-header_nav-pc .secondary-nav {
    overflow: hidden;
    padding: 12px 30px;
    background: #000;
  }
  .layout-header .layout-header_nav-pc .secondary-nav > ul {
    float: right;
    display: table;
  }
  .layout-header .layout-header_nav-pc .secondary-nav > ul > li {
    float: left;
    padding: 0 14px;
    font-size: 1.2em;
    font-weight: bold;
    text-align: center;
    white-space: nowrap;
  }
  .layout-header .layout-header_nav-pc .secondary-nav > ul > li a {
    display: block;
    text-decoration: none;
  }
  .layout-header
    .layout-header_nav-pc
    .secondary-nav
    > ul
    > li
    a[target='_blank']:not(.m-not-icon):after {
    background-image: url(/sb/common/img/parts/icon-window_bg03.svg);
    background-size: 12px 10px;
    height: 10px;
    width: 12px;
  }
  .layout-header .layout-header_nav-pc .secondary-nav a {
    color: #aaa;
  }
  .layout-header .layout-header_nav-pc .secondary-nav_mypage a {
    position: relative;
    padding-left: 25px;
  }
  .layout-header .layout-header_nav-pc .secondary-nav_mypage a:before {
    position: absolute;
    opacity: 0.7;
    top: 50%;
    left: 0;
    width: 18px;
    height: 18px;
    margin-top: -9px;
    background: url(/sb/common/img/layout/secondary-nav_bg_mypage-white.svg)
      no-repeat left center;
    background-size: 18px auto;
    content: '';
  }
  .layout-header .layout-header_nav-pc .secondary-nav_search {
    zoom: 1;
  }
  .layout-header .layout-header_nav-pc .secondary-nav_search:before,
  .layout-header .layout-header_nav-pc .secondary-nav_search:after {
    content: '';
    display: table;
  }
  .layout-header .layout-header_nav-pc .secondary-nav_search:after {
    clear: both;
  }
  .layout-header
    .layout-header_nav-pc
    .secondary-nav_search
    .secondary-nav_search_accordion {
    float: left;
  }
  .layout-header
    .layout-header_nav-pc
    .secondary-nav_search
    .secondary-nav_search_input {
    float: left;
  }
  .layout-header
    .layout-header_nav-pc
    .secondary-nav_search
    .secondary-nav_search_input
    input[type='text'] {
    width: 0;
    height: 15px;
    margin: 0;
    padding: 0;
    border: none;
    background: none;
    color: #fff;
    line-height: 15px;
    font-size: 12px;
    vertical-align: top;
  }
  .layout-header
    .layout-header_nav-pc
    .secondary-nav_search
    .secondary-nav_search_input
    button {
    height: 15px;
    margin: 0;
    padding: 0;
    border: none;
    background: none;
    line-height: 1.3;
  }
  .layout-header
    .layout-header_nav-pc
    .secondary-nav_search
    .secondary-nav_search_input
    button
    img {
    opacity: 0.7;
    width: 15px;
    height: 15px;
    vertical-align: top;
  }
  .layout-header
    .layout-header_nav-pc
    .secondary-nav_search
    .secondary-nav_search_input
    button:hover
    img {
    opacity: 1;
  }
  .layout-header .layout-header_nav-sp {
    display: none;
  }
  .layout-header.theme-toppage,
  .layout-header.theme-category-index {
    position: absolute;
  }
  .layout-header.theme-toppage + .layout-content,
  .layout-header.theme-category-index + .layout-content {
    padding-top: 40px;
  }
  .layout-header.theme-toppage:not(:hover) .layout-header_logo {
    display: none;
  }
  .layout-header.theme-toppage:not(:hover),
  .layout-header.theme-category-index:not(:hover) {
    background: none;
  }
  .layout-header.theme-toppage:not(:hover) .layout-header_nav-pc .primary-nav a,
  .layout-header.theme-category-index:not(:hover)
    .layout-header_nav-pc
    .primary-nav
    a {
    color: #fff;
  }
  .layout-header.theme-toppage:not(:hover)
    .layout-header_nav-pc
    .primary-nav
    > ul
    .primary-nav_company,
  .layout-header.theme-category-index:not(:hover)
    .layout-header_nav-pc
    .primary-nav
    > ul
    .primary-nav_company,
  .layout-header.theme-toppage:not(:hover)
    .layout-header_nav-pc
    .primary-nav
    > ul
    .primary-nav_employment,
  .layout-header.theme-category-index:not(:hover)
    .layout-header_nav-pc
    .primary-nav
    > ul
    .primary-nav_employment {
    border: none;
    position: relative;
  }
  .layout-header.theme-toppage:not(:hover)
    .layout-header_nav-pc
    .primary-nav
    > ul
    .primary-nav_company:before,
  .layout-header.theme-category-index:not(:hover)
    .layout-header_nav-pc
    .primary-nav
    > ul
    .primary-nav_company:before,
  .layout-header.theme-toppage:not(:hover)
    .layout-header_nav-pc
    .primary-nav
    > ul
    .primary-nav_employment:before,
  .layout-header.theme-category-index:not(:hover)
    .layout-header_nav-pc
    .primary-nav
    > ul
    .primary-nav_employment:before {
    display: block;
    position: absolute;
    top: 15px;
    width: 1px;
    height: -webkit-calc(100% - 30px);
    height: calc(100% - 30px);
    margin-top: 0;
    background: #fff;
    opacity: 0.3;
    content: '';
  }
  .layout-header.theme-toppage:not(:hover)
    .layout-header_nav-pc
    .primary-nav
    .mega-dropdown
    a,
  .layout-header.theme-category-index:not(:hover)
    .layout-header_nav-pc
    .primary-nav
    .mega-dropdown
    a {
    color: #222;
  }
  .layout-header.theme-toppage:not(:hover)
    .layout-header_nav-pc
    .primary-nav
    .primary-nav_dropdown
    > a:before,
  .layout-header.theme-category-index:not(:hover)
    .layout-header_nav-pc
    .primary-nav
    .primary-nav_dropdown
    > a:before {
    background-image: url(/sb/common/img/parts/icon-down-arrow_bg_white.svg);
  }
  .layout-header.theme-toppage:not(:hover) .primary-nav_company > a,
  .layout-header.theme-category-index:not(:hover) .primary-nav_company > a {
    background: url(/sb/common/img/layout/primary-nav_bg_company-white.svg)
      no-repeat center 15px;
    background-size: 18px auto;
  }
  .layout-header.theme-toppage:not(:hover) .primary-nav_employment > a,
  .layout-header.theme-category-index:not(:hover) .primary-nav_employment > a {
    background: url(/sb/common/img/layout/primary-nav_bg_employment-white.svg)
      no-repeat center 16px;
    background-size: 19px auto;
  }
  .layout-header.is-fixed {
    position: fixed;
    top: 0;
    background: #fff;
  }
  .layout-header.is-fixed .layout-header_logo {
    display: block;
    top: 12px;
  }
  .layout-header.is-fixed .layout-header_nav-pc .primary-nav a {
    color: #222;
  }
  .layout-header.is-fixed
    .layout-header_nav-pc
    .primary-nav
    > ul
    .primary-nav_company,
  .layout-header.is-fixed
    .layout-header_nav-pc
    .primary-nav
    > ul
    .primary-nav_employment {
    border-left: 1px solid #d9d9d9;
  }
  .layout-header.is-fixed .secondary-nav {
    display: none;
  }
  .layout-header.is-fixed .primary-nav_company > a {
    background: url(/sb/common/img/layout/primary-nav_bg_company.svg) no-repeat
      center 15px;
    background-size: 18px auto;
  }
  .layout-header.is-fixed .primary-nav_employment > a {
    background: url(/sb/common/img/layout/primary-nav_bg_employment.svg)
      no-repeat center 16px;
    background-size: 19px auto;
  }
  .layout-header.is-mouseover {
    position: absolute;
  }
}
@media (min-width: 769px) and (min-width: 769px) {
  .layout-header .layout-header_nav-pc .secondary-nav a:hover {
    color: #fff;
  }
  .layout-header
    .layout-header_nav-pc
    .secondary-nav
    a:hover[target='_blank']:not(.m-not-icon):after {
    background-image: url(/sb/common/img/parts/icon-window_bg03_on.svg);
  }
}
@media (min-width: 769px) and (min-width: 769px) {
  .layout-header .layout-header_nav-pc .secondary-nav_mypage a:hover:before {
    opacity: 1;
  }
}
@media screen and (min-width: 768px) and (max-width: 1200px) {
  .layout-header .layout-header_nav-pc .primary-nav > ul > li {
    padding-right: 25px;
  }
}
/* Header navigation (SP)
   ========================================================================== */
@media (max-width: 768px) {
  .layout-header .layout-header_logo {
    position: absolute;
    top: 6px;
    right: 0;
    left: 0;
    width: 33px;
    margin: 0 auto;
    padding: 0;
  }
  .layout-header .layout-header_nav-sp {
    height: 55px;
    background: #fff;
  }
  .layout-header .layout-header_nav-sp .primary-nav {
    zoom: 1;
  }
  .layout-header .layout-header_nav-sp .primary-nav:before,
  .layout-header .layout-header_nav-sp .primary-nav:after {
    content: '';
    display: table;
  }
  .layout-header .layout-header_nav-sp .primary-nav:after {
    clear: both;
  }
  .layout-header .layout-header_nav-sp .primary-nav > ul > li {
    font-size: 1.5em;
  }
  .layout-header .layout-header_nav-sp .primary-nav > ul > li > a,
  .layout-header .layout-header_nav-sp .primary-nav > ul > li > span {
    display: block;
    position: relative;
    padding: 18px 0;
    border-bottom: 1px solid #e4e5ec;
    color: #222;
  }
  .layout-header .layout-header_nav-sp .primary-nav > ul > li > a:before {
    position: absolute;
    top: 18px;
    right: 40px;
    width: 1px;
    background: #e4e5ec;
    height: -webkit-calc(100% - 36px);
    height: calc(100% - 36px);
    content: '';
  }
  .layout-header .layout-header_nav-sp .primary-nav > ul > li > a:after {
    position: absolute;
    top: 50%;
    right: 13px;
    width: 6px;
    height: 6px;
    margin-top: -5px;
    border-top: 2px solid #bcbcbc;
    border-right: 2px solid #bcbcbc;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    content: '';
  }
  .layout-header .layout-header_nav-sp .primary-nav .primary-nav_company,
  .layout-header .layout-header_nav-sp .primary-nav .primary-nav_employment {
    float: left;
    width: 50%;
    padding: 30px 0;
    font-size: 1.3em;
    text-align: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .layout-header .layout-header_nav-sp .primary-nav .primary-nav_company > a,
  .layout-header
    .layout-header_nav-sp
    .primary-nav
    .primary-nav_employment
    > a {
    display: block;
    padding: 45px 10px 10px;
    border-bottom: none;
  }
  .layout-header
    .layout-header_nav-sp
    .primary-nav
    .primary-nav_company
    > a:before,
  .layout-header
    .layout-header_nav-sp
    .primary-nav
    .primary-nav_employment
    > a:before,
  .layout-header
    .layout-header_nav-sp
    .primary-nav
    .primary-nav_company
    > a:after,
  .layout-header
    .layout-header_nav-sp
    .primary-nav
    .primary-nav_employment
    > a:after {
    content: none;
  }
  .layout-header .layout-header_nav-sp .primary-nav .primary-nav_company > a {
    border-right: 1px solid #e4e5ec;
    background: url(/sb/common/img/layout/primary-nav_bg_company.svg) no-repeat
      center 11px;
    background-size: 18px auto;
  }
  .layout-header
    .layout-header_nav-sp
    .primary-nav
    .primary-nav_employment
    > a {
    background: url(/sb/common/img/layout/primary-nav_bg_employment.svg)
      no-repeat center 12px;
    background-size: 19px auto;
  }
  .layout-header .layout-header_nav-sp .secondary-nav {
    padding-top: 25px;
    border-top: 1px solid #e4e5ec;
  }
  .layout-header .layout-header_nav-sp .secondary-nav > ul {
    overflow: hidden;
  }
  .layout-header .layout-header_nav-sp .secondary-nav > ul > li {
    float: left;
    margin-right: 18px;
    font-size: 1.2em;
  }
  .layout-header .layout-header_nav-sp .secondary-nav > ul > li > a {
    display: block;
    padding: 10px 0;
    color: #222;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .layout-header
    .layout-header_nav-sp
    .secondary-nav
    > ul
    > li
    > a[target='_blank']:not(.m-not-icon):after {
    background-image: url(/sb/common/img/parts/icon-window_bg03_black.svg);
    background-size: 11px 9px;
    height: 9px;
    width: 11px;
  }
  .layout-header
    .layout-header_nav-sp
    .secondary-nav
    > ul
    > li.secondary-nav_mypage,
  .layout-header
    .layout-header_nav-sp
    .secondary-nav
    > ul
    > li.secondary-nav_english {
    clear: both;
    width: 100%;
    margin: 10px 0 0;
    font-size: 1.3em;
  }
  .layout-header
    .layout-header_nav-sp
    .secondary-nav
    > ul
    > li.secondary-nav_mypage
    > a,
  .layout-header
    .layout-header_nav-sp
    .secondary-nav
    > ul
    > li.secondary-nav_english
    > a {
    padding: 15px 21px;
    border: 1px solid #e4e5ec;
  }
  .layout-header
    .layout-header_nav-sp
    .secondary-nav
    > ul
    > li.secondary-nav_mypage
    > a {
    position: relative;
  }
  .layout-header .drawer-handle {
    display: block;
    position: absolute;
    top: 19px;
    right: 20px;
    cursor: pointer;
    z-index: 4;
    background: #fff;
  }
  .layout-header .drawer-handle > a {
    display: block;
    width: 25px;
    height: 16px;
  }
  .layout-header .drawer-handle > a > span {
    white-space: nowrap;
    text-indent: 100%;
    overflow: hidden;
    display: block;
    position: absolute;
    top: 7px;
    left: 0;
    width: 100%;
    height: 2px;
    background: #000;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }
  .layout-header .drawer-handle > a:before,
  .layout-header .drawer-handle > a:after {
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background: #000;
    content: '';
    -webkit-transition: all 0.45s cubic-bezier(0.19, 1, 0.22, 1);
    transition: all 0.45s cubic-bezier(0.19, 1, 0.22, 1);
  }
  .layout-header .drawer-handle > a:before {
    top: 0;
  }
  .layout-header .drawer-handle > a:after {
    bottom: 0;
  }
  .layout-header .drawer-handle.is-active > a > span {
    opacity: 0;
  }
  .layout-header .drawer-handle.is-active > a:before {
    -webkit-transform: translateY(7px) rotate(-45deg);
    -ms-transform: translateY(7px) rotate(-45deg);
    transform: translateY(7px) rotate(-45deg);
  }
  .layout-header .drawer-handle.is-active > a:after {
    -webkit-transform: translateY(-7px) rotate(45deg);
    -ms-transform: translateY(-7px) rotate(45deg);
    transform: translateY(-7px) rotate(45deg);
  }
  .layout-header .drawer-menu {
    visibility: hidden;
    overflow: auto;
    width: 100%;
    max-height: 100vh;
    min-height: 100vh;
    padding: 55px 20px 100px;
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    background: #fff;
    opacity: 0;
    color: #000;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transform: translateY(-150px);
    -ms-transform: translateY(-150px);
    transform: translateY(-150px);
    -webkit-transition: all 0.45s cubic-bezier(0.19, 1, 0.22, 1);
    transition: all 0.45s cubic-bezier(0.19, 1, 0.22, 1);
  }
  .layout-header .drawer-menu.is-active {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  .layout-header .accordion-menu > span > a {
    white-space: nowrap;
    text-indent: 100%;
    overflow: hidden;
    display: block;
    position: absolute;
    top: 50%;
    right: 6px;
    width: 35px;
    height: 20px;
    margin-top: -10px;
    border-left: 1px solid #e4e5ec;
  }
  .layout-header .accordion-menu > span > a:before,
  .layout-header .accordion-menu > span > a:after {
    position: absolute;
    right: 4px;
    width: 12px;
    height: 2px;
    background: #bcbcbc;
    border-radius: 4px;
    content: '';
  }
  .layout-header .accordion-menu > span > a:before {
    top: 8px;
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  .layout-header .accordion-menu > span > a:after {
    top: 8px;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .layout-header .accordion-menu.is-expanded > span > a:before {
    display: none;
  }
  .layout-header .accordion-menu.is-expanded > span > a:after {
    background: #222;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .layout-header .accordion-menu_content {
    display: none;
  }
  .layout-header .accordion-menu_content > ul {
    margin: 0 15px;
    padding: 15px 0;
    border-top: 1px solid #e4e5ec;
  }
  .layout-header .accordion-menu_content > ul:first-child {
    border-top: none;
  }
  .layout-header .accordion-menu_content > ul > li {
    font-size: 1.4rem;
  }
  .layout-header .accordion-menu_content > ul > li > a {
    display: block;
    position: relative;
    padding: 10px 0 10px 18px;
    color: #222;
  }
  .layout-header .accordion-menu_content > ul > li > a:before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 4.7px;
    height: 4.7px;
    margin-top: -4px;
    border-top: 1px solid #222;
    border-right: 1px solid #222;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    content: '';
  }
  .layout-header .layout-header_nav-pc {
    display: none;
  }
  .layout-header .search-area {
    position: relative;
    width: 100%;
  }
  .layout-header .search-area .search-area_handle {
    position: absolute;
    top: 17px;
    left: 23px;
  }
  .layout-header .search-area .search-area_handle img {
    width: 20px;
    height: 20px;
  }
  .layout-header .search-area .search-area_handle > a {
    white-space: nowrap;
    text-indent: 100%;
    overflow: hidden;
    display: block;
    width: 20px;
    height: 20px;
    background: url(/sb/common/img/layout/search-area_img01.svg) no-repeat 0 0;
    background-size: 20px auto;
  }
  .layout-header .search-area .search-area_handle > a:before,
  .layout-header .search-area .search-area_handle > a:after {
    opacity: 0;
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background: #000;
    content: '';
    -webkit-transition: all 0.45s cubic-bezier(0.19, 1, 0.22, 1);
    transition: all 0.45s cubic-bezier(0.19, 1, 0.22, 1);
  }
  .layout-header .search-area .search-area_handle > a:before {
    top: 0;
  }
  .layout-header .search-area .search-area_handle > a:after {
    bottom: 0;
  }
  .layout-header .search-area.is-active {
    z-index: 5;
  }
  .layout-header .search-area.is-active .search-area_handle {
    z-index: 4;
  }
  .layout-header .search-area.is-active .search-area_handle > a {
    background: none;
  }
  .layout-header .search-area.is-active .search-area_handle > a:before,
  .layout-header .search-area.is-active .search-area_handle > a:after {
    opacity: 1;
  }
  .layout-header .search-area.is-active .search-area_handle > a:before {
    -webkit-transform: translateY(9px) rotate(-45deg);
    -ms-transform: translateY(9px) rotate(-45deg);
    transform: translateY(9px) rotate(-45deg);
  }
  .layout-header .search-area.is-active .search-area_handle > a:after {
    -webkit-transform: translateY(-9px) rotate(45deg);
    -ms-transform: translateY(-9px) rotate(45deg);
    transform: translateY(-9px) rotate(45deg);
  }
  .layout-header .search-area .search-area_content {
    visibility: hidden;
    position: absolute;
    z-index: 3;
    width: 100%;
    padding: 55px 15px 15px;
    background: #fff;
    opacity: 0;
    -webkit-transform: translateY(-140px);
    -ms-transform: translateY(-140px);
    transform: translateY(-140px);
    -webkit-transition: all 0.45s cubic-bezier(0.19, 1, 0.22, 1);
    transition: all 0.45s cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .layout-header .search-area .search-area_content.is-active {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  .layout-header .search-area .search-area_content form {
    position: relative;
  }
  .layout-header .search-area .search-area_content input {
    width: 100%;
    padding: 10px 40px 10px 15px;
    background: #ebedf2;
    border: none;
    font-size: 16px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .layout-header .search-area .search-area_content button {
    position: absolute;
    top: 50%;
    right: 10px;
    margin-top: -10px;
    border: none;
    background: none;
  }
  .layout-header .search-area .search-area_content button > img {
    width: 20px;
  }
}
/* Overlay block
   ========================================================================== */
.overlay {
  display: none;
  opacity: 0.7;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #000;
  z-index: 2;
}
@media (min-width: 769px) {
  .overlay {
    top: 130px;
  }
}
.overlay.is-active {
  display: block;
}
/* Set keyframe
   ========================================================================== */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* Migrate only display
   ========================================================================== */
.header-only-pc .layout-header {
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.header-only-pc .layout-header .layout-header_logo {
  position: absolute;
  top: 50px;
  right: inherit;
  left: 50px;
  width: 40px;
}
.header-only-pc .layout-header .layout-header_nav-pc {
  clear: both;
}
.header-only-pc .layout-header .layout-header_nav-pc .primary-nav {
  zoom: 1;
}
.header-only-pc .layout-header .layout-header_nav-pc .primary-nav:before,
.header-only-pc .layout-header .layout-header_nav-pc .primary-nav:after {
  content: '';
  display: table;
}
.header-only-pc .layout-header .layout-header_nav-pc .primary-nav:after {
  clear: both;
}
.header-only-pc .layout-header .layout-header_nav-pc .primary-nav > ul {
  float: right;
  display: table;
  margin: 0;
  padding: 0;
}
.header-only-pc .layout-header .layout-header_nav-pc .primary-nav > ul > li {
  display: table-cell;
  max-height: 90px;
  padding: 0 40px 0 0;
  font-size: 1.5em;
  font-weight: bold;
  vertical-align: middle;
  white-space: nowrap;
}
.header-only-pc
  .layout-header
  .layout-header_nav-pc
  .primary-nav
  > ul
  > li
  > a {
  display: inline-block;
  position: relative;
  max-height: 74px;
  padding: 28px 0 27px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
}
.header-only-pc
  .layout-header
  .layout-header_nav-pc
  .primary-nav
  > ul
  > li
  > a:after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background: #f3be00;
  -webkit-transition: -webkit-transform 0.4s 0.15s
    cubic-bezier(0.19, 1, 0.22, 1);
  transition: -webkit-transform 0.4s 0.15s cubic-bezier(0.19, 1, 0.22, 1);
  transition: transform 0.4s 0.15s cubic-bezier(0.19, 1, 0.22, 1);
  transition: transform 0.4s 0.15s cubic-bezier(0.19, 1, 0.22, 1),
    -webkit-transform 0.4s 0.15s cubic-bezier(0.19, 1, 0.22, 1);
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: right;
  -ms-transform-origin: right;
  transform-origin: right;
  content: '';
}
.header-only-pc
  .layout-header
  .layout-header_nav-pc
  .primary-nav
  > ul
  > li
  > a:hover:after {
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: left;
  -ms-transform-origin: left;
  transform-origin: left;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}
.header-only-pc
  .layout-header
  .layout-header_nav-pc
  .primary-nav
  > ul
  .primary-nav_dropdown
  > a {
  padding-right: 18px;
}
.header-only-pc
  .layout-header
  .layout-header_nav-pc
  .primary-nav
  > ul
  .primary-nav_dropdown
  > a:after {
  content: none;
}
.header-only-pc
  .layout-header
  .layout-header_nav-pc
  .primary-nav
  > ul
  .primary-nav_dropdown
  > a:before {
  background: url(/sb/common/img/parts/icon-down-arrow_bg.svg) no-repeat 0 0;
  background-size: 10px 6px;
  content: '';
  height: 6px;
  margin-top: -3px;
  width: 10px;
  position: absolute;
  right: 0;
  top: 50%;
}
.header-only-pc
  .layout-header
  .layout-header_nav-pc
  .primary-nav
  > ul
  .primary-nav_company,
.header-only-pc
  .layout-header
  .layout-header_nav-pc
  .primary-nav
  > ul
  .primary-nav_employment {
  position: relative;
  padding: 0;
  font-size: 1.2em;
}
.header-only-pc
  .layout-header
  .layout-header_nav-pc
  .primary-nav
  > ul
  .primary-nav_company:before,
.header-only-pc
  .layout-header
  .layout-header_nav-pc
  .primary-nav
  > ul
  .primary-nav_employment:before {
  display: block;
  position: absolute;
  top: 15px;
  width: 1px;
  height: -webkit-calc(100% - 30px);
  height: calc(100% - 30px);
  margin-top: 0;
  background: #d9d9d9;
  content: '';
}
.header-only-pc
  .layout-header
  .layout-header_nav-pc
  .primary-nav
  > ul
  .primary-nav_company
  a,
.header-only-pc
  .layout-header
  .layout-header_nav-pc
  .primary-nav
  > ul
  .primary-nav_employment
  a {
  padding: 42px 20px 18px;
}
.header-only-pc
  .layout-header
  .layout-header_nav-pc
  .primary-nav
  > ul
  .primary-nav_company
  a:hover,
.header-only-pc
  .layout-header
  .layout-header_nav-pc
  .primary-nav
  > ul
  .primary-nav_employment
  a:hover {
  opacity: 0.7;
}
.header-only-pc
  .layout-header
  .layout-header_nav-pc
  .primary-nav
  > ul
  .primary-nav_company
  a:hover:after,
.header-only-pc
  .layout-header
  .layout-header_nav-pc
  .primary-nav
  > ul
  .primary-nav_employment
  a:hover:after {
  content: none;
}
.header-only-pc .layout-header .layout-header_nav-pc .primary-nav a {
  color: #222;
}
.header-only-pc .layout-header .layout-header_nav-pc .mega-dropdown {
  display: none;
  position: absolute;
  left: 0;
  width: 100%;
  background: #f1f2f6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.header-only-pc .layout-header .layout-header_nav-pc .mega-dropdown a {
  color: #222;
}
.header-only-pc .layout-header .layout-header_nav-pc .js-dropdown-menu_content {
  opacity: 0;
  margin-top: -30px;
  padding-bottom: 30px;
}
.header-only-pc .layout-header .layout-header_nav-pc .mega-dropdown_inner {
  display: table;
  max-width: 1200px;
  margin: 0 auto;
  padding: 50px 0;
}
.header-only-pc
  .layout-header
  .layout-header_nav-pc
  .is-active:hover
  .mega-dropdown_inner {
  -webkit-animation: fadeIn 1s ease 0s 1 normal;
  animation: fadeIn 1s ease 0s 1 normal;
}
.header-only-pc .layout-header .layout-header_nav-pc .mega-dropdown_title {
  display: table-cell;
  width: 275px;
  font-size: 1.66em;
  vertical-align: top;
}
.header-only-pc .layout-header .layout-header_nav-pc .mega-dropdown_title > a {
  display: inline-block;
  position: relative;
  padding: 30px 40px 30px 0;
  text-decoration: none;
  background: url(/sb/common/img/layout/mega-dropdown_bg_arrow.svg) no-repeat
    right center;
  background-size: 15px auto;
}
.header-only-pc
  .layout-header
  .layout-header_nav-pc
  .mega-dropdown_title
  > a:before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 30px;
  height: 1px;
  background: #222;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  content: '';
}
.header-only-pc
  .layout-header
  .layout-header_nav-pc
  .mega-dropdown_title
  > a:hover:before {
  opacity: 1;
  width: 80px;
}
.header-only-pc .layout-header .layout-header_nav-pc .mega-dropdown_content {
  display: table-cell;
}
.header-only-pc
  .layout-header
  .layout-header_nav-pc
  .mega-dropdown_products-list {
  overflow: hidden;
  margin-left: -20px;
}
.header-only-pc
  .layout-header
  .layout-header_nav-pc
  .mega-dropdown_products-list
  > li {
  float: left;
  width: 25%;
  padding: 40px 0 0 20px;
  font-size: 0.933em;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.header-only-pc
  .layout-header
  .layout-header_nav-pc
  .mega-dropdown_products-list
  > li:nth-child(-n + 4) {
  padding-top: 0;
}
.header-only-pc
  .layout-header
  .layout-header_nav-pc
  .mega-dropdown_products-list
  > li
  > a {
  display: block;
  overflow: hidden;
  margin-bottom: 10px;
}
.header-only-pc
  .layout-header
  .layout-header_nav-pc
  .mega-dropdown_products-list
  > li
  > a:hover
  img {
  -webkit-transform: scale(1.04);
  -ms-transform: scale(1.04);
  transform: scale(1.04);
}
.header-only-pc
  .layout-header
  .layout-header_nav-pc
  .mega-dropdown_products-list
  > li
  > a
  img {
  display: block;
  -webkit-transition: -webkit-transform 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  transition: -webkit-transform 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 0.8s cubic-bezier(0.23, 1, 0.32, 1),
    -webkit-transform 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}
.header-only-pc
  .layout-header
  .layout-header_nav-pc
  .mega-dropdown_other-products {
  overflow: hidden;
  margin-top: 60px;
  border-left: 1px solid #cfd0d7;
}
.header-only-pc
  .layout-header
  .layout-header_nav-pc
  .mega-dropdown_other-products
  > li {
  float: left;
  font-size: 0.933em;
  border-right: 1px solid #cfd0d7;
}
.header-only-pc
  .layout-header
  .layout-header_nav-pc
  .mega-dropdown_other-products
  > li
  > a {
  display: block;
  padding: 9px 33px 10px;
  text-decoration: none;
}
.header-only-pc
  .layout-header
  .layout-header_nav-pc
  .mega-dropdown_other-products
  > li
  > a:hover {
  opacity: 0.7;
}
.header-only-pc .layout-header .layout-header_nav-pc .mega-dropdown_link {
  overflow: hidden;
  margin-left: -20px;
}
.header-only-pc .layout-header .layout-header_nav-pc .mega-dropdown_link > li {
  float: left;
  width: 25%;
  padding-left: 20px;
  font-size: 0.933em;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.header-only-pc
  .layout-header
  .layout-header_nav-pc
  .mega-dropdown_link
  > li
  > a {
  position: relative;
  display: block;
  padding: 30px 20px 30px 0;
  border-bottom: 1px solid #cfd0d7;
  text-decoration: none;
}
.header-only-pc
  .layout-header
  .layout-header_nav-pc
  .mega-dropdown_link
  > li
  > a:before {
  opacity: 0;
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 1px;
  height: 1px;
  background: #222;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  content: '';
}
.header-only-pc
  .layout-header
  .layout-header_nav-pc
  .mega-dropdown_link
  > li
  > a:hover:before {
  opacity: 1;
  width: 100%;
}
.header-only-pc .layout-header .layout-header_nav-pc .primary-nav_company {
  margin-left: 20px;
}
.header-only-pc .layout-header .layout-header_nav-pc .primary-nav_company > a {
  background: url(/sb/common/img/layout/primary-nav_bg_company.svg) no-repeat
    center 15px;
  background-size: 18px auto;
}
.header-only-pc
  .layout-header
  .layout-header_nav-pc
  .primary-nav_employment
  > a {
  background: url(/sb/common/img/layout/primary-nav_bg_employment.svg) no-repeat
    center 16px;
  background-size: 19px auto;
}
.header-only-pc .layout-header .layout-header_nav-pc .secondary-nav {
  overflow: hidden;
  padding: 12px 30px;
  background: #000;
}
.header-only-pc .layout-header .layout-header_nav-pc .secondary-nav > ul {
  float: right;
  display: table;
}
.header-only-pc .layout-header .layout-header_nav-pc .secondary-nav > ul > li {
  float: left;
  padding: 0 14px;
  font-size: 1.2em;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
}
.header-only-pc
  .layout-header
  .layout-header_nav-pc
  .secondary-nav
  > ul
  > li
  a {
  display: block;
  text-decoration: none;
}
.header-only-pc
  .layout-header
  .layout-header_nav-pc
  .secondary-nav
  > ul
  > li
  a[target='_blank']:not(.m-not-icon):after {
  background-image: url(/sb/common/img/parts/icon-window_bg03.svg);
  background-size: 12px 10px;
  height: 10px;
  width: 12px;
}
.header-only-pc .layout-header .layout-header_nav-pc .secondary-nav a {
  color: #aaa;
}
@media (min-width: 769px) {
  .header-only-pc .layout-header .layout-header_nav-pc .secondary-nav a:hover {
    color: #fff;
  }
  .header-only-pc
    .layout-header
    .layout-header_nav-pc
    .secondary-nav
    a:hover[target='_blank']:not(.m-not-icon):after {
    background-image: url(/sb/common/img/parts/icon-window_bg03_on.svg);
  }
}
.header-only-pc .layout-header .layout-header_nav-pc .secondary-nav_mypage a {
  position: relative;
  padding-left: 25px;
}
.header-only-pc
  .layout-header
  .layout-header_nav-pc
  .secondary-nav_mypage
  a:before {
  position: absolute;
  opacity: 0.7;
  top: 50%;
  left: 0;
  width: 18px;
  height: 18px;
  margin-top: -9px;
  background: url(/sb/common/img/layout/secondary-nav_bg_mypage-white.svg)
    no-repeat left center;
  background-size: 18px auto;
  content: '';
}
@media (min-width: 769px) {
  .header-only-pc
    .layout-header
    .layout-header_nav-pc
    .secondary-nav_mypage
    a:hover:before {
    opacity: 1;
  }
}
.header-only-pc .layout-header .layout-header_nav-pc .secondary-nav_search {
  zoom: 1;
}
.header-only-pc
  .layout-header
  .layout-header_nav-pc
  .secondary-nav_search:before,
.header-only-pc
  .layout-header
  .layout-header_nav-pc
  .secondary-nav_search:after {
  content: '';
  display: table;
}
.header-only-pc
  .layout-header
  .layout-header_nav-pc
  .secondary-nav_search:after {
  clear: both;
}
.header-only-pc
  .layout-header
  .layout-header_nav-pc
  .secondary-nav_search
  .secondary-nav_search_accordion {
  float: left;
}
.header-only-pc
  .layout-header
  .layout-header_nav-pc
  .secondary-nav_search
  .secondary-nav_search_input {
  float: left;
}
.header-only-pc
  .layout-header
  .layout-header_nav-pc
  .secondary-nav_search
  .secondary-nav_search_input
  input[type='text'] {
  width: 0;
  height: 15px;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  color: #fff;
  line-height: 15px;
  font-size: 12px;
  vertical-align: top;
}
.header-only-pc
  .layout-header
  .layout-header_nav-pc
  .secondary-nav_search
  .secondary-nav_search_input
  button {
  height: 15px;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  line-height: 1.3;
}
.header-only-pc
  .layout-header
  .layout-header_nav-pc
  .secondary-nav_search
  .secondary-nav_search_input
  button
  img {
  opacity: 0.7;
  width: 15px;
  height: 15px;
  vertical-align: top;
}
.header-only-pc
  .layout-header
  .layout-header_nav-pc
  .secondary-nav_search
  .secondary-nav_search_input
  button:hover
  img {
  opacity: 1;
}
.header-only-pc .layout-header .layout-header_nav-sp {
  display: none;
}
.header-only-pc .layout-header.theme-toppage,
.header-only-pc .layout-header.theme-category-index {
  position: absolute;
}
.header-only-pc .layout-header.theme-toppage + .layout-content,
.header-only-pc .layout-header.theme-category-index + .layout-content {
  padding-top: 40px;
}
.header-only-pc .layout-header.theme-toppage:not(:hover) .layout-header_logo {
  display: none;
}
.header-only-pc .layout-header.theme-toppage:not(:hover),
.header-only-pc .layout-header.theme-category-index:not(:hover) {
  background: none;
}
.header-only-pc
  .layout-header.theme-toppage:not(:hover)
  .layout-header_nav-pc
  .primary-nav
  a,
.header-only-pc
  .layout-header.theme-category-index:not(:hover)
  .layout-header_nav-pc
  .primary-nav
  a {
  color: #fff;
}
.header-only-pc
  .layout-header.theme-toppage:not(:hover)
  .layout-header_nav-pc
  .primary-nav
  > ul
  .primary-nav_company,
.header-only-pc
  .layout-header.theme-category-index:not(:hover)
  .layout-header_nav-pc
  .primary-nav
  > ul
  .primary-nav_company,
.header-only-pc
  .layout-header.theme-toppage:not(:hover)
  .layout-header_nav-pc
  .primary-nav
  > ul
  .primary-nav_employment,
.header-only-pc
  .layout-header.theme-category-index:not(:hover)
  .layout-header_nav-pc
  .primary-nav
  > ul
  .primary-nav_employment {
  border: none;
  position: relative;
}
.header-only-pc
  .layout-header.theme-toppage:not(:hover)
  .layout-header_nav-pc
  .primary-nav
  > ul
  .primary-nav_company:before,
.header-only-pc
  .layout-header.theme-category-index:not(:hover)
  .layout-header_nav-pc
  .primary-nav
  > ul
  .primary-nav_company:before,
.header-only-pc
  .layout-header.theme-toppage:not(:hover)
  .layout-header_nav-pc
  .primary-nav
  > ul
  .primary-nav_employment:before,
.header-only-pc
  .layout-header.theme-category-index:not(:hover)
  .layout-header_nav-pc
  .primary-nav
  > ul
  .primary-nav_employment:before {
  display: block;
  position: absolute;
  top: 15px;
  width: 1px;
  height: -webkit-calc(100% - 30px);
  height: calc(100% - 30px);
  margin-top: 0;
  background: #fff;
  opacity: 0.3;
  content: '';
}
.header-only-pc
  .layout-header.theme-toppage:not(:hover)
  .layout-header_nav-pc
  .primary-nav
  .mega-dropdown
  a,
.header-only-pc
  .layout-header.theme-category-index:not(:hover)
  .layout-header_nav-pc
  .primary-nav
  .mega-dropdown
  a {
  color: #222;
}
.header-only-pc
  .layout-header.theme-toppage:not(:hover)
  .layout-header_nav-pc
  .primary-nav
  .primary-nav_dropdown
  > a:before,
.header-only-pc
  .layout-header.theme-category-index:not(:hover)
  .layout-header_nav-pc
  .primary-nav
  .primary-nav_dropdown
  > a:before {
  background-image: url(/sb/common/img/parts/icon-down-arrow_bg_white.svg);
}
.header-only-pc
  .layout-header.theme-toppage:not(:hover)
  .primary-nav_company
  > a,
.header-only-pc
  .layout-header.theme-category-index:not(:hover)
  .primary-nav_company
  > a {
  background: url(/sb/common/img/layout/primary-nav_bg_company-white.svg)
    no-repeat center 15px;
  background-size: 18px auto;
}
.header-only-pc
  .layout-header.theme-toppage:not(:hover)
  .primary-nav_employment
  > a,
.header-only-pc
  .layout-header.theme-category-index:not(:hover)
  .primary-nav_employment
  > a {
  background: url(/sb/common/img/layout/primary-nav_bg_employment-white.svg)
    no-repeat center 16px;
  background-size: 19px auto;
}
.header-only-pc .layout-header.is-fixed {
  position: fixed;
  top: 0;
  background: #fff;
}
.header-only-pc .layout-header.is-fixed .layout-header_logo {
  display: block;
  top: 12px;
}
.header-only-pc .layout-header.is-fixed .layout-header_nav-pc .primary-nav a {
  color: #222;
}
.header-only-pc
  .layout-header.is-fixed
  .layout-header_nav-pc
  .primary-nav
  > ul
  .primary-nav_company,
.header-only-pc
  .layout-header.is-fixed
  .layout-header_nav-pc
  .primary-nav
  > ul
  .primary-nav_employment {
  border-left: 1px solid #d9d9d9;
}
.header-only-pc .layout-header.is-fixed .secondary-nav {
  display: none;
}
.header-only-pc .layout-header.is-fixed .primary-nav_company > a {
  background: url(/sb/common/img/layout/primary-nav_bg_company.svg) no-repeat
    center 15px;
  background-size: 18px auto;
}
.header-only-pc .layout-header.is-fixed .primary-nav_employment > a {
  background: url(/sb/common/img/layout/primary-nav_bg_employment.svg) no-repeat
    center 16px;
  background-size: 19px auto;
}
.header-only-pc .layout-header.is-mouseover {
  position: absolute;
}
.header-only-pc .layout-header .layout-header_nav-pc .primary-nav > ul > li {
  padding-right: 25px;
}
.header-only-sp .layout-header .layout-header_logo {
  position: absolute;
  top: 6px;
  right: 0;
  left: 0;
  width: 33px;
  margin: 0 auto;
  padding: 0;
}
.header-only-sp .layout-header .layout-header_nav-sp {
  height: 55px;
  background: #fff;
}
.header-only-sp .layout-header .layout-header_nav-sp .primary-nav {
  zoom: 1;
}
.header-only-sp .layout-header .layout-header_nav-sp .primary-nav:before,
.header-only-sp .layout-header .layout-header_nav-sp .primary-nav:after {
  content: '';
  display: table;
}
.header-only-sp .layout-header .layout-header_nav-sp .primary-nav:after {
  clear: both;
}
.header-only-sp .layout-header .layout-header_nav-sp .primary-nav > ul > li {
  font-size: 1.5em;
}
.header-only-sp .layout-header .layout-header_nav-sp .primary-nav > ul > li > a,
.header-only-sp
  .layout-header
  .layout-header_nav-sp
  .primary-nav
  > ul
  > li
  > span {
  display: block;
  position: relative;
  padding: 18px 0;
  border-bottom: 1px solid #e4e5ec;
  color: #222;
}
.header-only-sp
  .layout-header
  .layout-header_nav-sp
  .primary-nav
  > ul
  > li
  > a:before {
  position: absolute;
  top: 18px;
  right: 40px;
  width: 1px;
  background: #e4e5ec;
  height: -webkit-calc(100% - 36px);
  height: calc(100% - 36px);
  content: '';
}
.header-only-sp
  .layout-header
  .layout-header_nav-sp
  .primary-nav
  > ul
  > li
  > a:after {
  position: absolute;
  top: 50%;
  right: 13px;
  width: 6px;
  height: 6px;
  margin-top: -5px;
  border-top: 2px solid #bcbcbc;
  border-right: 2px solid #bcbcbc;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  content: '';
}
.header-only-sp
  .layout-header
  .layout-header_nav-sp
  .primary-nav
  .primary-nav_company,
.header-only-sp
  .layout-header
  .layout-header_nav-sp
  .primary-nav
  .primary-nav_employment {
  float: left;
  width: 50%;
  padding: 30px 0;
  font-size: 1.3em;
  text-align: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.header-only-sp
  .layout-header
  .layout-header_nav-sp
  .primary-nav
  .primary-nav_company
  > a,
.header-only-sp
  .layout-header
  .layout-header_nav-sp
  .primary-nav
  .primary-nav_employment
  > a {
  display: block;
  padding: 45px 10px 10px;
  border-bottom: none;
}
.header-only-sp
  .layout-header
  .layout-header_nav-sp
  .primary-nav
  .primary-nav_company
  > a:before,
.header-only-sp
  .layout-header
  .layout-header_nav-sp
  .primary-nav
  .primary-nav_employment
  > a:before,
.header-only-sp
  .layout-header
  .layout-header_nav-sp
  .primary-nav
  .primary-nav_company
  > a:after,
.header-only-sp
  .layout-header
  .layout-header_nav-sp
  .primary-nav
  .primary-nav_employment
  > a:after {
  content: none;
}
.header-only-sp
  .layout-header
  .layout-header_nav-sp
  .primary-nav
  .primary-nav_company
  > a {
  border-right: 1px solid #e4e5ec;
  background: url(/sb/common/img/layout/primary-nav_bg_company.svg) no-repeat
    center 11px;
  background-size: 18px auto;
}
.header-only-sp
  .layout-header
  .layout-header_nav-sp
  .primary-nav
  .primary-nav_employment
  > a {
  background: url(/sb/common/img/layout/primary-nav_bg_employment.svg) no-repeat
    center 12px;
  background-size: 19px auto;
}
.header-only-sp .layout-header .layout-header_nav-sp .secondary-nav {
  padding-top: 25px;
  border-top: 1px solid #e4e5ec;
}
.header-only-sp .layout-header .layout-header_nav-sp .secondary-nav > ul {
  overflow: hidden;
}
.header-only-sp .layout-header .layout-header_nav-sp .secondary-nav > ul > li {
  float: left;
  margin-right: 18px;
  font-size: 1.2em;
}
.header-only-sp
  .layout-header
  .layout-header_nav-sp
  .secondary-nav
  > ul
  > li
  > a {
  display: block;
  padding: 10px 0;
  color: #222;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.header-only-sp
  .layout-header
  .layout-header_nav-sp
  .secondary-nav
  > ul
  > li
  > a[target='_blank']:not(.m-not-icon):after {
  background-image: url(/sb/common/img/parts/icon-window_bg03_black.svg);
  background-size: 11px 9px;
  height: 9px;
  width: 11px;
}
.header-only-sp
  .layout-header
  .layout-header_nav-sp
  .secondary-nav
  > ul
  > li.secondary-nav_mypage,
.header-only-sp
  .layout-header
  .layout-header_nav-sp
  .secondary-nav
  > ul
  > li.secondary-nav_english {
  clear: both;
  width: 100%;
  margin: 10px 0 0;
  font-size: 1.3em;
}
.header-only-sp
  .layout-header
  .layout-header_nav-sp
  .secondary-nav
  > ul
  > li.secondary-nav_mypage
  > a,
.header-only-sp
  .layout-header
  .layout-header_nav-sp
  .secondary-nav
  > ul
  > li.secondary-nav_english
  > a {
  padding: 15px 21px;
  border: 1px solid #e4e5ec;
}
.header-only-sp
  .layout-header
  .layout-header_nav-sp
  .secondary-nav
  > ul
  > li.secondary-nav_mypage
  > a {
  position: relative;
}
.header-only-sp .layout-header .drawer-handle {
  display: block;
  position: absolute;
  top: 19px;
  right: 20px;
  cursor: pointer;
  z-index: 4;
  background: #fff;
}
.header-only-sp .layout-header .drawer-handle > a {
  display: block;
  width: 25px;
  height: 16px;
}
.header-only-sp .layout-header .drawer-handle > a > span {
  white-space: nowrap;
  text-indent: 100%;
  overflow: hidden;
  display: block;
  position: absolute;
  top: 7px;
  left: 0;
  width: 100%;
  height: 2px;
  background: #000;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.header-only-sp .layout-header .drawer-handle > a:before,
.header-only-sp .layout-header .drawer-handle > a:after {
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  background: #000;
  content: '';
  -webkit-transition: all 0.45s cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 0.45s cubic-bezier(0.19, 1, 0.22, 1);
}
.header-only-sp .layout-header .drawer-handle > a:before {
  top: 0;
}
.header-only-sp .layout-header .drawer-handle > a:after {
  bottom: 0;
}
.header-only-sp .layout-header .drawer-handle.is-active > a > span {
  opacity: 0;
}
.header-only-sp .layout-header .drawer-handle.is-active > a:before {
  -webkit-transform: translateY(7px) rotate(-45deg);
  -ms-transform: translateY(7px) rotate(-45deg);
  transform: translateY(7px) rotate(-45deg);
}
.header-only-sp .layout-header .drawer-handle.is-active > a:after {
  -webkit-transform: translateY(-7px) rotate(45deg);
  -ms-transform: translateY(-7px) rotate(45deg);
  transform: translateY(-7px) rotate(45deg);
}
.header-only-sp .layout-header .drawer-menu {
  visibility: hidden;
  overflow: auto;
  width: 100%;
  max-height: 100vh;
  min-height: 100vh;
  padding: 55px 20px 100px;
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  background: #fff;
  opacity: 0;
  color: #000;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transform: translateY(-150px);
  -ms-transform: translateY(-150px);
  transform: translateY(-150px);
  -webkit-transition: all 0.45s cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 0.45s cubic-bezier(0.19, 1, 0.22, 1);
}
.header-only-sp .layout-header .drawer-menu.is-active {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}
.header-only-sp .layout-header .accordion-menu > span > a {
  white-space: nowrap;
  text-indent: 100%;
  overflow: hidden;
  display: block;
  position: absolute;
  top: 50%;
  right: 6px;
  width: 35px;
  height: 20px;
  margin-top: -10px;
  border-left: 1px solid #e4e5ec;
}
.header-only-sp .layout-header .accordion-menu > span > a:before,
.header-only-sp .layout-header .accordion-menu > span > a:after {
  position: absolute;
  right: 4px;
  width: 12px;
  height: 2px;
  background: #bcbcbc;
  border-radius: 4px;
  content: '';
}
.header-only-sp .layout-header .accordion-menu > span > a:before {
  top: 8px;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}
.header-only-sp .layout-header .accordion-menu > span > a:after {
  top: 8px;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.header-only-sp .layout-header .accordion-menu.is-expanded > span > a:before {
  display: none;
}
.header-only-sp .layout-header .accordion-menu.is-expanded > span > a:after {
  background: #222;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.header-only-sp .layout-header .accordion-menu_content {
  display: none;
}
.header-only-sp .layout-header .accordion-menu_content > ul {
  margin: 0 15px;
  padding: 15px 0;
  border-top: 1px solid #e4e5ec;
}
.header-only-sp .layout-header .accordion-menu_content > ul:first-child {
  border-top: none;
}
.header-only-sp .layout-header .accordion-menu_content > ul > li {
  font-size: 1.4rem;
}
.header-only-sp .layout-header .accordion-menu_content > ul > li > a {
  display: block;
  position: relative;
  padding: 10px 0 10px 18px;
  color: #222;
}
.header-only-sp .layout-header .accordion-menu_content > ul > li > a:before {
  position: absolute;
  top: 50%;
  left: 0;
  width: 4.7px;
  height: 4.7px;
  margin-top: -4px;
  border-top: 1px solid #222;
  border-right: 1px solid #222;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  content: '';
}
.header-only-sp .layout-header .layout-header_nav-pc {
  display: none;
}
.header-only-sp .layout-header .search-area {
  position: relative;
  width: 100%;
}
.header-only-sp .layout-header .search-area .search-area_handle {
  position: absolute;
  top: 17px;
  left: 23px;
}
.header-only-sp .layout-header .search-area .search-area_handle img {
  width: 20px;
  height: 20px;
}
.header-only-sp .layout-header .search-area .search-area_handle > a {
  white-space: nowrap;
  text-indent: 100%;
  overflow: hidden;
  display: block;
  width: 20px;
  height: 20px;
  background: url(/sb/common/img/layout/search-area_img01.svg) no-repeat 0 0;
  background-size: 20px auto;
}
.header-only-sp .layout-header .search-area .search-area_handle > a:before,
.header-only-sp .layout-header .search-area .search-area_handle > a:after {
  opacity: 0;
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  background: #000;
  content: '';
  -webkit-transition: all 0.45s cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 0.45s cubic-bezier(0.19, 1, 0.22, 1);
}
.header-only-sp .layout-header .search-area .search-area_handle > a:before {
  top: 0;
}
.header-only-sp .layout-header .search-area .search-area_handle > a:after {
  bottom: 0;
}
.header-only-sp .layout-header .search-area.is-active {
  z-index: 5;
}
.header-only-sp .layout-header .search-area.is-active .search-area_handle {
  z-index: 4;
}
.header-only-sp .layout-header .search-area.is-active .search-area_handle > a {
  background: none;
}
.header-only-sp
  .layout-header
  .search-area.is-active
  .search-area_handle
  > a:before,
.header-only-sp
  .layout-header
  .search-area.is-active
  .search-area_handle
  > a:after {
  opacity: 1;
}
.header-only-sp
  .layout-header
  .search-area.is-active
  .search-area_handle
  > a:before {
  -webkit-transform: translateY(9px) rotate(-45deg);
  -ms-transform: translateY(9px) rotate(-45deg);
  transform: translateY(9px) rotate(-45deg);
}
.header-only-sp
  .layout-header
  .search-area.is-active
  .search-area_handle
  > a:after {
  -webkit-transform: translateY(-9px) rotate(45deg);
  -ms-transform: translateY(-9px) rotate(45deg);
  transform: translateY(-9px) rotate(45deg);
}
.header-only-sp .layout-header .search-area .search-area_content {
  visibility: hidden;
  position: absolute;
  z-index: 3;
  width: 100%;
  padding: 55px 15px 15px;
  background: #fff;
  opacity: 0;
  -webkit-transform: translateY(-140px);
  -ms-transform: translateY(-140px);
  transform: translateY(-140px);
  -webkit-transition: all 0.45s cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 0.45s cubic-bezier(0.19, 1, 0.22, 1);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.header-only-sp .layout-header .search-area .search-area_content.is-active {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}
.header-only-sp .layout-header .search-area .search-area_content form {
  position: relative;
}
.header-only-sp .layout-header .search-area .search-area_content input {
  width: 100%;
  padding: 10px 40px 10px 15px;
  background: #ebedf2;
  border: none;
  font-size: 16px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.header-only-sp .layout-header .search-area .search-area_content button {
  position: absolute;
  top: 50%;
  right: 10px;
  margin-top: -10px;
  border: none;
  background: none;
}
.header-only-sp .layout-header .search-area .search-area_content button > img {
  width: 20px;
}
/* ==========================================================================
   Layout - Content
   ========================================================================== */
/* Content
   ========================================================================== */
.layout-content {
  width: 100%;
}
@media (min-width: 769px) {
  .layout-content {
    margin-bottom: 100px;
  }
}
@media (max-width: 768px) {
  .layout-content {
    margin-bottom: 55px;
  }
}
/* Main content
   ========================================================================== */
.layout-main-content {
  margin: 0 auto;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
@media (min-width: 769px) {
  .layout-main-content {
    padding: 0 40px;
    min-width: 1080px;
  }
}
@media (max-width: 768px) {
  .layout-main-content {
    padding: 0 15px;
    min-width: 100%;
  }
}
/* ==========================================================================
   Layout - Breadcrumbs
   ========================================================================== */
/* Breadcrumbs
   ========================================================================== */
.breadcrumbs {
  position: relative;
  bottom: -50px;
  z-index: 3;
  width: 100%;
  height: 0;
  margin: 0 auto;
  padding: 0 40px;
  font-size: 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
@media (min-width: 769px) {
  .breadcrumbs a:hover,
  .breadcrumbs a:link:hover,
  .breadcrumbs a:active:hover,
  .breadcrumbs a:visited:hover {
    color: #222;
  }
}
.breadcrumbs > ol {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  color: #555;
}
.breadcrumbs > ol > li {
  display: inline-block;
  *display: inline;
  zoom: 1;
  vertical-align: middle;
  font-size: 1.2em;
  position: relative;
  padding-left: 25px;
}
.breadcrumbs > ol > li:before {
  position: absolute;
  left: 5px;
  content: '／';
}
.breadcrumbs > ol > li:first-child {
  padding-left: 0;
}
.breadcrumbs > ol > li:first-child:before {
  content: none;
}
.breadcrumbs > ol > li:first-child img {
  display: inline-block;
  *display: inline;
  zoom: 1;
  vertical-align: baseline;
  width: 14px;
  margin-right: 8px;
}
.breadcrumbs > ol > li > a {
  color: #555;
  text-decoration: underline;
}
@media (min-width: 769px) {
  .breadcrumbs > ol > li > a:hover {
    text-decoration: none;
  }
}
@media (max-width: 768px) {
  .breadcrumbs {
    display: none;
  }
}
/* Teheme old */
@media screen and (max-width: 768px) {
  .topicpathA {
    display: none;
  }
}
/* ==========================================================================
   Layout - Footer
   ========================================================================== */
/* Layout footer
   ========================================================================== */
:not(.footer-only-pc):not(.footer-only-sp) .layout-footer {
  position: relative;
  width: 100%;
  padding: 45px 0 0;
  background: #f5f6f9;
  font-size: 10px;
}
:not(.footer-only-pc):not(.footer-only-sp)
  .layout-footer
  .layout-footer_return-top
  a {
  white-space: nowrap;
  text-indent: 100%;
  overflow: hidden;
  position: absolute;
  top: -67px;
  right: 30px;
  width: 45px;
  height: 45px;
  background: url(/sb/common/img/layout/footer-return-top_img01.svg) no-repeat 0
    0;
  background-size: 45px auto;
}
:not(.footer-only-pc):not(.footer-only-sp) .layout-footer .layout-footer_inner {
  zoom: 1;
  position: relative;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
:not(.footer-only-pc):not(.footer-only-sp)
  .layout-footer
  .layout-footer_inner:before,
:not(.footer-only-pc):not(.footer-only-sp)
  .layout-footer
  .layout-footer_inner:after {
  content: '';
  display: table;
}
:not(.footer-only-pc):not(.footer-only-sp)
  .layout-footer
  .layout-footer_inner:after {
  clear: both;
}
:not(.footer-only-pc):not(.footer-only-sp) .layout-footer .layout-footer_logo {
  margin: 0;
  padding: 60px 20px 65px;
  text-align: center;
  border-bottom: 1px solid #dcdde0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
:not(.footer-only-pc):not(.footer-only-sp)
  .layout-footer
  .layout-footer_logo
  img {
  display: inline-block;
}
:not(.footer-only-pc):not(.footer-only-sp) .layout-footer .layout-footer_sns {
  margin-top: 50px;
  color: #555;
}
:not(.footer-only-pc):not(.footer-only-sp)
  .layout-footer
  .layout-footer_sns
  > ul {
  display: table;
}
:not(.footer-only-pc):not(.footer-only-sp)
  .layout-footer
  .layout-footer_sns
  > ul
  > li {
  display: table-cell;
  padding-left: 25px;
  vertical-align: middle;
}
:not(.footer-only-pc):not(.footer-only-sp)
  .layout-footer
  .layout-footer_sns
  > ul
  > li
  a {
  display: inline-block;
  padding: 0 5px;
}
:not(.footer-only-pc):not(.footer-only-sp)
  .layout-footer
  .layout-footer_sns
  > ul
  > li
  img {
  vertical-align: middle;
}
:not(.footer-only-pc):not(.footer-only-sp)
  .layout-footer
  .layout-footer_sns
  > p {
  margin-top: 35px;
  font-size: 1.3em;
}
:not(.footer-only-pc):not(.footer-only-sp)
  .layout-footer
  .layout-footer_sns
  > p
  a {
  color: #555;
}
:not(.footer-only-pc):not(.footer-only-sp) .layout-footer .layout-footer_nav {
  overflow: hidden;
  margin-top: 50px;
}
:not(.footer-only-pc):not(.footer-only-sp)
  .layout-footer
  .layout-footer_nav
  > ul {
  float: left;
  margin-left: 70px;
}
:not(.footer-only-pc):not(.footer-only-sp)
  .layout-footer
  .layout-footer_nav
  > ul:first-child {
  margin: 0;
}
:not(.footer-only-pc):not(.footer-only-sp)
  .layout-footer
  .layout-footer_nav
  > ul
  > li {
  margin-top: 15px;
  font-size: 1.3em;
}
:not(.footer-only-pc):not(.footer-only-sp)
  .layout-footer
  .layout-footer_nav
  > ul
  > li:first-child {
  margin: 0;
}
:not(.footer-only-pc):not(.footer-only-sp)
  .layout-footer
  .layout-footer_nav
  > ul
  a {
  color: #555;
  text-decoration: none;
}
:not(.footer-only-pc):not(.footer-only-sp)
  .layout-footer
  .layout-footer_group-link {
  clear: both;
  overflow: hidden;
  padding: 50px 0 80px;
}
:not(.footer-only-pc):not(.footer-only-sp)
  .layout-footer
  .layout-footer_group-link
  li {
  float: left;
  margin-left: 35px;
  font-size: 1.2em;
  font-weight: bold;
}
:not(.footer-only-pc):not(.footer-only-sp)
  .layout-footer
  .layout-footer_group-link
  li:first-child {
  margin-left: 0;
}
:not(.footer-only-pc):not(.footer-only-sp)
  .layout-footer
  .layout-footer_group-link
  li
  a {
  color: #000;
  text-decoration: none;
}
:not(.footer-only-pc):not(.footer-only-sp)
  .layout-footer
  .layout-footer_group-link
  li
  a[target]:after {
  display: inline-block;
  width: 11px;
  height: 11px;
  margin-left: 7px;
  background: url(/sb/common/img/layout/group-link_window.svg) no-repeat 0 0;
  background-size: auto 11px;
  content: '';
}
:not(.footer-only-pc):not(.footer-only-sp) .layout-footer .layout-footer_lower {
  zoom: 1;
  clear: both;
  padding: 50px 0;
  background: #fff;
}
:not(.footer-only-pc):not(.footer-only-sp)
  .layout-footer
  .layout-footer_lower:before,
:not(.footer-only-pc):not(.footer-only-sp)
  .layout-footer
  .layout-footer_lower:after {
  content: '';
  display: table;
}
:not(.footer-only-pc):not(.footer-only-sp)
  .layout-footer
  .layout-footer_lower:after {
  clear: both;
}
:not(.footer-only-pc):not(.footer-only-sp) .layout-footer .layout-footer_note {
  display: table;
}
:not(.footer-only-pc):not(.footer-only-sp)
  .layout-footer
  .layout-footer_note
  > p {
  display: table-cell;
  padding-right: 10px;
  color: #555;
  font-size: 1.1em;
  vertical-align: middle;
}
:not(.footer-only-pc):not(.footer-only-sp)
  .layout-footer
  .layout-footer_note
  > p:last-child {
  padding: 0 0 0 10px;
}
:not(.footer-only-pc):not(.footer-only-sp)
  .layout-footer
  .layout-footer_copyright {
  margin: 0;
  font-size: 1em;
  text-align: right;
  color: #777;
}
:not(.footer-only-pc):not(.footer-only-sp)
  .layout-footer
  .layout-footer_copyright
  > img {
  display: block;
  margin: 0 0 20px auto;
  width: 182px;
}
@media (min-width: 769px) {
  :not(.footer-only-pc):not(.footer-only-sp)
    .layout-footer
    .layout-footer_return-top
    a:hover {
    opacity: 0.7;
  }
  :not(.footer-only-pc):not(.footer-only-sp) .layout-footer .layout-footer_sns {
    float: right;
  }
  :not(.footer-only-pc):not(.footer-only-sp)
    .layout-footer
    .layout-footer_sns
    > ul
    > li:first-child {
    padding-left: 0;
  }
  :not(.footer-only-pc):not(.footer-only-sp)
    .layout-footer
    .layout-footer_sns
    > ul
    > li
    a:hover {
    opacity: 0.7;
  }
  :not(.footer-only-pc):not(.footer-only-sp) .layout-footer .layout-footer_nav {
    float: left;
  }
  :not(.footer-only-pc):not(.footer-only-sp)
    .layout-footer
    .layout-footer_nav
    > ul
    a:hover {
    text-decoration: underline;
  }
  :not(.footer-only-pc):not(.footer-only-sp)
    .layout-footer
    .layout-footer_group-link
    li
    a:hover {
    text-decoration: underline;
  }
  :not(.footer-only-pc):not(.footer-only-sp)
    .layout-footer
    .layout-footer_note {
    float: left;
  }
  :not(.footer-only-pc):not(.footer-only-sp)
    .layout-footer
    .layout-footer_copyright {
    float: right;
    text-align: right;
  }
}
@media (max-width: 768px) {
  :not(.footer-only-pc):not(.footer-only-sp) .layout-footer {
    padding: 0;
  }
  :not(.footer-only-pc):not(.footer-only-sp)
    .layout-footer
    .layout-footer_return-top
    a {
    top: -22px;
    right: 10px;
  }
  :not(.footer-only-pc):not(.footer-only-sp)
    .layout-footer
    .layout-footer_inner {
    width: 100%;
    padding: 0 20px;
  }
  :not(.footer-only-pc):not(.footer-only-sp)
    .layout-footer
    .layout-footer_logo {
    min-width: -webkit-calc(100% + 40px);
    min-width: calc(100% + 40px);
    margin: 0 -20px;
    padding: 40px 0 48px;
  }
  :not(.footer-only-pc):not(.footer-only-sp)
    .layout-footer
    .layout-footer_logo
    img {
    width: 66px;
    height: auto;
  }
  :not(.footer-only-pc):not(.footer-only-sp) .layout-footer .layout-footer_sns {
    width: 100%;
    margin-top: 35px;
  }
  :not(.footer-only-pc):not(.footer-only-sp)
    .layout-footer
    .layout-footer_sns
    > ul {
    margin: 0 auto;
  }
  :not(.footer-only-pc):not(.footer-only-sp)
    .layout-footer
    .layout-footer_sns
    > ul
    > li {
    padding: 0 10px;
  }
  :not(.footer-only-pc):not(.footer-only-sp)
    .layout-footer
    .layout-footer_sns
    > p {
    margin-top: 25px;
    font-size: 1.1em;
    text-align: center;
  }
  :not(.footer-only-pc):not(.footer-only-sp) .layout-footer .layout-footer_nav {
    width: 100%;
  }
  :not(.footer-only-pc):not(.footer-only-sp)
    .layout-footer
    .layout-footer_nav
    > ul {
    margin: 30px 0 0;
    width: 50%;
  }
  :not(.footer-only-pc):not(.footer-only-sp)
    .layout-footer
    .layout-footer_nav
    > ul:first-child {
    width: 100%;
    border-top: 1px solid #dbdce2;
  }
  :not(.footer-only-pc):not(.footer-only-sp)
    .layout-footer
    .layout-footer_nav
    > ul:first-child
    > li {
    margin-top: 0;
    border-bottom: 1px solid #dbdce2;
    color: #333;
    font-size: 1.3em;
    font-weight: bold;
  }
  :not(.footer-only-pc):not(.footer-only-sp)
    .layout-footer
    .layout-footer_nav
    > ul:first-child
    > li
    a {
    display: block;
    padding: 15px 0;
  }
  :not(.footer-only-pc):not(.footer-only-sp)
    .layout-footer
    .layout-footer_nav
    > ul
    > li {
    font-size: 1.2em;
  }
  :not(.footer-only-pc):not(.footer-only-sp)
    .layout-footer
    .layout-footer_group-link
    li {
    margin-left: 23px;
  }
  :not(.footer-only-pc):not(.footer-only-sp)
    .layout-footer
    .layout-footer_lower {
    padding: 35px 0;
  }
  :not(.footer-only-pc):not(.footer-only-sp)
    .layout-footer
    .layout-footer_note
    > p {
    padding-right: 6px;
    font-size: 0.9em;
    vertical-align: top;
  }
  :not(.footer-only-pc):not(.footer-only-sp)
    .layout-footer
    .layout-footer_note
    > p:last-child {
    padding-left: 8px;
  }
  :not(.footer-only-pc):not(.footer-only-sp)
    .layout-footer
    .layout-footer_copyright {
    margin-top: 30px;
    text-align: center;
  }
}
/* Migrate only display
   ========================================================================== */
.footer-only-pc .layout-footer {
  position: relative;
  width: 100%;
  padding: 45px 0 0;
  background: #f5f6f9;
  font-size: 10px;
}
.footer-only-pc .layout-footer .layout-footer_return-top a {
  white-space: nowrap;
  text-indent: 100%;
  overflow: hidden;
  position: absolute;
  top: -67px;
  right: 30px;
  width: 45px;
  height: 45px;
  background: url(/sb/common/img/layout/footer-return-top_img01.svg) no-repeat 0
    0;
  background-size: 45px auto;
}
.footer-only-pc .layout-footer .layout-footer_inner {
  zoom: 1;
  position: relative;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.footer-only-pc .layout-footer .layout-footer_inner:before,
.footer-only-pc .layout-footer .layout-footer_inner:after {
  content: '';
  display: table;
}
.footer-only-pc .layout-footer .layout-footer_inner:after {
  clear: both;
}
.footer-only-pc .layout-footer .layout-footer_logo {
  margin: 0;
  padding: 60px 20px 65px;
  text-align: center;
  border-bottom: 1px solid #dcdde0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.footer-only-pc .layout-footer .layout-footer_logo img {
  display: inline-block;
}
.footer-only-pc .layout-footer .layout-footer_sns {
  margin-top: 50px;
  color: #555;
}
.footer-only-pc .layout-footer .layout-footer_sns > ul {
  display: table;
}
.footer-only-pc .layout-footer .layout-footer_sns > ul > li {
  display: table-cell;
  padding-left: 25px;
  vertical-align: middle;
}
.footer-only-pc .layout-footer .layout-footer_sns > ul > li a {
  display: inline-block;
  padding: 0 5px;
}
.footer-only-pc .layout-footer .layout-footer_sns > ul > li img {
  vertical-align: middle;
}
.footer-only-pc .layout-footer .layout-footer_sns > p {
  margin-top: 35px;
  font-size: 1.3em;
}
.footer-only-pc .layout-footer .layout-footer_sns > p a {
  color: #555;
}
.footer-only-pc .layout-footer .layout-footer_nav {
  overflow: hidden;
  margin-top: 50px;
}
.footer-only-pc .layout-footer .layout-footer_nav > ul {
  float: left;
  margin-left: 70px;
}
.footer-only-pc .layout-footer .layout-footer_nav > ul:first-child {
  margin: 0;
}
.footer-only-pc .layout-footer .layout-footer_nav > ul > li {
  margin-top: 15px;
  font-size: 1.3em;
}
.footer-only-pc .layout-footer .layout-footer_nav > ul > li:first-child {
  margin: 0;
}
.footer-only-pc .layout-footer .layout-footer_nav > ul a {
  color: #555;
  text-decoration: none;
}
.footer-only-pc .layout-footer .layout-footer_group-link {
  clear: both;
  overflow: hidden;
  padding: 50px 0 80px;
}
.footer-only-pc .layout-footer .layout-footer_group-link li {
  float: left;
  margin-left: 35px;
  font-size: 1.2em;
  font-weight: bold;
}
.footer-only-pc .layout-footer .layout-footer_group-link li:first-child {
  margin-left: 0;
}
.footer-only-pc .layout-footer .layout-footer_group-link li a {
  color: #000;
  text-decoration: none;
}
.footer-only-pc .layout-footer .layout-footer_group-link li a[target]:after {
  display: inline-block;
  width: 11px;
  height: 11px;
  margin-left: 7px;
  background: url(/sb/common/img/layout/group-link_window.svg) no-repeat 0 0;
  background-size: auto 11px;
  content: '';
}
.footer-only-pc .layout-footer .layout-footer_lower {
  zoom: 1;
  clear: both;
  padding: 50px 0;
  background: #fff;
}
.footer-only-pc .layout-footer .layout-footer_lower:before,
.footer-only-pc .layout-footer .layout-footer_lower:after {
  content: '';
  display: table;
}
.footer-only-pc .layout-footer .layout-footer_lower:after {
  clear: both;
}
.footer-only-pc .layout-footer .layout-footer_note {
  display: table;
}
.footer-only-pc .layout-footer .layout-footer_note > p {
  display: table-cell;
  padding-right: 10px;
  color: #555;
  font-size: 1.1em;
  vertical-align: middle;
}
.footer-only-pc .layout-footer .layout-footer_note > p:last-child {
  padding: 0 0 0 10px;
}
.footer-only-pc .layout-footer .layout-footer_copyright {
  margin: 0;
  font-size: 1em;
  text-align: right;
  color: #777;
}
.footer-only-pc .layout-footer .layout-footer_copyright > img {
  display: block;
  margin: 0 0 20px auto;
  width: 182px;
}
.footer-only-pc .layout-footer .layout-footer_return-top a:hover {
  opacity: 0.7;
}
.footer-only-pc .layout-footer .layout-footer_sns {
  float: right;
}
.footer-only-pc .layout-footer .layout-footer_sns > ul > li:first-child {
  padding-left: 0;
}
.footer-only-pc .layout-footer .layout-footer_sns > ul > li a:hover {
  opacity: 0.7;
}
.footer-only-pc .layout-footer .layout-footer_nav {
  float: left;
}
.footer-only-pc .layout-footer .layout-footer_nav > ul a:hover {
  text-decoration: underline;
}
.footer-only-pc .layout-footer .layout-footer_group-link li a:hover {
  text-decoration: underline;
}
.footer-only-pc .layout-footer .layout-footer_note {
  float: left;
}
.footer-only-pc .layout-footer .layout-footer_copyright {
  float: right;
  text-align: right;
}
.footer-only-sp .layout-footer {
  position: relative;
  width: 100%;
  padding: 45px 0 0;
  background: #f5f6f9;
  font-size: 10px;
  padding: 0;
}
.footer-only-sp .layout-footer .layout-footer_return-top a {
  white-space: nowrap;
  text-indent: 100%;
  overflow: hidden;
  position: absolute;
  top: -67px;
  right: 30px;
  width: 45px;
  height: 45px;
  background: url(/sb/common/img/layout/footer-return-top_img01.svg) no-repeat 0
    0;
  background-size: 45px auto;
}
.footer-only-sp .layout-footer .layout-footer_inner {
  zoom: 1;
  position: relative;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.footer-only-sp .layout-footer .layout-footer_inner:before,
.footer-only-sp .layout-footer .layout-footer_inner:after {
  content: '';
  display: table;
}
.footer-only-sp .layout-footer .layout-footer_inner:after {
  clear: both;
}
.footer-only-sp .layout-footer .layout-footer_logo {
  margin: 0;
  padding: 60px 20px 65px;
  text-align: center;
  border-bottom: 1px solid #dcdde0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.footer-only-sp .layout-footer .layout-footer_logo img {
  display: inline-block;
}
.footer-only-sp .layout-footer .layout-footer_sns {
  margin-top: 50px;
  color: #555;
}
.footer-only-sp .layout-footer .layout-footer_sns > ul {
  display: table;
}
.footer-only-sp .layout-footer .layout-footer_sns > ul > li {
  display: table-cell;
  padding-left: 25px;
  vertical-align: middle;
}
.footer-only-sp .layout-footer .layout-footer_sns > ul > li a {
  display: inline-block;
  padding: 0 5px;
}
.footer-only-sp .layout-footer .layout-footer_sns > ul > li img {
  vertical-align: middle;
}
.footer-only-sp .layout-footer .layout-footer_sns > p {
  margin-top: 35px;
  font-size: 1.3em;
}
.footer-only-sp .layout-footer .layout-footer_sns > p a {
  color: #555;
}
.footer-only-sp .layout-footer .layout-footer_nav {
  overflow: hidden;
  margin-top: 50px;
}
.footer-only-sp .layout-footer .layout-footer_nav > ul {
  float: left;
  margin-left: 70px;
}
.footer-only-sp .layout-footer .layout-footer_nav > ul:first-child {
  margin: 0;
}
.footer-only-sp .layout-footer .layout-footer_nav > ul > li {
  margin-top: 15px;
  font-size: 1.3em;
}
.footer-only-sp .layout-footer .layout-footer_nav > ul > li:first-child {
  margin: 0;
}
.footer-only-sp .layout-footer .layout-footer_nav > ul a {
  color: #555;
  text-decoration: none;
}
.footer-only-sp .layout-footer .layout-footer_group-link {
  clear: both;
  overflow: hidden;
  padding: 50px 0 80px;
}
.footer-only-sp .layout-footer .layout-footer_group-link li {
  float: left;
  margin-left: 35px;
  font-size: 1.2em;
  font-weight: bold;
}
.footer-only-sp .layout-footer .layout-footer_group-link li:first-child {
  margin-left: 0;
}
.footer-only-sp .layout-footer .layout-footer_group-link li a {
  color: #000;
  text-decoration: none;
}
.footer-only-sp .layout-footer .layout-footer_group-link li a[target]:after {
  display: inline-block;
  width: 11px;
  height: 11px;
  margin-left: 7px;
  background: url(/sb/common/img/layout/group-link_window.svg) no-repeat 0 0;
  background-size: auto 11px;
  content: '';
}
.footer-only-sp .layout-footer .layout-footer_lower {
  zoom: 1;
  clear: both;
  padding: 50px 0;
  background: #fff;
}
.footer-only-sp .layout-footer .layout-footer_lower:before,
.footer-only-sp .layout-footer .layout-footer_lower:after {
  content: '';
  display: table;
}
.footer-only-sp .layout-footer .layout-footer_lower:after {
  clear: both;
}
.footer-only-sp .layout-footer .layout-footer_note {
  display: table;
}
.footer-only-sp .layout-footer .layout-footer_note > p {
  display: table-cell;
  padding-right: 10px;
  color: #555;
  font-size: 1.1em;
  vertical-align: middle;
}
.footer-only-sp .layout-footer .layout-footer_note > p:last-child {
  padding: 0 0 0 10px;
}
.footer-only-sp .layout-footer .layout-footer_copyright {
  margin: 0;
  font-size: 1em;
  text-align: right;
  color: #777;
}
.footer-only-sp .layout-footer .layout-footer_copyright > img {
  display: block;
  margin: 0 0 20px auto;
  width: 182px;
}
.footer-only-sp .layout-footer .layout-footer_return-top a {
  top: -22px;
  right: 10px;
}
.footer-only-sp .layout-footer .layout-footer_inner {
  width: 100%;
  padding: 0 20px;
}
.footer-only-sp .layout-footer .layout-footer_logo {
  min-width: -webkit-calc(100% + 40px);
  min-width: calc(100% + 40px);
  margin: 0 -20px;
  padding: 40px 0 48px;
}
.footer-only-sp .layout-footer .layout-footer_logo img {
  width: 66px;
  height: auto;
}
.footer-only-sp .layout-footer .layout-footer_sns {
  width: 100%;
  margin-top: 35px;
}
.footer-only-sp .layout-footer .layout-footer_sns > ul {
  margin: 0 auto;
}
.footer-only-sp .layout-footer .layout-footer_sns > ul > li {
  padding: 0 10px;
}
.footer-only-sp .layout-footer .layout-footer_sns > p {
  margin-top: 25px;
  font-size: 1.1em;
  text-align: center;
}
.footer-only-sp .layout-footer .layout-footer_nav {
  width: 100%;
}
.footer-only-sp .layout-footer .layout-footer_nav > ul {
  margin: 30px 0 0;
  width: 50%;
}
.footer-only-sp .layout-footer .layout-footer_nav > ul:first-child {
  width: 100%;
  border-top: 1px solid #dbdce2;
}
.footer-only-sp .layout-footer .layout-footer_nav > ul:first-child > li {
  margin-top: 0;
  border-bottom: 1px solid #dbdce2;
  color: #333;
  font-size: 1.3em;
  font-weight: bold;
}
.footer-only-sp .layout-footer .layout-footer_nav > ul:first-child > li a {
  display: block;
  padding: 15px 0;
}
.footer-only-sp .layout-footer .layout-footer_nav > ul > li {
  font-size: 1.2em;
}
.footer-only-sp .layout-footer .layout-footer_group-link li {
  margin-left: 23px;
}
.footer-only-sp .layout-footer .layout-footer_lower {
  padding: 35px 0;
}
.footer-only-sp .layout-footer .layout-footer_note > p {
  padding-right: 6px;
  font-size: 0.9em;
  vertical-align: top;
}
.footer-only-sp .layout-footer .layout-footer_note > p:last-child {
  padding-left: 8px;
}
.footer-only-sp .layout-footer .layout-footer_copyright {
  margin-top: 30px;
  text-align: center;
}
/* Theme Simple
   ========================================================================== */
.layout-header.theme-simple ~ .layout-footer {
  padding-top: 0;
}
/* Teheme old
   ========================================================================== */
.theme-old .layout-footer_inner {
  padding: 0;
}
.theme-old #sapporo-footer-area li {
  font-size: 1em;
}
.theme-old.layout-footer {
  margin-top: 0;
  padding-top: 0;
}
.theme-old.breadcrumbs {
  display: none;
}
/* ==========================================================================
   Layout - Migration
   ========================================================================== */
/* Header & Footer
   ========================================================================== */
#sapporo-header-area .layout-header_inner,
#sapporo-navigation-area .layout-header_inner,
#sapporo-footer-area .layout-header_inner,
#sapporo-related-area .layout-header_inner {
  color: #222;
}
#sapporo-header-area .layout-header_inner .secondary-nav a:visited,
#sapporo-navigation-area .layout-header_inner .secondary-nav a:visited,
#sapporo-footer-area .layout-header_inner .secondary-nav a:visited,
#sapporo-related-area .layout-header_inner .secondary-nav a:visited,
#sapporo-header-area .layout-header_inner .secondary-nav a:hover,
#sapporo-navigation-area .layout-header_inner .secondary-nav a:hover,
#sapporo-footer-area .layout-header_inner .secondary-nav a:hover,
#sapporo-related-area .layout-header_inner .secondary-nav a:hover,
#sapporo-header-area .layout-header_inner .secondary-nav a:link,
#sapporo-navigation-area .layout-header_inner .secondary-nav a:link,
#sapporo-footer-area .layout-header_inner .secondary-nav a:link,
#sapporo-related-area .layout-header_inner .secondary-nav a:link {
  text-decoration: none;
}
@media (min-width: 769px) {
  #sapporo-header-area .layout-header_inner .secondary-nav a:visited,
  #sapporo-navigation-area .layout-header_inner .secondary-nav a:visited,
  #sapporo-footer-area .layout-header_inner .secondary-nav a:visited,
  #sapporo-related-area .layout-header_inner .secondary-nav a:visited,
  #sapporo-header-area .layout-header_inner .secondary-nav a:hover,
  #sapporo-navigation-area .layout-header_inner .secondary-nav a:hover,
  #sapporo-footer-area .layout-header_inner .secondary-nav a:hover,
  #sapporo-related-area .layout-header_inner .secondary-nav a:hover,
  #sapporo-header-area .layout-header_inner .secondary-nav a:link,
  #sapporo-navigation-area .layout-header_inner .secondary-nav a:link,
  #sapporo-footer-area .layout-header_inner .secondary-nav a:link,
  #sapporo-related-area .layout-header_inner .secondary-nav a:link {
    color: #aaa !important;
  }
  #sapporo-header-area .layout-header_inner .secondary-nav a:visited:hover,
  #sapporo-navigation-area .layout-header_inner .secondary-nav a:visited:hover,
  #sapporo-footer-area .layout-header_inner .secondary-nav a:visited:hover,
  #sapporo-related-area .layout-header_inner .secondary-nav a:visited:hover,
  #sapporo-header-area .layout-header_inner .secondary-nav a:hover:hover,
  #sapporo-navigation-area .layout-header_inner .secondary-nav a:hover:hover,
  #sapporo-footer-area .layout-header_inner .secondary-nav a:hover:hover,
  #sapporo-related-area .layout-header_inner .secondary-nav a:hover:hover,
  #sapporo-header-area .layout-header_inner .secondary-nav a:link:hover,
  #sapporo-navigation-area .layout-header_inner .secondary-nav a:link:hover,
  #sapporo-footer-area .layout-header_inner .secondary-nav a:link:hover,
  #sapporo-related-area .layout-header_inner .secondary-nav a:link:hover {
    color: #fff !important;
  }
}
@media (max-width: 768px) {
  #sapporo-header-area .layout-header_inner,
  #sapporo-navigation-area .layout-header_inner,
  #sapporo-footer-area .layout-header_inner,
  #sapporo-related-area .layout-header_inner {
    text-align: left;
  }
}
#sapporo-header-area .layout-footer_inner,
#sapporo-navigation-area .layout-footer_inner,
#sapporo-footer-area .layout-footer_inner,
#sapporo-related-area .layout-footer_inner {
  color: #222;
}
#sapporo-header-area .layout-footer_inner .layout-footer_logo,
#sapporo-navigation-area .layout-footer_inner .layout-footer_logo,
#sapporo-footer-area .layout-footer_inner .layout-footer_logo,
#sapporo-related-area .layout-footer_inner .layout-footer_logo {
  margin: 0;
  padding: 60px 20px 65px;
}
@media (max-width: 768px) {
  #sapporo-header-area .layout-footer_inner .layout-footer_logo,
  #sapporo-navigation-area .layout-footer_inner .layout-footer_logo,
  #sapporo-footer-area .layout-footer_inner .layout-footer_logo,
  #sapporo-related-area .layout-footer_inner .layout-footer_logo {
    padding: 40px 10px 48px;
  }
}
#sapporo-header-area .layout-header_inner a,
#sapporo-navigation-area .layout-header_inner a,
#sapporo-footer-area .layout-header_inner a,
#sapporo-related-area .layout-header_inner a,
#sapporo-header-area .layout-footer_inner a,
#sapporo-navigation-area .layout-footer_inner a,
#sapporo-footer-area .layout-footer_inner a,
#sapporo-related-area .layout-footer_inner a,
#sapporo-header-area .layout-footer_lower a,
#sapporo-navigation-area .layout-footer_lower a,
#sapporo-footer-area .layout-footer_lower a,
#sapporo-related-area .layout-footer_lower a,
#sapporo-header-area .layout-header_inner a:visited,
#sapporo-navigation-area .layout-header_inner a:visited,
#sapporo-footer-area .layout-header_inner a:visited,
#sapporo-related-area .layout-header_inner a:visited,
#sapporo-header-area .layout-footer_inner a:visited,
#sapporo-navigation-area .layout-footer_inner a:visited,
#sapporo-footer-area .layout-footer_inner a:visited,
#sapporo-related-area .layout-footer_inner a:visited,
#sapporo-header-area .layout-footer_lower a:visited,
#sapporo-navigation-area .layout-footer_lower a:visited,
#sapporo-footer-area .layout-footer_lower a:visited,
#sapporo-related-area .layout-footer_lower a:visited,
#sapporo-header-area .layout-header_inner a:hover,
#sapporo-navigation-area .layout-header_inner a:hover,
#sapporo-footer-area .layout-header_inner a:hover,
#sapporo-related-area .layout-header_inner a:hover,
#sapporo-header-area .layout-footer_inner a:hover,
#sapporo-navigation-area .layout-footer_inner a:hover,
#sapporo-footer-area .layout-footer_inner a:hover,
#sapporo-related-area .layout-footer_inner a:hover,
#sapporo-header-area .layout-footer_lower a:hover,
#sapporo-navigation-area .layout-footer_lower a:hover,
#sapporo-footer-area .layout-footer_lower a:hover,
#sapporo-related-area .layout-footer_lower a:hover,
#sapporo-header-area .layout-header_inner a:link,
#sapporo-navigation-area .layout-header_inner a:link,
#sapporo-footer-area .layout-header_inner a:link,
#sapporo-related-area .layout-header_inner a:link,
#sapporo-header-area .layout-footer_inner a:link,
#sapporo-navigation-area .layout-footer_inner a:link,
#sapporo-footer-area .layout-footer_inner a:link,
#sapporo-related-area .layout-footer_inner a:link,
#sapporo-header-area .layout-footer_lower a:link,
#sapporo-navigation-area .layout-footer_lower a:link,
#sapporo-footer-area .layout-footer_lower a:link,
#sapporo-related-area .layout-footer_lower a:link {
  color: #222 !important;
  text-decoration: none;
}
#sapporo-header-area
  .layout-header_inner
  .layout-footer_sns
  a[href^='http']:after,
#sapporo-navigation-area
  .layout-header_inner
  .layout-footer_sns
  a[href^='http']:after,
#sapporo-footer-area
  .layout-header_inner
  .layout-footer_sns
  a[href^='http']:after,
#sapporo-related-area
  .layout-header_inner
  .layout-footer_sns
  a[href^='http']:after,
#sapporo-header-area
  .layout-footer_inner
  .layout-footer_sns
  a[href^='http']:after,
#sapporo-navigation-area
  .layout-footer_inner
  .layout-footer_sns
  a[href^='http']:after,
#sapporo-footer-area
  .layout-footer_inner
  .layout-footer_sns
  a[href^='http']:after,
#sapporo-related-area
  .layout-footer_inner
  .layout-footer_sns
  a[href^='http']:after,
#sapporo-header-area
  .layout-footer_lower
  .layout-footer_sns
  a[href^='http']:after,
#sapporo-navigation-area
  .layout-footer_lower
  .layout-footer_sns
  a[href^='http']:after,
#sapporo-footer-area
  .layout-footer_lower
  .layout-footer_sns
  a[href^='http']:after,
#sapporo-related-area
  .layout-footer_lower
  .layout-footer_sns
  a[href^='http']:after {
  content: none;
}
#sapporo-header-area
  .layout-header_inner
  .layout-footer_nav
  a[href^='http']:after,
#sapporo-navigation-area
  .layout-header_inner
  .layout-footer_nav
  a[href^='http']:after,
#sapporo-footer-area
  .layout-header_inner
  .layout-footer_nav
  a[href^='http']:after,
#sapporo-related-area
  .layout-header_inner
  .layout-footer_nav
  a[href^='http']:after,
#sapporo-header-area
  .layout-footer_inner
  .layout-footer_nav
  a[href^='http']:after,
#sapporo-navigation-area
  .layout-footer_inner
  .layout-footer_nav
  a[href^='http']:after,
#sapporo-footer-area
  .layout-footer_inner
  .layout-footer_nav
  a[href^='http']:after,
#sapporo-related-area
  .layout-footer_inner
  .layout-footer_nav
  a[href^='http']:after,
#sapporo-header-area
  .layout-footer_lower
  .layout-footer_nav
  a[href^='http']:after,
#sapporo-navigation-area
  .layout-footer_lower
  .layout-footer_nav
  a[href^='http']:after,
#sapporo-footer-area
  .layout-footer_lower
  .layout-footer_nav
  a[href^='http']:after,
#sapporo-related-area
  .layout-footer_lower
  .layout-footer_nav
  a[href^='http']:after,
#sapporo-header-area
  .layout-header_inner
  .layout-footer_group-link
  a[href^='http']:after,
#sapporo-navigation-area
  .layout-header_inner
  .layout-footer_group-link
  a[href^='http']:after,
#sapporo-footer-area
  .layout-header_inner
  .layout-footer_group-link
  a[href^='http']:after,
#sapporo-related-area
  .layout-header_inner
  .layout-footer_group-link
  a[href^='http']:after,
#sapporo-header-area
  .layout-footer_inner
  .layout-footer_group-link
  a[href^='http']:after,
#sapporo-navigation-area
  .layout-footer_inner
  .layout-footer_group-link
  a[href^='http']:after,
#sapporo-footer-area
  .layout-footer_inner
  .layout-footer_group-link
  a[href^='http']:after,
#sapporo-related-area
  .layout-footer_inner
  .layout-footer_group-link
  a[href^='http']:after,
#sapporo-header-area
  .layout-footer_lower
  .layout-footer_group-link
  a[href^='http']:after,
#sapporo-navigation-area
  .layout-footer_lower
  .layout-footer_group-link
  a[href^='http']:after,
#sapporo-footer-area
  .layout-footer_lower
  .layout-footer_group-link
  a[href^='http']:after,
#sapporo-related-area
  .layout-footer_lower
  .layout-footer_group-link
  a[href^='http']:after {
  display: inline-block;
  width: 11px;
  height: 11px;
  margin-left: 7px;
  background: url(/sb/common/img/layout/group-link_window.svg) no-repeat 0 0;
  background-size: auto 11px;
  content: '';
}
#sapporo-header-area .layout-header_inner p,
#sapporo-navigation-area .layout-header_inner p,
#sapporo-footer-area .layout-header_inner p,
#sapporo-related-area .layout-header_inner p,
#sapporo-header-area .layout-footer_inner p,
#sapporo-navigation-area .layout-footer_inner p,
#sapporo-footer-area .layout-footer_inner p,
#sapporo-related-area .layout-footer_inner p,
#sapporo-header-area .layout-footer_lower p,
#sapporo-navigation-area .layout-footer_lower p,
#sapporo-footer-area .layout-footer_lower p,
#sapporo-related-area .layout-footer_lower p {
  margin-top: 30px;
}
#sapporo-header-area .layout-header_inner p:first-child,
#sapporo-navigation-area .layout-header_inner p:first-child,
#sapporo-footer-area .layout-header_inner p:first-child,
#sapporo-related-area .layout-header_inner p:first-child,
#sapporo-header-area .layout-footer_inner p:first-child,
#sapporo-navigation-area .layout-footer_inner p:first-child,
#sapporo-footer-area .layout-footer_inner p:first-child,
#sapporo-related-area .layout-footer_inner p:first-child,
#sapporo-header-area .layout-footer_lower p:first-child,
#sapporo-navigation-area .layout-footer_lower p:first-child,
#sapporo-footer-area .layout-footer_lower p:first-child,
#sapporo-related-area .layout-footer_lower p:first-child {
  margin-top: 0;
}
@media (max-width: 768px) {
  #sapporo-header-area .layout-header_inner p,
  #sapporo-navigation-area .layout-header_inner p,
  #sapporo-footer-area .layout-header_inner p,
  #sapporo-related-area .layout-header_inner p,
  #sapporo-header-area .layout-footer_inner p,
  #sapporo-navigation-area .layout-footer_inner p,
  #sapporo-footer-area .layout-footer_inner p,
  #sapporo-related-area .layout-footer_inner p,
  #sapporo-header-area .layout-footer_lower p,
  #sapporo-navigation-area .layout-footer_lower p,
  #sapporo-footer-area .layout-footer_lower p,
  #sapporo-related-area .layout-footer_lower p {
    margin-top: 20px;
  }
}
#sapporo-header-area .layout-footer_note > p,
#sapporo-navigation-area .layout-footer_note > p,
#sapporo-footer-area .layout-footer_note > p,
#sapporo-related-area .layout-footer_note > p {
  padding-right: 10px;
}
/* Header
   ========================================================================== */
#sapporo-header-area .layout-header .layout-header_logo,
.sapporo-navigation-area .layout-header .layout-header_logo,
.sp-area-header .layout-header .layout-header_logo {
  background: none;
}
#sapporo-header-area .layout-header .accordion-menu_content > ul > li,
.sapporo-navigation-area .layout-header .accordion-menu_content > ul > li,
.sp-area-header .layout-header .accordion-menu_content > ul > li {
  font-size: 14px;
}
#sapporo-header-area .overlay,
.sapporo-navigation-area .overlay,
.sp-area-header .overlay {
  z-index: 0;
}
.is-fixed .layout-header .layout-header_inner {
  width: 100vw;
  margin: 0;
}
.breadcrumbs + #sapporo-footer-area {
  padding: 0;
}
.topicpath {
  display: none;
}
.sp-area-footer {
  z-index: 100;
  padding: 0;
  border-top: none;
}
#sapporo-footer-area {
  padding: 0;
}
/* Footer
   ========================================================================== */
#sapporo-footer-area .layout-footer,
.sp-area-footer .layout-footer {
  margin-top: 0;
}
#sapporo-footer-area .layout-footer .layout-footer_nav p,
.sp-area-footer .layout-footer .layout-footer_nav p,
#sapporo-footer-area .layout-footer .layout-footer_sns p,
.sp-area-footer .layout-footer .layout-footer_sns p,
#sapporo-footer-area .layout-footer .layout-footer_nav li,
.sp-area-footer .layout-footer .layout-footer_nav li,
#sapporo-footer-area .layout-footer .layout-footer_sns li,
.sp-area-footer .layout-footer .layout-footer_sns li,
#sapporo-footer-area .layout-footer .layout-footer_nav a,
.sp-area-footer .layout-footer .layout-footer_nav a,
#sapporo-footer-area .layout-footer .layout-footer_sns a,
.sp-area-footer .layout-footer .layout-footer_sns a {
  color: #555 !important;
}
#sapporo-footer-area .layout-footer .layout-footer_group-link a,
.sp-area-footer .layout-footer .layout-footer_group-link a {
  color: #000 !important;
}
#sapporo-footer-area .layout-footer .layout-footer_return-top,
.sp-area-footer .layout-footer .layout-footer_return-top {
  z-index: 200;
}
/* Migration break point
   ========================================================================== */
@media screen and (min-width: 769px) {
  #sapporo-header-area,
  #sapporo-navigation-area,
  #sapporo-footer-area {
    display: block !important;
  }
  .sp-area-header,
  .sp-area-footer,
  .sp-all {
    display: none !important;
  }
}
@media screen and (max-width: 768px) {
  #sapporo-header-area,
  #sapporo-navigation-area,
  #sapporo-footer-area {
    display: none !important;
  }
  .sp-area-header,
  .sp-area-footer .sp-all {
    display: block !important;
  }
}
.migrate-only-pc #sapporo-header-area,
.migrate-only-pc #sapporo-navigation-area,
.migrate-only-pc #sapporo-footer-area {
  display: block !important;
}
.migrate-only-pc .sp-area-header,
.migrate-only-pc .sp-area-footer,
.migrate-only-pc .sp-all {
  display: none !important;
}
.migrate-only-sp #sapporo-header-area,
.migrate-only-sp #sapporo-navigation-area,
.migrate-only-sp #sapporo-footer-area {
  display: none !important;
}
.migrate-only-sp .sp-area-header,
.migrate-only-sp .sp-area-footer,
.migrate-only-sp .sp-all {
  display: block !important;
}
.header-only-pc#sapporo-header-area,
.footer-only-pc#sapporo-header-area,
.header-only-pc#sapporo-navigation-area,
.footer-only-pc#sapporo-navigation-area,
.header-only-pc#sapporo-footer-area,
.footer-only-pc#sapporo-footer-area {
  display: block !important;
}
.header-only-pc.sp-area-header,
.footer-only-pc.sp-area-header,
.header-only-pc.sp-area-footer,
.footer-only-pc.sp-area-footer,
.header-only-pc.sp-all,
.footer-only-pc.sp-all {
  display: none !important;
}
.header-only-pc .layout-header_nav-pc,
.footer-only-pc .layout-header_nav-pc {
  display: block !important;
}
.header-only-pc .layout-header_nav-sp,
.footer-only-pc .layout-header_nav-sp {
  display: none !important;
}
.header-only-sp#sapporo-header-area,
.footer-only-sp#sapporo-header-area,
.header-only-sp#sapporo-navigation-area,
.footer-only-sp#sapporo-navigation-area,
.header-only-sp#sapporo-footer-area,
.footer-only-sp#sapporo-footer-area {
  display: none !important;
}
.header-only-sp .sp-area-header,
.footer-only-sp .sp-area-header,
.header-only-sp.sp-area-footer,
.footer-only-sp.sp-area-footer,
.header-only-sp.sp-all,
.footer-only-sp.sp-all {
  display: block !important;
}
.header-only-sp .layout-header_nav-pc,
.footer-only-sp .layout-header_nav-pc {
  display: none !important;
}
.header-only-sp .layout-header_nav-sp,
.footer-only-sp .layout-header_nav-sp {
  display: block !important;
}
</style>
